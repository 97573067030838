import React from 'react'
import {
	Button,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { ResponsiveImage } from 'components'
import { resize } from 'lib/cloudinary'
import {
	THUMBNAIL_HEIGHT,
	THUMBNAIL_WIDTH_X4,
} from 'lib/constants'

const BenefitItem = ({
	benefit,
	width = THUMBNAIL_WIDTH_X4,
	height = THUMBNAIL_HEIGHT,
	handleClick,
	isLocked,
	...props
}) => {
	const classes = useStyles()

	const imageUrl = resize(benefit.image_url, {
		width: width,
		height: height,
	})

	return (
		<div className={classes.root}>
			<Button
				className={classes.button}
				onClick={() => handleClick(benefit)}
			>
				<ResponsiveImage
					src={imageUrl}
					gradient
					borderRadius
					width={width}
					opacity={isLocked ? 0.6 : 1.0}
				/>
			</Button>
			<Typography
				variant="caption"
				color="secondary"
				className={classes.caption}
			>
				MEMBER BENEFITS
			</Typography>
			<Typography
				variant="subtitle1"
				color="textPrimary"
				className={classes.title}
			>
				{benefit.title}
			</Typography>
			<Typography
				variant="caption"
				color="textPrimary"
				className={classes.overline}
			>
				T&CS APPLY
			</Typography>
		</div>
	)
}

export default BenefitItem

const useStyles = makeStyles((theme) => ({
	root: {},
	title: {
		fontWeight: 600,
		fontSize: 18,
	},
	caption: {},
	button: {
		padding: 0,
		borderRadius: 0,
	},
	overline: {},
}))
