import React from 'react'
import {
	Button,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { Schedule } from '@material-ui/icons'
import CircleRating from '../../components/CircleRating'

const CourseMeta = ({
	course,
	fontSize = 12,
	...props
}) => {
	const classes = useStyles({
		fontSize,
	})
	return (
		<div className={classes.root}>
			<div className={classes.difficulty}>
				<Typography
					variant="caption"
					color="primary"
					className={classes.label}
				>
					DIFFICULTY
				</Typography>
				<CircleRating
					rating={course.difficulty}
					maxRating={5}
				/>
			</div>
			<div className={classes.schedule}>
				<Schedule className={classes.icon} />
				<Typography
					variant="caption"
					color="secondary"
					className={classes.secondaryLabel}
				>
					{course.total_lessons} SESSIONS
				</Typography>
			</div>
		</div>
	)
}

export default CourseMeta

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(1),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		maxWidth: 380,
	},
	difficulty: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		minWidth: 160,
	},
	schedule: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
	icon: {
		height: (props) =>
			props.fontSize > 12 ? 24 : 20,
		width: (props) => (props.fontSize > 12 ? 24 : 20),
		marginRight: 20,
		color: theme.palette.secondary.main,
		[theme.breakpoints.down('sm')]: {
			marginRight: 5,
		},
	},
	label: {
		fontSize: (props) => props.fontSize,
		marginRight: 20,
		[theme.breakpoints.down('sm')]: {
			marginRight: 5,
		},
	},
	secondaryLabel: {
		whiteSpace: 'nowrap',
		fontSize: (props) => props.fontSize,
	},
}))
