import React, {
	useContext,
	useEffect,
	useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import { useAlerts, useLoaders } from 'hooks'
import api from 'api'
import { AppContext } from 'context'
import { uniq } from 'lodash'
import auth from 'api/data/auth'
import profile from 'api/data/profile'

const useAuth = (props) => {
	const history = useHistory()

	const { showLoading, hideLoading } = useLoaders()

	const { showAlertError, showAlertSuccess } =
		useAlerts()

	const {
		authenticated,
		setAuthenticated,
		currentUser,
		setCurrentUser,
		setToken,
		token
	} = useContext(AppContext)

	const [user, setUser] = useState({})

	const handleChange = (ev) => {
		const { name } = ev.target
		let value =
			ev.target.type === 'checkbox'
				? ev.target.checked
				: ev.target.value
		setUser({
			...user,
			[name]: value,
		})
	}

	const fetchMe = async () => {
		try {
			let resp = await profile.getProfile();
			console.log(resp);
			if (resp && resp.data) {
				setCurrentUser(resp.data)
			}
			return resp.data
		} catch (e) {
			console.log(e)
			if (e?.status === 422) {
				showAlertError('Please sign in to continue.')
				localStorage.removeItem('token')
				window.location.href = '/'
			}
		}
	}

	const updateMe = async (user) => {
		showLoading()
		let resp = await api.put(
			`/api/v1/users/${user.id}`,
			{
				user: user,
			}
		)
		setCurrentUser(resp.data)
		hideLoading()
		return resp.data
	}

	const loginUser = async (user) => {
		try {
			showLoading()

			let resp = await auth.login({
				email: user.email,
				password: user.password,
			});

			if(resp.data) {
			  console.log(resp.data)
			  await setTokens(resp.token)
			  setCurrentUser(resp.data)
			  hideLoading()
			  window.location.href = '/home'
			  return resp.data
			}else{
			  hideLoading()
			  return false
			}
		  }catch(e){
				// console.log(e)
				showAlertError("Incorrect user credentials.")
				hideLoading()
		  }
	}

	const markViewedNotification = async (screenId) => {
		let viewedNotifications = uniq([
			...currentUser?.viewed_notifications,
			screenId,
		])
		await updateMe({
			id: currentUser.id,
			viewed_notifications: viewedNotifications,
		})
	}

	const signupUser = async (user) => {
		try {
			showLoading()
			let resp = await auth.register(user);
			console.log(resp)
			if (resp.data) {
			  await setTokens(resp.token)
			  // setCurrentUser(resp.data)
			  hideLoading()
			  return resp.data
			}else{
			  console.log(resp.data)
			  hideLoading()
			  return false
			}
	  
		  }catch(e){
			console.log(e)
			showAlertError(e.data.message)
			hideLoading()
		  }
	}

	const resetPassword = async (
		password,
		passwordConfirmation,
		code
	) => {
		try {
			showLoading()
			let resp = await api.post(
				'/api/auth/reset-password',
				{
					password: password,
					password_confirmation: passwordConfirmation,
					code: code
				}
			)
			hideLoading()
			if (resp.data) {
				setCurrentUser(resp.data)
				setAuthenticated(true)
				setTokens(resp.token)
				return resp.data
			} else {
				return false
			}
		} catch (e) {
			showAlertError(
				'There was an error resetting your password'
			)
			hideLoading()
		}
	}

	const verifyPin = async (pin, email) => {
		let verified = false
		try {
			showLoading()
			let resp = await api.post(
				'/api/auth/confirm-code',
				{
					code: pin
				}
			)
			if (resp.data?.id) {
				setCurrentUser(resp.data)
				setAuthenticated(true)
				setTokens(resp.data)
				return resp.data
			} else {
				return false
			}
			hideLoading()
			return verified
		} catch (e) {
			hideLoading()
			return verified
		}
	}

	const forgotPassword = async (email) => {
		try {
			showLoading()
			let resp = await api.post(
				'/api/auth/forgot-password',
				{
					email: email,
				}
			)
			console.log(resp)
			setCurrentUser(resp.data)
			hideLoading()
			return resp
		} catch (e) {
			showAlertError(
				'A user with that email could not be found'
			)
			hideLoading()
		}
	}

	const logoutUser = () => {
		setCurrentUser(null)
		setAuthenticated(null)
		localStorage.removeItem('token')
	}

	const authenticateFromToken = (token) => {
		if (token) {
			setAuthenticated(true)
			setToken(token)
		} else {
			return false
		}
	}

	const setTokens = (token) => {
		setToken(token)
		localStorage.setItem('token', token)
	}

	useEffect(() => {
		if (token && !authenticated && currentUser != null) {
			setTokens(token)
			setAuthenticated(true)
		}
	}, [currentUser])

	const useQueryParams = (query) => {   
		const result = {};

		console.log(window.location.href)
		if (window.location.href.includes("?")){   
		new URLSearchParams(query||window.location.search).forEach((value, key) => {
		  result[key] = value;
		});
		console.log(result.token);
		setToken(result.token)
		localStorage.setItem('token', result.token)
		setAuthenticated(true)
		window.location.href = '/home'

	}
	return result;
	}

	return {
		useQueryParams,
		user,
		currentUser,
		handleChange,
		fetchMe,
		updateMe,
		loginUser,
		signupUser,
		logoutUser,
		verifyPin,
		resetPassword,
		forgotPassword,
		markViewedNotification,
		authenticateFromToken,
	}
}

export default useAuth
