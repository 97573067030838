import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Bar from 'containers/skills/Bar'

const Bars = ({
	points = 0,
	newPoints = 0,
	maxPoints = 5,
}) => {
	const classes = useStyles()

	let whiteBarCount = Math.max(points - newPoints, 0)
	let greenBarCount = newPoints
	let greyBarCount =
		maxPoints - whiteBarCount - greenBarCount

	let whiteBars
	if (whiteBarCount > 0) {
		whiteBars = new Array(whiteBarCount).fill(0)
	}

	let greenBars
	if (greenBarCount > 0) {
		greenBars = new Array(greenBarCount).fill(0)
	}

	let greyBars
	if (greyBarCount > 0) {
		greyBars = new Array(greyBarCount).fill(0)
	}

	return (
		<div className={classes.row}>
			{whiteBars &&
				whiteBars.map((j, i) => (
					<Bar
						key={i}
						max={maxPoints}
						color={'primary'}
					/>
				))}
			{greenBars &&
				greenBars.map((j, i) => (
					<Bar
						key={i}
						color={'secondary'}
						max={maxPoints}
					/>
				))}
			{greyBars &&
				greyBars.map((j, i) => (
					<Bar
						key={i}
						color={'grey'}
						max={maxPoints}
					/>
				))}
		</div>
	)
}

export default Bars

const useStyles = makeStyles((theme) => ({
	row: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
}))
