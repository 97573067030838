import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const SearchTags = ({
	tags,
	activeTags,
	handleClick,
}) => {
	const classes = useStyles()
	return (
		<div className={classes.root}>
			{Array.isArray(tags) && tags?.map((tag, i) => (
				<Button
					key={i}
					variant="outlined"
					onClick={() => handleClick(tag.value)}
					className={clsx(classes.tag, {
						[classes.selected]: activeTags?.includes(
							tag.value
						),
					})}
				>
					{tag.label}
				</Button>
			))}
		</div>
	)
}

export default SearchTags

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	tag: {
		fontSize: 20,
		borderRadius: 20,
		fontFamily: theme.typography.subtitle1.fontFamily,
		padding: theme.spacing(0, 2),
		border: `1px solid ${theme.palette.primary.main}`,
		margin: theme.spacing(0.5),
		[theme.breakpoints.down('sm')]: {
			fontSize: 15,
		},
	},
	selected: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
		},
	},
}))
