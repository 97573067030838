import React from 'react'
import { usePaywall } from 'hooks'
import {
	CardActionArea,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { CoverImage } from 'components'
import EventDate from 'containers/events/EventDate'
import moment from 'moment'

const EventCover = ({
	event,
	isLocked = false,
	handleClick,
	...props
}) => {
	const classes = useStyles()
	const { isPaid } = usePaywall()
	return (
		<div>
			<CardActionArea
				onClick={() => handleClick(event)}
			>
				<CoverImage
					mobileImageUrl={event.image_url}
					desktopImageUrl={event.web_image_url}
					maxHeight={500}
					borderRadius
					opacity={isLocked ? 0.5 : 1}
					secondaryAction={
						<EventDate event={event} />
					}
				/>
			</CardActionArea>
			<Typography
				variant="body2"
				color="secondary"
				className={classes.caption}
			>
				{moment(event?.start_date).format(
					'MM/DD/YYYY'
				)}
			</Typography>
			<Typography
				variant="subtitle1"
				color="textPrimary"
				className={classes.subtitle}
			>
				{event.title}
			</Typography>
		</div>
	)
}

export default EventCover

const useStyles = makeStyles((theme) => ({
	root: {},
	caption: {
		textTransform: 'uppercase',
	},
	subtitle: {
		fontSize: 17,
	},
}))
