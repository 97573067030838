import React, { useState, useEffect } from 'react'
import { Modal, RatingInput } from 'components'
import {
	Button,
	Grid,
	Container,
	makeStyles,
	Typography,
} from '@material-ui/core'
import { FILTER_POSITIONS, GetFilterTags } from 'lib/constants'
import SearchTags from 'containers/search/SearchTags'
import SearchPlayerTags from 'containers/search/SearchPlayerTags'
import api from 'api'
import useFilterTags from 'hooks/useFilterTags'

const SearchFilterModal = ({
	open,
	filters,
	setFilters,
	handleClose,
	handleFilterChange,
	handleSubmit
}) => {
	const classes = useStyles()
	const [disableApplyFilters, setDisableApplyFilters] = useState(false)
	const handleResetClick = () => {
		setFilters({
			tags: [],
      trainer_ids: [],
			difficulty: null,
		})
		setDisableApplyFilters(false)
	}

	const handleDifficultyChange = (event) => {
		const { name, value } = event.target
		handleFilterChange(name, value)
		setDisableApplyFilters(checkNoFilters())
	}

	const handleTagClick = (tag) => {
		console.log("tag click")

		let newTags = []
		console.log("filter")
		console.log(filters.tags)
		console.log(tag)
		if (filters.tags.includes(tag)) {
			newTags = filters.tags.filter((t) => t !== tag)
		} else {
			newTags = [...filters.tags, tag]
		}
		handleFilterChange('tags', newTags)
		setDisableApplyFilters(checkNoFilters())
	}

	const handlePlayerTagClick = (tag) => {
		let newTags = []
		if (filters.trainer_ids.includes(tag)) {
			newTags = filters.trainer_ids.filter(
				(t) => t !== tag
			)
		} else {
			newTags = [...filters.trainer_ids, tag]
		}
		handleFilterChange('trainer_ids', newTags)
		setDisableApplyFilters(checkNoFilters())

		console.log("handle player tag")
	}

	// const [filterTags, setFilterTags] = useState([])

	// useEffect(async () => {
	// 	setFilterTags(await GetFilterTags())
	// }, [])

	// console.log(filterTags)

	const {
		findFilterTags,
		filterTags
	} = useFilterTags({})

	useEffect(() => {
		findFilterTags({}, 1, false, "/api/course/filter/tags")
	}, [])

	const formatFilterTags = (input) => {
		let retVal = []
		for(let i = 0; i < input.length; i++) {
			retVal.push({value: input[i]?.id, label: input[i]?.name})
		}
		return retVal
	}

	const checkNoFilters = () => {
		console.log(1234455)

		if(
			Array.isArray(filters.tags) || !filters.tags.length ||
			Array.isArray(filters.trainer_ids) || !filters.trainer_ids.length ||
			filters.trainer_ids == null
		) {
			return true
		} else {
			return false
		}
	}
	console.log(checkNoFilters())
	console.log("hereeeeee", disableApplyFilters)

	return (
		<Modal
			fullScreen
			maxWidth="md"
			open={open}
			handleClose={handleClose}
		>
			<Container>
				<div className={classes.container}>
					<Typography variant="h3">
						FILTERS
					</Typography>
					<Typography variant="h3">
						POSITION{' '}
						{filters?.tags?.length > 0 && (
							<span>({filters?.tags?.length})</span>
						)}
					</Typography>
					<SearchTags
						tags={formatFilterTags(filterTags)}
						activeTags={filters?.tags}
						handleClick={handleTagClick}
					/>
					<div className={classes.divider} />
					<Typography variant="h3">
						PLAYER{' '}
						{filters?.trainer_ids?.length > 0 && (
							<span>
								({filters?.trainer_ids?.length})
							</span>
						)}
					</Typography>
					<SearchPlayerTags
						activeTags={filters?.trainer_ids}
						handleClick={handlePlayerTagClick}
					/>
					<div className={classes.divider} />
					<Typography variant="h3">
						DIFFICULTY
					</Typography>
					<RatingInput
						label="LEVEL"
						name="difficulty"
						value={filters?.difficulty}
						handleChange={handleDifficultyChange}
					/>
					<div className={classes.divider} />
					<div className={classes.buttonContainer}>
						<Grid container spacing={1}>
							<Grid item xs={6} sm={6}>
								<Button
									fullWidth
									variant="contained"
									className={classes.resetButton}
									onClick={handleResetClick}
								>
									RESET
								</Button>
							</Grid>
							<Grid item xs={6} sm={6}>
								<Button
									fullWidth
									className={classes.submitButton}
									onClick={handleSubmit}
									variant="contained"
									disabled={!disableApplyFilters}
									classes={{ disabled: classes.disableApplyFilters }}
								>
									APPLY FILTERS
								</Button>
							</Grid>
						</Grid>
					</div>
				</div>
			</Container>
		</Modal>
	)
}

export default SearchFilterModal

const useStyles = makeStyles((theme) => ({
	root: {},
	modalContent: {
		padding: theme.spacing(2),
	},
	buttonContainer: {
		marginTop: theme.spacing(2),
	},
	submitButton: {
		border: `1px solid ${theme.palette.primary.main}`,
	},
	resetButton: {
		'&&': {
			color: theme.palette.background.main,
			backgroundColor: '#8C8C8C',
			border: `1px solid ${theme.palette.primary.main}`,
		},
	},
	divider: {
		paddingTop: theme.spacing(2),
		borderBottom: `1px solid ${theme.palette.primary.light}`,
	},
	disableApplyFilters: {
		backgroundColor: "#f5f5f5 !important"
	}
}))
