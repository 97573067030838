import React from 'react'
import { MaskInput } from 'components'

const DateInput = ({
	value,
	label,
	name,
	borderBottom,
	placeholder,
	handleChange,
}) => {
	return (
		<MaskInput
			mask={'99/99/9999'}
			value={value}
			label={label}
			name={name}
			placeholder={placeholder}
			handleChange={handleChange}
			borderBottom={borderBottom}
		/>
	)
}

export default DateInput
