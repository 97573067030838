import React, {
	useContext,
	useState,
	useEffect,
} from 'react'
import { AppContext } from 'context'
import { CoverImage } from 'components'
import { useResponsive } from 'hooks'
import {
	Box,
	Container,
	Grid,
	makeStyles,
	Typography,
} from '@material-ui/core'
import { useHistory } from 'react-router'
import { cover, resize } from 'lib/cloudinary'
import {
	COVER_HEIGHT,
	COVER_MOBILE_HEIGHT,
} from 'lib/constants'

const ActiveTraining = ({ handleClick, course }) => {
	const history = useHistory()

	const { isMobile } = useResponsive()
	const { currentUser } = useContext(AppContext)

	const classes = useStyles({
		src: isMobile
			? course?.image_url
			: course.image_url,
	})
	const lesson = course.lessons.find(lesson => !lesson.completed)

	return (
		<div
			onClick={() => handleClick(lesson)}
			className={classes.cover}
		>
			<Container
				maxWidth="lg"
				className={classes.content}
			>
				<div>
					<Typography
						variant="h5"
						color="secondary"
						className={classes.activeTraining}
					>
						ACTIVE TRAINING
					</Typography>
					<Typography variant="h5" color="primary">
						{course.title}
					</Typography>
					<Typography
						variant="subtitle2"
						color="secondary"
						className={classes.activeTrainingCaption}
					>
						WITH {course?.trainer}
					</Typography>
				</div>
				<div className={classes.nextSession}>
					<div>
						<Typography
							variant="h6"
							color="secondary"
							className={classes.sessionText}
						>
							NEXT SESSION:
						</Typography>
						<Typography
							variant="h3"
							color="primary"
							className={classes.sessionText}
						>
							{lesson.title}
						</Typography>
					</div>
					<div className={classes.sessionProgress}>
						<Typography
							variant="h6"
							color="secondary"
							className={classes.sessionText}
						>
							SESSION
						</Typography>
						<Typography
							variant="h3"
							color="primary"
							className={classes.sessionText}
						>
							{lesson.position}/{course.total_lessons}
						</Typography>
					</div>
				</div>
			</Container>
		</div>
	)
}

export default ActiveTraining

const useStyles = makeStyles((theme) => ({
	cover: {
		cursor: 'pointer',
		width: '100%',
		height: COVER_HEIGHT,
		backgroundPosition: '50% 25%',
		backgroundImage: (props) => `url(${props.src}) `,
		backgroundSize: 'cover',
		maskImage:
			'linear-gradient(to top, rgba(0,0,0,0.05) 0%,rgba(0,0,0,1.0) 15%)',
		[theme.breakpoints.down('sm')]: {
			height: COVER_MOBILE_HEIGHT,
		},
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'space-between',
		height: '100%',
		width: '100%',
	},
	activeTraining: {
		marginTop: theme.spacing(2),
		lineHeight: 0.9,
		color: theme.palette.background.main,
		[theme.breakpoints.down('xs')]: {
			color: theme.palette.secondary.main,
		},
	},
	activeTrainingCaption: {
		lineHeight: 0.9,
		textTransform: 'uppercase',
		color: theme.palette.background.main,
		[theme.breakpoints.down('xs')]: {
			color: theme.palette.secondary.main,
		},
	},
	caption: {
		textTransform: 'uppercase',
	},
	nextSession: {
		cursor: 'pointer',
		margin: theme.spacing(0, 0, 6, 0),
		padding: theme.spacing(1),
		border: `1px solid ${theme.palette.secondary.main}`,
		borderRadius: 6,
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		backgroundColor: theme.palette.background.main,
		maxWidth: 540,
	},
	caption: {
		lineHeight: 0.9,
	},
	sessionProgress: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
	},
	sessionText: {
		lineHeight: 0.9,
	},
}))
