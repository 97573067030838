import React, { useEffect } from 'react'
import {
	usePaywall,
	useAuth,
	useArticles,
	useResponsive,
} from 'hooks'
import { useHistory } from 'react-router-dom'
import {
	StickyButton,
	Placeholder,
	PageHeader,
} from 'components'
import {
	Box,
	Button,
	Container,
	Grid,
	makeStyles,
} from '@material-ui/core'
import ArticleCover from 'containers/articles/ArticleCover'
import ArticleItem from 'containers/articles/ArticleItem'
import PremiumSignupModal from 'containers/payments/PremiumSignupModal'
import {
	Lock,
	ScheduleOutlined,
} from '@material-ui/icons'
import Pagination from '@material-ui/lab/Pagination'

const ArticleContainer = ({ ...props }) => {
	const history = useHistory()
	const classes = useStyles()

	const {
		isPaid,
		showPaywall,
		hidePaywall,
		openPaywall,
	} = usePaywall()

	const {
		articles,
		findArticles,
		paginate,
		page,
		numPages,
	} = useArticles({})

	const { isMobile } = useResponsive()
	const handleArticleClick = (article) => {
		if (!isPaid) {
			//showPaywall()
			history.push(`/articles/${article.id}`)
		} else {
			history.push(`/articles/${article.id}`)
		}
	}

	useEffect(() => {
		findArticles({})
	}, [])

	const isLocked = false

	return (
		<div>
			<Container maxWidth="lg">
				<PageHeader title="The Boot Room" />
				{articles?.length > 0 ? (
					<div>
						{!isMobile ? (
							<ArticleCover
								article={articles[0]}
								handleClick={handleArticleClick}
								isLocked={isLocked}
							/>
						) : (
							<ArticleItem
								article={articles[0]}
								handleClick={handleArticleClick}
								isLocked={isLocked}
							/>
						)}
						<div className={classes.articleList}>
							<Grid container spacing={1}>
								{articles &&
									articles.map((article, i) =>
										i == 0 && page == 1 ? null : (
											<Grid
												item
												xs={12}
												sm={12}
												md={6}
												lg={4}
												key={article.id}
											>
												<ArticleItem
													article={article}
													handleClick={
														handleArticleClick
													}
													isLocked={isLocked}
												/>
											</Grid>
										)
									)}
							</Grid>
						</div>
					</div>
				) : (
					<Placeholder
						icon={<ScheduleOutlined />}
						title="No Articles."
						subtitle="There are currently no articles."
					/>
				)}

				<Box my={4}>
					<Pagination
						onChange={(ev, page) => paginate(page)}
						color="primary"
						size="small"
						shape="rounded"
						page={page}
						count={numPages}
					/>
				</Box>
			</Container>
			<div className={classes.spacer} />
			<PremiumSignupModal
				open={openPaywall}
				handleClose={hidePaywall}
			/>
			{isLocked && (
				<StickyButton>
					<Button
						fullWidth
						variant="contained"
						color="primary"
						onClick={showPaywall}
						startIcon={<Lock />}
					>
						UPGRADE NOW FOR ACCESS
					</Button>
				</StickyButton>
			)}
		</div>
	)
}

export default ArticleContainer

const useStyles = makeStyles((theme) => ({
	root: {},
	title: {},
	articleList: {
		marginTop: 40,
	},
	caption: {
		textAlign: 'center',
	},
	button: {
		padding: 0,
		borderRadius: 0,
	},
	spacer: {
		height: 100,
	},
}))
