import React, { useEffect } from 'react'
import { useUsers, useAuth, useAlerts } from 'hooks'
import { AuthScreen, TextInput } from 'components'
import {
	Link,
	Button,
	Box,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const ParentalApprovalRequest = ({ ...props }) => {
	const classes = useStyles()
	const history = useHistory()

	const { currentUser } = useAuth()

	useEffect(() => {
		if (currentUser?.parent_approved) {
			history.push('/users/me')
		}
	}, [currentUser])

	return (
		<div>
			<AuthScreen title="Parental Approval">
				<Typography
					variant="subtitle1"
					className={classes.notification}
				>
					Awaiting approval from your parent/guardian.
				</Typography>
				<center>
					<Box width={244} my={2}>
						<Button
							fullWidth
							color="primary"
							variant="contained"
							className={classes.button}
						>
							Awaiting Approval
						</Button>
					</Box>
				</center>
				<Box my={4} />
				<center>
					<Typography variant="body1">
						Already have an account?
					</Typography>
					<Typography variant="body1">
						<Link color="secondary" href="/login">
							Sign In
						</Link>
					</Typography>
				</center>
			</AuthScreen>
		</div>
	)
}

export default ParentalApprovalRequest

const useStyles = makeStyles((theme) => ({
	notification: {
		fontSize: 13,
		padding: theme.spacing(2, 0),
		textTransform: 'uppercase',
		color: theme.palette.text.primary,
		borderTop: `1px solid ${theme.palette.primary.light}`,
		borderBottom: `1px solid ${theme.palette.primary.light}`,
		whiteSpace: 'pre-line',
	},
	buttons: {
		marginTop: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	button: {
		opacity: 0.6,
	},
}))
