import React from 'react'
import {
	Box,
	Avatar,
	Typography,
	makeStyles,
} from '@material-ui/core'

const Placeholder = ({
	icon,
	title,
	subtitle,
	...props
}) => {
	const classes = useStyles()

	return (
		<Box
			p={2}
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			height="100%"
			width="100%"
		>
			{icon && (
				<Avatar className={classes.avatar}>
					{icon}
				</Avatar>
			)}
			<Typography variant="h5">{title}</Typography>
			<Typography variant="body2">
				{subtitle}
			</Typography>
		</Box>
	)
}
export default Placeholder

const useStyles = makeStyles((theme) => ({
	avatar: {
		height: '50px',
		width: '50px',
		backgroundColor: theme.palette.secondary.main,
	},
}))
