import React, { useState, useEffect } from 'react'

// Helper hook to run a function on Enter key press
const useVideo = () => {
	const [activeVideo, setActiveVideo] = useState()
	const [videoOpen, setVideoOpen] = useState(false)

	const playVideo = (item, src="", title="") => {
		console.log(item)
		setActiveVideo({
			src: src == "" ? item?.video.video_url : src,
			title: title == "" ? item?.title : title,
		})
		setVideoOpen(true)
	}

	const playVideoUrl = (videoUrl) => {
		setActiveVideo({
			src: videoUrl,
		})
		setVideoOpen(true)
	}

	const playVideoId = (videoId) => {
		let videoUrl = `https://content.jwplatform.com/videos/${videoId}.mp4`
		setActiveVideo({
			src: videoUrl,
		})
		setVideoOpen(true)
	}

	const stopVideo = () => {
		setActiveVideo()
		setVideoOpen(false)
	}

	return {
		src: activeVideo?.src,
		videoId: activeVideo?.videoId,
		captions: activeVideo?.captions,
		activeVideo,
		setActiveVideo,
		playVideo,
		playVideoUrl,
		playVideoId,
		stopVideo,
		videoOpen,
	}
}

export default useVideo
