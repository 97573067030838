import React from 'react'
import { TextInput } from 'components'
import {
	Link as RouterLink,
	useHistory,
} from 'react-router-dom'
import {
	Box,
	Button,
	Grid,
	Link,
	Typography,
	makeStyles,
} from '@material-ui/core'

const UserForm = ({
	user,
	handleKeyPress,
	handleChange,
	handleSubmit,
}) => {
	const history = useHistory()
	const classes = useStyles()
	const handleResetClick = () => {
		history.push('/forgot_password')
	}

	return (
		<Grid container spacing={0}>
			<Grid item xs={12} sm={12}>
				<TextInput
					border
					required={true}
					name="email"
					value={user.email}
					onKeyPress={handleKeyPress}
					placeholder="EMAIL"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					required={true}
					name="password"
					value={user.password}
					type="password"
					onKeyPress={handleKeyPress}
					placeholder="PASSWORD"
					handleChange={handleChange}
				/>
				<div className={classes.resetPasswordLink}>
					<Button
						onClick={handleResetClick}
						disableRipple
						className={classes.resetPasswordButton}
					>
						FORGOT PASSWORD?
					</Button>
				</div>
			</Grid>
			<Grid item xs={12} sm={12}>
				<center>
					<Box my={6}>
						<Button
							className={classes.button}
							color="primary"
							onClick={handleSubmit}
							variant="contained"
						>
							Sign In
						</Button>
					</Box>
				</center>
			</Grid>
			<Box my={4} />
			<Grid item xs={12} sm={12}>
				<center>
					<Typography variant="body1">
						Don't Have An Account?
					</Typography>
					<Typography variant="body1">
						<Link
							color="secondary"
							component={RouterLink}
							to="/signup"
						>
							Sign Up
						</Link>
					</Typography>
				</center>
			</Grid>
		</Grid>
	)
}

export default UserForm

const useStyles = makeStyles((theme) => ({
	resetPasswordLink: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
	button: {
		width: 244,
	},
	resetPasswordButton: {
		width: 140,
		fontSize: 12,
	},
}))
