import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import {
	useVideo,
	useLessons,
	useAlerts,
	usePaywall,
} from 'hooks'
import { VideoBackground } from 'components'
import {
	PlayArrow,
	ExpandMore,
} from '@material-ui/icons'
import {
	Box,
	IconButton,
	Typography,
	Container,
	makeStyles,
} from '@material-ui/core'
import LessonJWPlayerModal from 'containers/lessons/LessonPlayerModal'
import WhatYouNeedIcons from 'containers/lessons/WhatYouNeedIcons'
import ProgressBar from 'containers/skills/ProgressBar'
import {
	COVER_HEIGHT,
	VIDEO_MOBILE_HEIGHT,
	SKILLS,
} from 'lib/constants'

const LessonShow = ({ ...props }) => {
	const history = useHistory()
	const { id } = props.match.params
	const classes = useStyles()

	const { isLoaded, lesson, findLesson } = useLessons(
		{ id: id }
	)
	const { currentUser, isPaid } = usePaywall()
	const { playVideo, stopVideo, src, videoOpen } =
		useVideo()

	const handleLessonClick = () => {
		playVideo(lesson)
	}

	useEffect(() => {
		if (id) {
			findLesson(id)
		}
	}, [id])

	useEffect(() => {
		if (
			lesson?.premium &&
			currentUser?.id &&
			!isPaid
		) {
			history.push('/home')
		}
	}, [isPaid, currentUser, lesson])
	console.log(lesson)
	return (
		<>
			{isLoaded && (
				<>
					<VideoBackground
						mobileVideoUrl={lesson?.video?.preview_video_url}
						desktopVideoUrl={lesson?.video?.preview_video_url}
					/>
					<IconButton
						className={classes.iconButton}
						onClick={handleLessonClick}
					>
						<PlayArrow className={classes.playIcon} />
					</IconButton>
					<Container maxWidth="sm">
						<Box p={2}>
							<center>
								<Typography
									variant="body2"
									color="secondary"
									className={classes.subtitle}
								>
									{lesson?.category
										?.replace('_', ' ')
										.toUpperCase()}{' '}
									TRAINING
								</Typography>
								<Typography
									variant="h2"
									color="textPrimary"
									className={classes.title}
								>
									{lesson.title}
								</Typography>
								<Typography
									variant="body2"
									color="primary"
									className={classes.description}
								>
									{lesson.description}
								</Typography>
								<Typography
									variant="body2"
									color="secondary"
									className={classes.moreLabel}
								>
									MORE DETAILS
								</Typography>
								<ExpandMore
									className={classes.icon}
								/>
							</center>
							<Typography
								variant="subtitle1"
								color="secondary"
								className={classes.subheader}
							>
								TOP SKILLS
							</Typography>
							{
								lesson.skills_lesson.map((skill, i) =>
									skill.point > 0 ?
									<ProgressBar
										key={i}
										label={SKILLS.get(skill.name)}
										icon={skill.name}
										points={skill.point}
									/>
										: null
								)
}
							{SKILLS.forEach((value, key) => {
								const skillPoints = `${key}_points`
								const points = lesson[skillPoints] || 0
								return Number(points) > 0 ? (
									<ProgressBar
										key={key}
										label={value}
										icon={key}
										points={Number(points)}
									/>
								) : null
							})}
							<WhatYouNeedIcons
								icons={lesson.what_you_need}
								flexDirection="column-reverse"
							/>
						</Box>
					</Container>
					<LessonJWPlayerModal
						src={src}
						lesson={lesson}
						open={videoOpen}
						handleClose={stopVideo}
					/>
				</>
			)}
		</>
	)
}

export default LessonShow

const useStyles = makeStyles((theme) => ({
	title: {},
	subtitle: {},
	description: {
		marginTop: theme.spacing(2),
		maxWidth: 660,
	},
	moreLabel: {
		marginTop: theme.spacing(4),
	},
	playIcon: {
		height: 90,
		width: 90,
	},
	icon: {
		height: 40,
		width: 40,
	},
	subheader: {
		marginBottom: theme.spacing(4),
	},
	iconButton: {
		position: 'absolute',
		top: COVER_HEIGHT / 2 - 20,
		left: '48%',
		[theme.breakpoints.down('sm')]: {
			top: VIDEO_MOBILE_HEIGHT / 2 - 20,
			left: '40%',
		},
	},
	icon: {
		height: 50,
		width: 50,
	},
}))
