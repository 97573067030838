import React from 'react'
import {
	Typography,
	makeStyles,
} from '@material-ui/core'
import FootballCard from '../../assets/images/Football_Card.png'
import { Button } from 'react-scroll'
import { ButtonLoader } from 'components'
import { useUsers } from 'hooks'

const PlayerCard = ({ attempt }) => {
	const classes = useStyles()
	const { isLoading, enrollCourse } = useUsers()

	return (
		<div className={classes.playerCard}>
			<img
				src={FootballCard}
				className={classes.imagePlayerCard}
			/>

			<Typography className={[classes.playerCardOverall, classes.playerCardElement]}>Overall</Typography>
			<Typography className={[classes.playerCardScore, classes.playerCardElement]}>{attempt?.feedback?.overall}</Typography>
			
			{
				attempt?.feedback == null && (
					<div className={classes.cardBeingBuilt}>
						Player card being built...
					</div>
				)
			}


			{/* <Button
				fullWidth
				variant="contained"
				// onClick={handleNavigateFeedbackUpload}
				// startIcon={isLoading && <ButtonLoader />}
				className={classes.buttonCta}
			>
				Player card being built...
			</Button> */}

			<Typography className={[classes.playerCardName, classes.playerCardElement]}>
				{ attempt?.feedback?.fullname }
			</Typography>

			<Typography className={[classes.playerCardSho, classes.playerCardElement]}>
				SHO
				{
					attempt?.feedback?.sho
						? <span className={classes.playerCardIndividualScore}>{attempt?.feedback?.sho}</span>
						: <span className={classes.playerCardIndividualScore}>-</span>
				}
			</Typography>

			<Typography style={{ top: "65%" }} className={[classes.playerCardSho, classes.playerCardElement]}>
				CON
				{
					attempt?.feedback?.con
						? <span className={classes.playerCardIndividualScore}>{attempt?.feedback?.con}</span>
						: <span className={classes.playerCardIndividualScore}>-</span>
				}
			</Typography>

			<Typography style={{ top: "71%" }} className={[classes.playerCardSho, classes.playerCardElement]}>
				PAS
				{
					attempt?.feedback?.pas
						? <span className={classes.playerCardIndividualScore}>{attempt?.feedback?.pas}</span>
						: <span className={classes.playerCardIndividualScore}>-</span>
				}
			</Typography>

			<Typography style={{ left: "62%" }} className={[classes.playerCardSho, classes.playerCardElement]}>
				DRI
				{
					attempt?.feedback?.dri
						? <span className={classes.playerCardIndividualScore}>{attempt?.feedback?.dri}</span>
						: <span className={classes.playerCardIndividualScore}>-</span>
				}
			</Typography>

			<Typography style={{ top: "65%", left: "62%" }} className={[classes.playerCardSho, classes.playerCardElement]}>
				PHY
				{
					attempt?.feedback?.phy
						? <span className={classes.playerCardIndividualScore}>{attempt?.feedback?.phy}</span>
						: <span className={classes.playerCardIndividualScore}>-</span>
				}
			</Typography>

			<Typography style={{ top: "71%", left: "62%" }} className={[classes.playerCardSho, classes.playerCardElement]}>
				DEF
				{
					attempt?.feedback?.def
						? <span className={classes.playerCardIndividualScore}>{attempt?.feedback?.def}</span>
						: <span className={classes.playerCardIndividualScore}>-</span>
				}
			</Typography>
		</div>
	)
}

export default PlayerCard

const useStyles = makeStyles((theme) => ({
	playerCard: {
		position: "relative",
	},

	playerCardSho: {
		position: "absolute",
		fontSize: "32px",
		transform: "translate(-50%, -50%)",
		top: "59%",
		left: "37%"
	},

	playerCardElement: {
		color: "#FFFFFF",
		fontFamily: "Tungsten Bold",
		fontWeight: "600",
		textTransform: "uppercase",
		lineHeight: 1
	},

	playerCardOverall: {
		position: "absolute",
		right: "12%",
		top: "27%",
		fontSize: "32px",
		transform: "translate(-50%, -50%)"
	},

	playerCardIndividualScore: {
		color: "#6BE0A0",
		marginLeft: "10px"
	},

	playerCardScore: {
		color: "#6BE0A0",
		position: "absolute",
		left: "71%",
		fontSize: "120px",
		transform: "translate(-50%, -50%)",
		top: "36%"
	},

	playerCardName: {
		position: "absolute",
		transform: "translate(-50%, -50%)",
		top: "50%",
		left: "50%",
		fontSize: "40px"
	},

	imagePlayerCard: {
		width: "100%",
		height: "100%"
	},

	buttonCta: {
		width: "40%",
		backgroundColor: "white"
	},

	cardBeingBuilt: {
		width: "50%",
		position: "absolute",
		left: "50%",
		top: "50%",
		transform: "translate(-50%, -50%)",
		color: "#1E1E1E",
		backgroundColor: "#FFFFFF",
		borderRadius: "100px",
		textAlign: "center",
		textTransform: "uppercase",
		fontWeight: 700,
		lineHeight: "17px",
		padding: "8px",
		fontSize: "14px"
	}
}))
