import React, {
	useContext,
	useState,
	useEffect,
} from 'react'
import PropTypes from 'prop-types'
import {
	useVideo,
	usePaywall,
	useScrollTop,
	useHome,
	useResponsive,
	useAuth,
} from 'hooks'
import { AppContext } from 'context'
import {
	Box,
	Button,
	Container,
	Grid,
	Typography,
	makeStyles,
	Link,
} from '@material-ui/core'
import {
	MobileCarousel,
	VideoModal,
	PaywallOverlay,
} from 'components'
import {
	useHistory,
	Link as RouterLink,
} from 'react-router-dom'
import ArticleCover from 'containers/articles/ArticleCover'
import ArticleItem from 'containers/home/ArticleItem'
import EditorialCover from 'containers/editorials/EditorialCover'
import EditorialItem from 'containers/editorials/EditorialItem'
import BenefitItem from 'containers/home/BenefitItem'
import TopicItem from 'containers/home/TopicItem'
import EventItem from 'containers/home/EventItem'
import ActiveTraining from 'containers/home/ActiveTraining'
import RecommendedTraining from 'containers/home/RecommendedTraining'
import PremiumSignupModal from 'containers/payments/PremiumSignupModal'
import {
	ENABLE_ARTICLES,
	ENABLE_EVENTS,
} from 'lib/constants'
import clsx from 'clsx'
import CourseList from 'containers/courses/CourseList'
import FeedbackPlayerCard from './FeedbackPlayerCard'

const Home = () => {
	useScrollTop()

	const classes = useStyles()
	const history = useHistory()

	const {
		videoOpen,
		activeVideo,
		playVideo,
		stopVideo,
		src,
		captions,
		videoId,
	} = useVideo()

	const { isMobile } = useResponsive()

	const { fetchMe } = useAuth()
	const { currentUser } = useContext(AppContext)

	const {
		isPaid,
		openPaywall,
		showPaywall,
		hidePaywall,
	} = usePaywall()

	const { data, loadHome } = useHome()

	const handleEventClick = (event) => {
		if (!isPaid) {
			showPaywall()
		} else {
			history.push(`/events/${event.id}`)
		}
	}

	const handleHeroClick = () => {
		history.push(`/feedback`)
	}

	const handleCourseClick = (course) => {
		if (!isPaid && course.premium) {
			showPaywall()
		} else {
			history.push(`/courses/${course.id}/enroll`)
		}
	}

	const handleBenefitClick = (benefit) => {
		if (!isPaid) {
			showPaywall()
		} else {
			history.push(`/benefits/${benefit?.id}`)
		}
	}

	const handleEditorialClick = (editorial) => {
		if (!isPaid && editorial.premium) {
			showPaywall()
		} else {
			playVideo(editorial)
		}
	}

	const handleTopicClick = (topic) => {
		history.push(`/topics/${topic?.id}`)
	}

	const handleArticleClick = (article) => {
		history.push(`/articles/${article?.id}`)
	}

	useEffect(() => {
		loadHome()
		fetchMe()
	}, [])

	const {
		articles,
		// benefits,
		activeTraining,
		// editorials,
		// events,
		recommended,
		// topics,
		challengeStatus
	} = data || {}

	// console.log(currentUser)
	// console.log(activeTraining)
	console.log(recommended)
	console.log(challengeStatus)

	return (
		<div>
			<Container className={classes.heroContainer} maxWidth="lg">
				<div>
					<FeedbackPlayerCard
						handleClick={handleHeroClick}
						challengeStatus={challengeStatus}
					/>
				</div>
			</Container>

			<CourseList />

			<Container maxWidth="lg">
				{/* <center className={classes.subheader}>
					<Typography variant="h3" color="primary">
						Editorial Videos
					</Typography>
					<Typography
						variant="subtitle1"
						color="primary"
					>
						Original video series with some of the
						biggest and brightest stars in the game.
					</Typography>
				</center>

				{!isMobile ? (
					<div>
						{editorials?.length > 0 && (
							<div>
								<Box my={3}>
									<EditorialCover
										editorial={editorials[0]}
										handleClick={handleEditorialClick}
									/>
								</Box>
								<Grid container spacing={1}>
									{editorials
										?.slice(1, 4)
										.map((editorial, i) => (
											<Grid
												item
												xs={12}
												sm={6}
												md={4}
												key={i}
											>
												<EditorialItem
													editorial={editorial}
													handleClick={
														handleEditorialClick
													}
												/>
											</Grid>
										))}
								</Grid>
							</div>
						)}
					</div>
				) : (
					<MobileCarousel>
						{editorials
							?.slice(0, 4)
							.map((editorial, i) => (
								<EditorialItem
									key={i}
									editorial={editorial}
									handleClick={handleEditorialClick}
								/>
							))}
					</MobileCarousel>
				)}

				<center>
					<Button
						className={classes.seeAllButton}
						color="secondary"
						onClick={() => history.push('/topics')}
					>
						SEE ALL
					</Button>
				</center>

				<Grid container spacing={2}>
					{topics?.slice(0, 4)?.map((topic, i) => (
						<Grid
							item
							xs={6}
							sm={4}
							md={3}
							lg={3}
							key={i}
						>
							<TopicItem
								topic={topic}
								handleClick={handleTopicClick}
							/>
						</Grid>
					))}
				</Grid> */}

				<center className={classes.subheader}>
					<Typography variant="h3" color="primary">
						The Boot Room
					</Typography>
					<Typography
						variant="subtitle1"
						color="primary"
					>
						Expert guidance from the best, covering
						everything you'll need from nutrition to
						recovery and mental health.
					</Typography>
				</center>

				{!isMobile ? (
					<div>
						{articles?.length > 0 && (
							<div>
								<Box my={3}>
									<ArticleCover
										article={articles[0]}
										handleClick={handleArticleClick}
									/>
								</Box>
								<Grid container spacing={1}>
									{articles
										?.slice(1, 4)
										.map((article, i) => (
											<Grid
												item
												xs={12}
												sm={6}
												md={4}
												key={i}
											>
												<ArticleItem
													article={article}
													handleClick={
														handleArticleClick
													}
												/>
											</Grid>
										))}
								</Grid>
							</div>
						)}
					</div>
				) : (
					<MobileCarousel>
						{articles
							?.slice(0, 4)
							.map((article, i) => (
								<ArticleItem
									key={i}
									article={article}
									handleClick={handleArticleClick}
								/>
							))}
					</MobileCarousel>
				)}

				<center>
					<Button
						className={classes.seeAllButton}
						color="secondary"
						onClick={() => history.push('/articles')}
					>
						SEE ALL
					</Button>
				</center>

				{/* {events?.length > 0 && (
					<div>
						<center className={classes.subheader}>
							<Typography
								variant="h3"
								color="primary"
							>
								Upcoming Events
							</Typography>
							<Typography
								variant="subtitle1"
								color="primary"
							>
								Exclusive events for our Premium
								members to sign up to and attend.
							</Typography>
						</center>
						<PaywallOverlay
							paddingTop={320}
							open={!isPaid}
							handleClick={showPaywall}
						/>

						{isMobile ? (
							<MobileCarousel>
								{events
									?.slice(0, 3)
									?.map((event, i) => (
										<EventItem
											event={event}
											handleClick={handleEventClick}
										/>
									))}
							</MobileCarousel>
						) : (
							<div>
								<Grid container spacing={2}>
									{events
										?.slice(0, 3)
										?.map((event, i) => (
											<Grid
												item
												xs={12}
												sm={6}
												md={4}
												lg={4}
												key={i}
											>
												<EventItem
													event={event}
													handleClick={
														handleEventClick
													}
												/>
											</Grid>
										))}
								</Grid>
							</div>
						)}

						<center>
							<Button
								className={classes.seeAllEventsButton}
								color="secondary"
								onClick={() =>
									history.push('/events')
								}
							>
								SEE ALL
							</Button>
						</center>
					</div>
				)} */}

				{/* <center className={classes.subheader}>
					<Typography variant="h3" color="primary">
						Member Benefits
					</Typography>
					<Typography
						variant="subtitle1"
						color="primary"
					>
						Offers, discounts and rewards exclusive to
						our premium members.
					</Typography>
				</center>
				<PaywallOverlay
					paddingTop={240}
					open={!isPaid}
					handleClick={showPaywall}
				/>
				<Grid container spacing={2}>
					{benefits
						?.slice(0, 8)
						?.map((benefit, i) => (
							<Grid item xs={6} sm={6} md={3} key={i}>
								<BenefitItem
									benefit={benefit}
									handleClick={handleBenefitClick}
								/>
							</Grid>
						))}
				</Grid>
				<center>
					<Button
						className={classes.seeAllButton}
						color="secondary"
						onClick={() => history.push('/benefits')}
					>
						SEE ALL
					</Button>
				</center> */}

				<VideoModal
					open={videoOpen}
					handleClose={stopVideo}
					title={activeVideo?.title}
					captions={captions}
					src={src}
					videoId={videoId}
				/>
				<PremiumSignupModal
					open={openPaywall}
					handleClose={hidePaywall}
				/>
			</Container>
		</div>
	)
}

Home.propTypes = {
	className: PropTypes.string,
	item: PropTypes.object,
}

export default Home

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	subheader: {
		marginTop: 40,
		marginBottom: 20,
	},
	seeAllButton: {
		marginTop: 40,
		width: 100,
		marginBottom: 40,
	},
	seeAllEventsButton: {
		width: 100,
		marginBottom: 40,
		marginTop: 80,
	},
	heroContainer: {
		marginTop: "60px",
		padding: `0 130px 0 130px`,
		[theme.breakpoints.down('sm')]: {
			padding: `0 15px 0 15px`,
			marginTop: "15px",
		},
	},
}))
