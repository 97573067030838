import React, { useEffect } from 'react'
import {
	Autosuggest,
	DateInput,
	TextInput,
} from 'components'
import {
	makeStyles,
	Box,
	Button,
	Grid,
	Link,
	Typography,
} from '@material-ui/core'
// import { COUNTRIES } from 'lib/constants/countries'
import useCountries from 'hooks/useCountries'

const SignupForm = ({
	user,
	handleChange,
	handleSubmit,
}) => {
	const {
    countries,
    findCountries
  } = useCountries()

  useEffect(() => {
    findCountries()
  }, [])

	const classes = useStyles()
	return (
		<Grid container spacing={0}>
			<Grid item xs={12} sm={12}>
				<TextInput
					border
					name="email"
					value={user.email}
					placeholder="EMAIL"
					handleChange={handleChange}
					className={classes.autofillInput}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					name="first_name"
					value={user.first_name}
					placeholder="FIRST NAME"
					handleChange={handleChange}
					className={classes.autofillInput}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					name="last_name"
					value={user.last_name}
					placeholder="LAST NAME"
					handleChange={handleChange}
					className={classes.autofillInput}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<DateInput
					name="dob"
					value={user.dob}
					placeholder="DD/MM/YYYY"
					handleChange={handleChange}
					className={classes.autofillInput}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<Autosuggest
					options={countries}
					// options={COUNTRIES}
					value={user.country}
					name="country_id"
					placeholder="COUNTRY/REGION"
					handleChange={handleChange}
					className={classes.autofillInput}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					name="password"
					value={user.password}
					type="password"
					placeholder="PASSWORD"
					handleChange={handleChange}
					className={classes.autofillInput}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					name="password_confirmation"
					value={user.password_confirmation}
					type="password"
					placeholder="CONFIRM PASSWORD"
					handleChange={handleChange}
					className={classes.autofillInput}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					name='promo_code'
					value={user.promo_code}
					placeholder="PROMO CODE (OPTIONAL)"
					handleChange={handleChange}
					className={classes.autofillInput}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<center>
					<Box width={244} my={2}>
						<Button
							fullWidth
							size="large"
							color="primary"
							onClick={handleSubmit}
							variant="contained"
						>
							Next
						</Button>
					</Box>
				</center>
			</Grid>
			<Grid item xs={12} sm={12}>
				<Box my={4} />
				<center>
					<Typography variant="body1">
						Already have an account?
					</Typography>
					<Typography variant="body1">
						<Link color="secondary" href="/login">
							Sign In
						</Link>
					</Typography>
				</center>
			</Grid>
		</Grid>
	)
}

export default SignupForm

const useStyles = makeStyles((theme) => ({
	autofillInput: {
		'& input:-webkit-autofill': {
      transition: 'background-color 5000s ease-in-out 0s',
			'-webkit-text-fill-color': theme.palette.text.primary,
    },
	}
}))
