import api from "api";

const endpoints = {
  register: "api/auth/register",
  login: "api/auth/login",
  loginNicepay: "api/auth/nicepay",
  resetPassword: "api/auth/reset-password"
}

export default {
  register: async ({ email, first_name, last_name, dob, country_id, password,password_confirmation, role, promo_code }) => {
    return await api.post(endpoints.register, { email, first_name, last_name, dob, country_id, password, password_confirmation, role, promo_code })
  },
  login: async ({ email, password }) => {
    return await api.post(endpoints.login, { email, password });
  },
  loginNicepay: async ({ email, password }) => {
    return await api.post(endpoints.loginNicepay, { email, password });
  },
  resetPassword: async ({ email }) => {
    return await api.post(endpoints.resetPassword, { email });
  }
}