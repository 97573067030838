import React from 'react'
import api from 'api'
import {
	useAlerts,
	useLoaders,
	useResource,
} from 'hooks'

const useAttempts = ({ id, ...props }) => {
	const { showLoading, hideLoading } = useLoaders()
	const { showAlertError, showAlertSuccess } =
		useAlerts()

	const {
		resourceId: attemptId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: attempt,
		resources: attempts,
		findOne: findAttempt,
		findMany: findAttempts,
		update: updateAttempt,
		create: createAttempt,
		save: saveAttempt,
		destroy: deleteAttempt,
		handleChange,
		handleChangePage,
		reload: reloadAttempt,
		reloadMany: reloadAttempts,
		params,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		meta,
		setMeta,
		setResources: setCourses,
	} = useResource({
		id: id,
		url: '/api/video/feedback',
		urlMany: '/api/video/feedback',
		name: 'attempt',
	})

	// const browseCourses = async (params) => {
	// 	showLoading()
	// 	const resp = await api.get(
	// 		'/api/courses',
	// 		{
	// 			params: params,
	// 		}
	// 	)
	// 	// console.log(resp)
	// 	setCourses(resp?.data)
	// 	setMeta(resp?.meta)
	// 	hideLoading()
	// 	return resp
	// }

	return {
		attemptId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		attempt,
		attempts,
		findAttempt,
		findAttempts,
		saveAttempt,
		updateAttempt,
		createAttempt,
		deleteAttempt,
		handleChange,
		handleChangePage,
		meta,
		params,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadAttempt,
		reloadAttempts,
	}
}

export default useAttempts
