import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useResponsive } from 'hooks'
import FeaturedCourseItem from 'containers/courses/FeaturedCourseItem'
import Carousel from 'react-material-ui-carousel'
import {
	ChevronLeft,
	ChevronRight,
} from '@material-ui/icons'

const FeaturedCourses = ({
	courses,
	handleClick,
	...props
}) => {
	const classes = useStyles()
	const { isMobile } = useResponsive()

	return (
		<div className={classes.root}>
			<Carousel
				autoPlay={false}
				navButtonsAlwaysVisible
				NextIcon={
					<ChevronRight
						className={classes.leftIcon}
					/>
				}
				PrevIcon={
					<ChevronLeft
						className={classes.rightIcon}
					/>
				}
				navButtonsWrapperProps={
					styles.navButtonsWrapper
				}
				navButtonsProps={styles.navButtons}
				animation={isMobile ? 'slide' : 'fade'}
			>
				{courses?.control.map((course, i) => (
					<FeaturedCourseItem
						key={i}
						course={course}
						handleClick={handleClick}
					/>
				))}
			</Carousel>
		</div>
	)
}

export default FeaturedCourses

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: 400,
	},
	title: {},
	subtitle: {},
	button: {
		borderRadius: 0,
	},
	leftIcon: {
		height: 60,
		width: 60,
	},
	rightIcon: {
		height: 60,
		width: 60,
	},
}))

const styles = {
	navButtonsWrapper: {
		style: {
			bottom: '80px',
			top: 'unset',
		},
	},
	navButtons: {
		style: {
			backgroundColor: 'transparent',
		},
	},
}
