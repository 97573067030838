import React from 'react'
import api from 'api'
import {
	useAlerts,
	useLoaders,
	useResource,
} from 'hooks'
import { validateBenefit } from 'validations/benefits'

const useBenefits = ({ id, ...props }) => {
	const { showLoading, hideLoading } = useLoaders()
	const { showAlertError, showAlertSuccess } =
		useAlerts()

	const {
		resourceId: benefitId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: benefit,
		resources: benefits,
		findOne: findBenefit,
		findMany: findBenefits,
		update: updateBenefit,
		create: createBenefit,
		save: saveBenefit,
		destroy: deleteBenefit,
		handleChange,
		handleChangePage,
		reload: reloadBenefit,
		reloadMany: reloadBenefits,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
	} = useResource({
		id: id,
		url: '/api/v1/benefits',
		name: 'benefit',
	})

	const redeemCode = async (benefitId) => {
		try {
			const resp = await api.post(
				`/api/v1/benefits/${benefitId}/redeem_code`
			)
			return resp?.data
		} catch (e) {
			showAlertError(
				'There was an error redeeming the promotion code.'
			)
		}
	}

	return {
		benefitId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		benefit,
		benefits,
		findBenefit,
		findBenefits,
		saveBenefit,
		updateBenefit,
		createBenefit,
		deleteBenefit,
		redeemCode,
		handleChange,
		handleChangePage,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadBenefit,
		reloadBenefits,
	}
}

export default useBenefits
