import React from 'react'
import {
	Box,
	Grid,
	Typography,
	makeStyles,
	Icon
} from '@material-ui/core'
import CourseMeta from 'containers/courses/CourseMeta'
import WhatYouNeedIcons from 'containers/lessons/WhatYouNeedIcons'
import ProgressBars from 'containers/skills/ProgressBars'
import { useResponsive } from 'hooks'
import api from 'api'
import ChevronRight from 'assets/icons/chevron_left.svg'


// const imageSize = (theme.metrics.screenWidth / 5)
const imageSize = 100

const AttemptCard = ({ attempt }) => {
	const classes = useStyles()
	const { isMobile } = useResponsive()

	console.log(attempt)
	return (
		<div className={classes.item}>
			<img 
				src={attempt?.thumbnail_url} 
				className={classes.image}
			/>			

			<div className={classes.cardBody}>
				<div className={classes.cardHeader}>
					<Typography variant={'h5'} className={classes.cardTitle}>Attempt {attempt?.attempt}</Typography>
					<Typography mt={1} variant={'cardText'} className={classes.cardUploadedTime}>Uploaded on {attempt?.created_at}</Typography>
				</div>
				<Typography mt={2} variant={'cardText'} className={classes.status}>
					{attempt?.attempt_status}
					{attempt?.attempt_status == "New feedback available" && (
						<span className={classes.circle}></span>
					)}
				</Typography>
			</div>

			<img 
				src={ChevronRight}
				className={classes.chevron}
			/>	
		</div>
	)
}

export default AttemptCard

const useStyles = makeStyles((theme) => ({
	item: {
		display: "flex",
		flexDirection: 'row',
		backgroundColor: theme.palette.background.dark,
		// justifyContent: 'space-between',
		padding: 10,
		// height: theme.metrics.screenWidth / 4,
		// marginBottom: 10,
		borderRadius: "12px",
		gap: "1em"
	},

	image: {
		height: imageSize,
		width: imageSize,
		borderRadius: 10,
		objectFit: "cover"
	},

	icon: {
		alignSelf: 'center'
	},

	circle: {
		width: "12px",
		height: "12px",
		backgroundColor: "#6BE0A0",
		display: "inline-block",
		borderRadius: "10px",
		marginLeft: "5px"
	},

	cardBody: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		justifyContent: "space-between"
	},

	cardHeader: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start"
	},

	cardTitle: {
		fontWeight: 600,
		fontSize: "1em",
		fontFamily: "Proximanova Regular"
	},

	cardUploadedTime: {
		fontSize: "14px",
		fontWeight: 400,
		fontFamily: "Proximanova Regular"
	},

	status: {
		color: "#6BE0A0",
		display: "flex",
		alignItems: "center"
	},

	chevron: {
		alignSelf: "center",
		flex: "auto",
		height: "18px"
	}
}))
