import React, {useContext, useState} from 'react';
import {AppContext} from 'context';
import {isPaid} from 'lib/utils';
import {useAlerts, useLoaders, useResource} from 'hooks';
import api from 'api';

const usePaywall = () => {
  const {currentUser} = useContext (AppContext);
  const {showLoading, hideLoading,isLoading} = useLoaders ();

  const [openPaywall, setOpenPaywall] = useState (false);

  const {showAlertError, showAlertSuccess} = useAlerts ();

  const showPaywall = () => {
    setOpenPaywall (true);
  };


  // console.log(currentUser)

  const addPromoCode = async promoCode => {
    try {
      showLoading ();
      let resp = await api.post ('/api/promo-code/redeem', {
        promo_code: promoCode.value,
      }).then(x => {
        console.log(x)
      });
      console.log(resp)
      hideLoading ();
      // window.location.href = '/users/settings';
    } catch (e) {
      showAlertError (e.response.data.message);
      hideLoading();
    }
  };

  const hidePaywall = () => {
    setOpenPaywall (false);
  };

  const nicePaySubscribe = async user => {
    console.log (user);
    try {
      showLoading ();
      let resp = await api.post ('/api/nicepay/create-payment', {
        subscription_type: user.subscription_type,
        buyer_name: user.buyer_name,
        buyer_phone: user.buyer_phone,
        buyer_email: user.buyer_email,
        buyer_address: user.buyer_address,
        buyer_city: user.buyer_city,
        buyer_state: user.buyer_state,
        buyer_postal_code: user.buyer_postal_code,
        buyer_country: user.buyer_country,
      });
      console.log (resp);
      hideLoading ();
      window.location.href = resp.url

    } catch (e) {
      console.log (e);
      showAlertError (e.data.message);
      hideLoading ();
    }
  };

  const nicePayVerifySuccess = async refNo => {
    console.log (refNo);
    try {
      showLoading ();
      let resp = await api.post ('/api/nicepay/check-payment', {
        reference_number: refNo,
      });
      console.log (resp);
      hideLoading ();
      return resp.data
    } catch (e) {
      console.log (e);
      showAlertError (e.data.message);
      window.location.href = '/home'
      hideLoading ();
    }
  };
  return {
    nicePaySubscribe,
    nicePayVerifySuccess,
    isLoading,
    currentUser,
    addPromoCode,
    openPaywall,
    showPaywall,
    hidePaywall,
    isPaid: isPaid (currentUser),
  };
};

export default usePaywall;
