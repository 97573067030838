import React from 'react'
import { fade, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		alignItems: 'center',
		borderBottom: (props) =>
			props.borderBottom == true &&
			`1px solid ${theme.palette.primary.light}`,
		borderTop: (props) =>
			props.borderTop == true &&
			`1px solid ${theme.palette.primary.light}`,
	},
	label: {
		minWidth: 160,
		paddingLeft: theme.spacing(1.5),
	},
	inputBase: {
		height: 44,
		width: '100%',
		'& input, & textarea': {
			webkitAppearance: 'none',
			MozAppearance: 'none',
			appearance: 'none',
			padding: theme.spacing(1, 1.5),
			minHeight: 28,
			transition: theme.transitions.create([
				'border-color',
				'box-shadow',
			]),
			fontSize: 15,
			borderBottom: `1px solid ${theme.palette.primary.light}`,
			'&:focus': {
				borderColor: theme.palette.common.white,
			},
		},
	},
	uppercase: {
		'& input, & textarea': {
			textTransform: 'uppercase',
		},
	},
	IconButton: {
		padding: '5px',
	},
	icon: {
		fontSize: '20px',
		color: '#888',
	},
}))
