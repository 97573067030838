import React from 'react';
import {Route} from 'react-router';
import {Switch} from 'react-router-dom';
import { AuthRoute } from '../components'

//auth
import Login from 'containers/auth/Login';
import Signup from 'containers/auth/Signup';
import ForgotPassword from 'containers/auth/ForgotPassword';
import ResetPassword from 'containers/auth/ResetPassword';
import VerifyPin from 'containers/auth/VerifyPin';

import Verification from 'containers/auth/Verification';

//routes
import Home from 'containers/home/Home';

//articles
import ArticleShow from 'containers/articles/ArticleShow';
import ArticleList from 'containers/articles/ArticleList';

//users
import UserShow from 'containers/users/UserShow';
import UserEdit from 'containers/users/UserEdit';

//benefits
import BenefitShow from 'containers/benefits/BenefitShow';
import BenefitList from 'containers/benefits/BenefitList';

//Clubs
// import ClubContainer from 'containers/clubs/ClubContainer';
// import ClubShow from 'containers/clubs/ClubShow';

//courses
import CourseBrowse from 'containers/courses/CourseBrowse';
import CourseShow from 'containers/courses/CourseShow';
import CourseShowEnroll from 'containers/courses/CourseShowEnroll';
import CourseList from 'containers/courses/CourseList';

//Search
import SearchContainer from 'containers/search/SearchContainer';

//lessons
import LessonShow from 'containers/lessons/LessonShow';
import LessonComplete from 'containers/lessons/LessonComplete';
import LessonCourseComplete from 'containers/lessons/LessonCourseComplete';
import LessonProgress from 'containers/lessons/LessonProgress';

//topics
import TopicShow from 'containers/topics/TopicShow';
import TopicList from 'containers/topics/TopicList';

//payments
import Subscription from 'containers/payments/Subscription';
import PremiumSignup from 'containers/payments/PremiumSignup';
import NicePayForm from 'containers/payments/NicePayForm';
import NicePaySuccessScreen from 'containers/payments/NicePaySuccessScreen';

//Settings
import SettingsList from 'containers/settings/SettingsList';
import ParentalApprovalRequest from 'containers/auth/ParentalApprovalRequest';
import ParentalApproval from 'containers/auth/ParentalApproval';
import ParentalApprovalPending from 'containers/auth/ParentalApprovalPending';

//Events
import EventList from 'containers/events/EventList';
import EventShow from 'containers/events/EventShow';

//Video Uploader
import VideoUpload from 'containers/videos/VideoUpload';
import FeedbackShow from 'containers/feedback/FeedbackShow';
import FeedbackUpload from 'containers/feedback/FeedbackUpload';
import FeedbackList from 'containers/feedback/FeedbackList';

const Routes = () => (
  <Switch>
    <Route path="/" component={Login} exact />
    <Route path="/login/:token" component={Login} />
    <Route path="/login" component={Login} />
    <Route path="/signup" component={Signup} />
    <Route path="/forgot_password" component={ForgotPassword} exact />
    <Route path="/verify_pin" component={VerifyPin} exact />
    <Route path="/reset_password" component={ResetPassword} exact />
    <Route path="/users/passwords/edit" component={ResetPassword} exact />

    <Route
      path="/parental_approval"
      component={ParentalApprovalRequest}
      exact
    />
    <Route
      path="/parental_approval/pending"
      component={ParentalApprovalPending}
      exact
    />
    <Route path="/parental_approval/:id" component={ParentalApproval} exact />

    <AuthRoute path="/home" component={Home} exact />

    <AuthRoute path="/users/settings" component={SettingsList} exact />
    <AuthRoute
      path="/users/Stripesubscription"
      component={Subscription}
      exact
    />
    <AuthRoute path="/users/NicePaySubscription" component={NicePayForm} exact />
    <AuthRoute path="/nicepay" component={NicePaySuccessScreen} exact/>
    <AuthRoute path="/users/PremiumSignup" component={PremiumSignup} exact />
    <AuthRoute path="/users/:id/edit" component={UserEdit} exact />
    <AuthRoute path="/users/:id" component={UserShow} exact />

    <AuthRoute path="/articles" component={ArticleList} exact />
    <AuthRoute path="/articles/:id" component={ArticleShow} exact />

    <AuthRoute path="/benefits" component={BenefitList} exact />
    <AuthRoute path="/benefits/:id" component={BenefitShow} exact />

    {/* <AuthRoute path="/clubs" component={ClubContainer} exact />
    <AuthRoute path="/clubs/:id" component={ClubShow} exact /> */}

    <AuthRoute
      path="/courses/:id/videos/upload"
      component={VideoUpload}
      exact
    />
    <AuthRoute path="/videos/upload" component={VideoUpload} exact />

    {/* <AuthRoute path="/courses" component={CourseList} exact /> */}
    <AuthRoute path="/courses/browse" component={CourseBrowse} exact />
    <AuthRoute path="/courses/:id/enroll" component={CourseShowEnroll} exact />
    <AuthRoute path="/courses/:id" component={CourseShow} exact />

    <AuthRoute path="/search" component={SearchContainer} exact />

    <AuthRoute
      path="/courses/:course_id/lessons/:id"
      component={LessonShow}
      exact
    />
    <AuthRoute path="/lessons/:id" component={LessonShow} exact />

    <AuthRoute path="/lessons/:id/complete" component={LessonComplete} exact />
    <AuthRoute
      path="/courses/:course_id/lessons/:id/complete"
      component={LessonComplete}
      exact
    />

    <AuthRoute
      path="/lessons/:id/course_complete"
      component={LessonCourseComplete}
      exact
    />
    <AuthRoute
      path="/courses/:course_id/lessons/:id/course_complete"
      component={LessonCourseComplete}
      exact
    />

    <AuthRoute path="/lessons/:id/progress" component={LessonProgress} exact />
    <AuthRoute
      path="/courses/:course_id/lessons/:id/progress"
      component={LessonProgress}
      exact
    />

    <AuthRoute path="/topics" component={TopicList} exact />
    <AuthRoute path="/topics/:id" component={TopicShow} exact />

    <AuthRoute path="/events" component={EventList} exact />
    <AuthRoute path="/events/:id" component={EventShow} exact />

    <AuthRoute path="/verification" component={Verification} exact />

    <AuthRoute path="/feedback" component={FeedbackList} exact />
    <AuthRoute path="/feedback/result/:id" component={FeedbackShow} exact />
    <AuthRoute path="/feedback/upload" component={FeedbackUpload} exact />

  </Switch>
);

export default Routes;
