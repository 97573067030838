import api from "api";

const endpoints = {
  getBenefits: "api/benefits",
  getBenefit: (id) => `api/benefit/${id}`,
}

export default {
  getBenefits: async (per_page) => {
    return await api.get(endpoints.getBenefits, { params: { per_page } })
  },
  getBenefit: async (benefitId) => {
    return await api.get(endpoints.getBenefit(benefitId));
  },
}