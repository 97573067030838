import React from 'react'
import { makeStyles } from '@material-ui/core'

//Skill icons
import ControlIcon from 'assets/icons/control.svg'
import DribblingIcon from 'assets/icons/dribbling.svg'
import DefendingIcon from 'assets/icons/defending.svg'
import IntelligenceIcon from 'assets/icons/intelligence.svg'
import PassingIcon from 'assets/icons/passing.svg'
import ShootingIcon from 'assets/icons/shooting.svg'
import StarIcon from 'assets/icons/star.svg'

//What you need icons
import OnePlayerIcon from 'assets/icons/1_player.svg'
import TwoPlayersIcon from 'assets/icons/2_players.svg'
import ThreePlayersIcon from 'assets/icons/3_players.svg'
import FourPlayersIcon from 'assets/icons/4_players.svg'
import FivePlayersIcon from 'assets/icons/5_players.svg'
import BallIcon from 'assets/icons/ball.svg'
import ConeIcon from 'assets/icons/cone.svg'
import GoalIcon from 'assets/icons/goal.svg'
import MannequinIcon from 'assets/icons/mannequin.svg'
import PhoneIcon from 'assets/icons/phone.svg'
import PolesIcon from 'assets/icons/poles.svg'

const icons = {
	'1_player': OnePlayerIcon,
	'2_players': TwoPlayersIcon,
	'3_players': ThreePlayersIcon,
	'4_players': FourPlayersIcon,
	'5_players': FivePlayersIcon,
	'ball': BallIcon,
	'football': BallIcon,
	'phone': PhoneIcon,
	'cone': ConeIcon,
	'control': ControlIcon,
	'dribbling': DribblingIcon,
	'defending': DefendingIcon,
	'goal': GoalIcon,
	'mannequin': MannequinIcon,
	'passing': PassingIcon,
	'poles': PolesIcon,
	'smart': IntelligenceIcon,
	'shooting': ShootingIcon,
	'star': StarIcon,
};

const ReactIcon = ({ icon, size = 40, ...rest }) => {
	const classes = useStyles({ size });
	const Icon = icons[icon] || StarIcon;

	return <img src={Icon} className={classes.icon} />;
};

export default ReactIcon

const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.common.white,
		height: (props) => props.size,
		width: (props) => props.size,
	},
}))
