import palette from './palette'

export default {
	fontFamily: [
		'Tungsten Bold',
		'Proximanova Medium',
		'Proximanova Regular',
		'Proximanova Semibold',
		'Tungsten Bold',
		'Tungsten Semibold',
		'Roboto',
	].join(','),
	h1: {
		fontFamily: 'Tungsten Bold, Ariel',
		fontWeight: 600,
		fontSize: 120,
		lineHeight: '0.8em',
		textTransform: 'uppercase',
		'@media (max-width:600px)': {
			fontSize: 62,
		},
		color: palette.text.primary,
	},
	h2: {
		fontFamily: 'Tungsten Bold',
		fontWeight: 600,
		fontSize: 78,
		textTransform: 'uppercase',
		'@media (max-width:600px)': {
			fontSize: 44,
		},
		lineHeight: '0.8em',
		color: palette.text.primary,
	},
	h3: {
		fontFamily: 'Tungsten Bold',
		fontWeight: 600,
		fontSize: 40,
		textTransform: 'uppercase',
		'@media (max-width:600px)': {
			fontSize: 34,
		},
		lineHeight: '1.3em',
		color: palette.text.primary,
	},
	h4: {
		fontFamily: 'Tungsten Bold',
		fontWeight: 600,
		textIndent: 0,
		fontSize: 34,
		textTransform: 'uppercase',
		lineHeight: '1.3em',
		color: palette.text.primary,
	},
	h5: {
		fontFamily: 'Tungsten Bold',
		fontWeight: 600,
		fontSize: 30,
		textTransform: 'uppercase',
		lineHeight: '1.3em',
		color: palette.text.primary,
	},
	h6: {
		fontFamily: 'Tungsten Bold',
		fontWeight: 600,
		fontSize: 28,
		textTransform: 'uppercase',
		lineHeight: '2.0em',
		color: palette.text.primary,
	},
	subtitle1: {
		fontFamily: 'Proximanova Medium',
		fontSize: 19,
		fontWeight: 600,
		lineHeight: '1.5em',
		'@media (max-width:600px)': {
			fontSize: 16,
			fontWeight: 500,
		},
		color: palette.text.primary,
	},
	subtitle2: {
		fontFamily: 'Proximanova Medium',
		fontWeight: 500,
		fontSize: 15,
		lineHeight: '2.0em',
		color: palette.text.primary,
	},
	subtitle3: {
		fontFamily: 'Tungsten Bold',
		fontWeight: 600,
		textIndent: 0,
		fontSize: 20,
		textTransform: 'uppercase',
		lineHeight: '1.3em',
		color: palette.text.primary,
	},
	body1: {
		fontFamily: 'Proximanova Medium',
		fontWeight: 500,
		color: palette.text.primary,
		fontSize: 16,
		lineHeight: '1.5em',
		color: palette.text.primary,
	},
	body2: {
		fontFamily: 'Proximanova Medium',
		color: palette.text.secondary,
		fontSize: 15,
		fontWeight: 500,
		lineHeight: '1.75em',
		textDecoration: 'none',
	},
	button: {
		fontFamily: 'Proximanova Medium',
		color: palette.text.primary,
		fontSize: 17,
		fontWeight: 500,
	},
	caption: {
		fontFamily: 'Proximanova Medium',
		fontSize: 12,
		fontWeight: 500,
	},
	overline: {
		fontFamily: 'Proximanova Medium',
		color: palette.text.secondary,
		fontSize: 14,
		letterSpacing: 0.01071,
		fontWeight: 400,
		lineHeight: '13px',
		textTransform: 'none',
	},
	cardText: {
		fontSize: "14px",
		fontWeight: 400,
		fontFamily: "Proximanova Regular"
	}
}
