import React, { useState, useContext } from "react";
import { AppContext } from "context";
import { useAuth, useAlerts, useUsers } from "hooks";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { ButtonLoader } from "components";
import {
  INDONESIA_PRICE,
  MEMBERSHIP_PRICE,
  MEMBERSHIP_ANNUAL_PRICE,
  PREMIUM_BENEFITS,
} from "lib/constants";
import { isStripe, isPaid } from "lib/utils";
import { useHistory } from "react-router";
import CancelSubscriptionModal from "containers/payments/CancelSubscriptionModal";

const StripeManageCard = ({ brand, last4, ...props }) => {
  const [open, setOpen] = useState(false);

  const { fetchMe } = useAuth();
  const classes = useStyles();
  const history = useHistory();
  const { showAlertSuccess, showAlertError } = useAlerts();

  const { currentUser, selectedPackageName } = useContext(AppContext);

  const { isLoading, removePayment, subscribe, unsubscribe } = useUsers();

  const handleSubscribe = async () => {
    if (isStripe(currentUser)) {
      await subscribe();
      await fetchMe();
      showAlertSuccess("You have successfully subscribed");
    } else {
      history.push("/credit_card");
    }
  };

  const handleUnsubscribe = async () => {
    await unsubscribe();
    showAlertSuccess("You have canceled your subscription");

    fetchMe();
    setOpen(false);
  };

  const handleRemovePayment = async () => {
    const user = await removePayment();
    fetchMe();
  };

  const displayPrice =
    // currentUser?.country == 'ID'
    // 	? INDONESIA_PRICE
    // 	: MEMBERSHIP_PRICE
    selectedPackageName == "Monthly"
      ? MEMBERSHIP_PRICE
      : MEMBERSHIP_ANNUAL_PRICE;

  return (
    <div className={classes.root}>
      <div className={classes.subscription}>
        <div >
          <Typography variant="body2" color="primary">
            You already have an active subscription.
          </Typography>
        </div>


        {isPaid(currentUser) && isStripe(currentUser) && (
          <Typography variant="h4" color="primary">
            ELLEVATE {selectedPackageName == "Monthly" ? "MONTHLY " : "ANNUAL "}{" "}
            SUBSCRIPTION
          </Typography>
        )}
        {isPaid(currentUser) && isStripe(currentUser) && (
          <Typography variant="body2" color="secondary">
            {displayPrice}
          </Typography>
        )}

        {isPaid(currentUser) && isStripe(currentUser) && (
          <div>
            <Typography variant="body2" color="primary">
              {brand} credit card
            </Typography>
            <Typography variant="body2" color="primary">
              XXXX XXXX XXXX {last4}
            </Typography>
          </div>
        )}

        {isPaid(currentUser) && currentUser?.payment_platform == "ios" && (
          <div>
            <Typography variant="body2" color="primary">
              Paid using Apple iOS.
            </Typography>
          </div>
        )}
      </div>
      <div className={isPaid(currentUser) && isStripe(currentUser)?classes.creditCard:classes.nicePay}>
        {isStripe(currentUser) && (
          <Button
            fullWidth
            className={classes.button}
            onClick={handleRemovePayment}
            variant="contained"
            color="primary"
            type="submit"
            startIcon={isLoading && <ButtonLoader />}
          >
            REMOVE CARD
          </Button>
        )}

        {isPaid(currentUser) && isStripe(currentUser) && (
          <Button
            fullWidth
            className={classes.button}
            onClick={() => setOpen(true)}
            variant="contained"
            color="primary"
            type="submit"
            startIcon={isLoading && <ButtonLoader />}
          >
            CANCEL SUBSCRIPTION
          </Button>
        )}

        {/* {isPaid(currentUser) && !isStripe(currentUser) && (
            <div >
            <Typography variant="body2" color="primary">
              Paid using NicePay
            </Typography>
          </div>
         
        )} */}

        {!isPaid(currentUser) && isStripe(currentUser) && (
          <Button
            fullWidth
            className={classes.button}
            onClick={handleSubscribe}
            variant="contained"
            color="primary"
            type="submit"
            startIcon={isLoading && <ButtonLoader />}
          >
            SUBSCRIBE {displayPrice}
          </Button>
        )}
      </div>
      <CancelSubscriptionModal
        open={open}
        isLoading={isLoading}
        handleClose={() => setOpen(false)}
        handleSubmit={handleUnsubscribe}
      />
    </div>
  );
};

export default StripeManageCard;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(0.5),
    maxWidth: 590,
  },
  subscription: {},
  creditCard: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  nicePayButton:{
    alignItems:'center',
    justifyContent:'center'
  }
}));
