/* eslint-disable no-unused-vars */
import { useState } from 'react'
import { useAlerts, useLoaders } from 'hooks'
import articleApi from 'api/data/articleApi'
import benefit from 'api/data/benefit'
import course from 'api/data/course'
import challengeStatus from 'api/data/challengeStatus'

const useHome = (props) => {
	const { showLoading, hideLoading } = useLoaders()
	const { showAlertError } = useAlerts()

	const [data, setData] = useState()

	const loadHome = async () => {
		try {
			showLoading()

			let resp = await Promise.all([
				articleApi.getArticles(4),
				benefit.getBenefits(4),
				course.getActiveTraining(),
				challengeStatus.getChallengeStatus(1)
			])
			console.log(resp)
			if (resp) {
				setData({
					articles: resp[0].data.data, 
					benefits: resp[1].data.data, 
					activeTraining: resp[2].data,
					challengeStatus: resp[3].status
				})
			}
			hideLoading()
			return resp.data
		} catch (e) {
			hideLoading()
			showAlertError('There was an error', e)
		}
	}

	return {
		data,
		loadHome,
	}
}

export default useHome
