import api from "api";

const endpoints = {
  getCourses: "api/courses",
  getCourse: (id) => `api/course/:id`,
  getActiveTraining: "api/course/active-training",
  enrollInCourse: (id) => `api/course/enroll/${id}`,
  leaveCourse: (id) => `api/course/leave/${id}`,
  getEnrolledCourses: "api/course/enroll",
}

export default {
  getCourses: async() => {
    return await api.get(endpoints.getCourses)
  },
  getCourse: async(courseId) => {
    return await api.get(endpoints.getCourse(courseId))
  },
  getActiveTraining: async() => {
    return await api.get(endpoints.getActiveTraining)
  },
  enrollInCourse: async(courseId) => {
    return await api.post(endpoints.enrollInCourse(courseId))
  },
  leaveCourse: async(courseId) => {
    return await api.post(endpoints.leaveCourse(courseId))
  },
  getEnrolledCourses: async() => {
    return await api.get(endpoints.getEnrolledCourses)
  },
}