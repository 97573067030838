import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Box, Grid, Typography } from "@material-ui/core";
import {usePaywall } from "hooks";
import { useHistory } from "react-router";
import SubscriptionImage from "../../assets/images/subscription-background.png";

import mobile from "is-mobile";
// import { COUNTRIES } from "lib/constants/countries";
import { Autosuggest } from "components";
import useCountries from "hooks/useCountries";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
}
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    bgcolor: "background.paper",
    alignSelf: "center",
    outline: 0,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    alignItems: "center",
    justifyContent: "center",
    width: 550,
    border: `1px solid ${theme.palette.secondary.main}`,
    outline: 0,
  },
  circleView: {
    width: 100,
    height: 100,
    borderRadius: 100,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderWidth: 2,
    padding: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    height: 80,
    width: 80,
    alignSelf: "center",
  },
}));
export default function LocationMissingModal({
  open = false,
}) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const { addPromoCode } = usePaywall();
  const history = useHistory();

  const handleStripeClicked = async () => {
    history.push("/users/StripeSubscription");
  };

  
  const handleNicePayClicked = async () => {
    history.push("/users/NicePaySubscription")
  };

  const {
    countries,
    findCountries
  } = useCountries()

  useEffect(() => {
    findCountries()
  }, [])

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        BackdropComponent={Backdrop}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Box display="flex" justifyContent="center">
              <Typography marginBottom={10} variant={"h3"}>
                Please enter your location
              </Typography>
            </Box>

            <center>
              <Box my={6}>
                <Grid item xs={12} sm={12}>
                  <Autosuggest
                    options={countries}
                    // value={user.country}
                    name="country_id"
                    placeholder="COUNTRY/REGION"
                    // handleChange={handleChange}
                  />

                  <Button
                    fullWidth
                    // className={classes.button}
                    color="primary"
                    // onClick={displaySelectPaymentModal}
                    variant="contained"
                    style={{marginTop: "1em"}}
                  >
                    Update
                  </Button>
                </Grid>
              </Box>
            </center>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
