import React, { useContext, useState } from 'react'
import { useAlerts, useAuth } from 'hooks'
import { AppContext } from 'context'
import {
	Link as RouterLink,
	useHistory,
} from 'react-router-dom'
import {
	Box,
	Button,
	Grid,
	Link,
	Typography,
} from '@material-ui/core'
import { AuthScreen, TextInput } from 'components'

const ResetPassword = ({ ...props }) => {
	const [email, setEmail] = useState()

	const { showAlertError, showAlertSuccess } =
		useAlerts()

	const { authenticated, currentUser } =
		useContext(AppContext)

	const history = useHistory()

	const { forgotPassword } = useAuth()

	const handleSubmit = async () => {
		try {
			const data = await forgotPassword(email)

			if(data != undefined) {
				// history.push(`/verify_pin?email=${email}`)
				history.push(`/reset_password`)
				showAlertSuccess(
					'We have sent you password reset instructions.'
				)
			} else {
				showAlertError(
					"A user with that email doesn't exist"
				)
			}
		} catch (e) {
			showAlertError(
				"A user with that email doesn't exist"
			)
		}
	}

	const handleChange = ({ target }) =>
		setEmail(target.value)

	return (
		<div>
			<AuthScreen title="Forgot Password">
				<Grid container spacing={0}>
					<Grid item xs={12}>
						<TextInput
							border
							fullWidth
							required={true}
							name="email"
							placeholder="ENTER YOUR EMAIL"
							handleChange={handleChange}
						/>
					</Grid>
					<Grid item xs={12}>
						<center>
							<Box my={2} width={244}>
								<Button
									fullWidth
									variant="contained"
									color="primary"
									onClick={handleSubmit}
								>
									Next
								</Button>
							</Box>
						</center>
					</Grid>
				</Grid>
				<Box my={2} />
				<center>
					<Typography variant="body2">
						<Link
							color="secondary"
							component={RouterLink}
							to="/login"
						>
							Sign in
						</Link>
					</Typography>
				</center>
			</AuthScreen>
		</div>
	)
}

export default ResetPassword
