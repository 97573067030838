import React from 'react'
import { Chip, makeStyles } from '@material-ui/core'

const Label = ({ label, ...rest }) => {
	const classes = useStyles()
	return <div className={classes.chip}>{label}</div>
}

export default Label

const useStyles = makeStyles((theme) => ({
	chip: {
		display: 'flex',
		justifyContent: 'center',
		whiteSpace: 'nowrap',
		marginRight: theme.spacing(1),
		color: theme.palette.secondary.main,
		backgroundColor: 'transparent',
		borderRadius: 20,
		fontFamily: 'Proximanova Medium',
		fontSize: 14,
		minWidth: 120,
		padding: '2px 12px 2px 12px',
		border: `1px solid ${theme.palette.secondary.main}`,
	},
}))
