import { useResource } from 'hooks'

const useFilterTags = ({ id, ...props }) => {
	const {
		resourceId: filterTagId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: filterTag,
		resources: filterTags,
		findOne: findFilterTag,
		findMany: findFilterTags,
		update: updateFilterTag,
		create: createFilterTag,
		save: saveFilterTag,
		destroy: deleteFilterTag,
		handleChange,
		handleChangePage,
		refresh: reloadFilterTag,
		reloadMany: reloadFilterTags,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		paginate,
		loadMore,
		sortKey,
		sortDirection,
		handleSort,
		setResource: setFilterTag,
	} = useResource({
		id: id,
		url: '/api/course/filter/tags',
		name: 'filterTag',
	})

	return {
		filterTagId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		filterTag,
		filterTags,
		findFilterTag,
		findFilterTags,
		saveFilterTag,
		updateFilterTag,
		createFilterTag,
		deleteFilterTag,
		handleChange,
		handleChangePage,
		query,
		page,
		numPages,
		perPage,
		paginate,
		loadMore,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadFilterTag,
		reloadFilterTags,
		setFilterTag,
	}
}

export default useFilterTags
