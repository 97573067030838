import React from 'react'
import { Modal } from 'components'
import PremiumSignup from 'containers/payments/PremiumSignup'

const PremiumSignupModal = ({
	open,
	handleClose,
	...rest
}) => {
	return (
		<Modal
			open={open}
			fullScreen
			handleClose={handleClose}
		>
			<PremiumSignup onSubscribed={handleClose} />
		</Modal>
	)
}

export default PremiumSignupModal
