import React from 'react'
import {
	Box,
	Button,
	Grid,
	Typography,
	makeStyles,
} from '@material-ui/core'
import CourseMeta from 'containers/courses/CourseMeta'
import WhatYouNeedIcons from 'containers/lessons/WhatYouNeedIcons'
import ProgressBars from 'containers/skills/ProgressBars'
import { useResponsive } from 'hooks'
import AttemptCard from './AttemptCard'
import { useHistory } from 'react-router'
import api from 'api'

const FeedbackAttempts = ({ attempts }) => {
	const classes = useStyles()
	const { isMobile } = useResponsive()
	const history = useHistory()

	const handleNavigateFeedbackStatus = (id) => {
		// Call to set the feedback as viewed
		api.post(
			'/api/video/feedback/view',
			{
				captured_video_id: id
			}
		)
		history.push(`/feedback/result/${id}`)
	}

	return (
		<div className={classes.container}>
			<p className={classes.descriptionTitle}>YOUR ATTEMPTS</p>

			<Grid className={classes.body}>
				{attempts?.map((attempt, id) => (

						<button
							key={id}
							className={classes.noStyleBtn}
							onClick={() => handleNavigateFeedbackStatus(attempt.id)}
						>
							<AttemptCard
								key={id}
								attempt={attempt}
							/>
						</button>
	
				))}
			</Grid>
		</div>
	)
}

export default FeedbackAttempts

const useStyles = makeStyles((theme) => ({
	root: {},
	subtitle: {
		marginTop: 8,
		fontWeight: 600,
		fontSize: 20,
	},
	descriptionTitle: {
		marginTop: 60,
		marginBottom: 10,
		color: "#8DDDA5"
	},
	container: {
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		width: "100%"
	},
	description: {
		// fontSize:25
	},
	noStyleBtn: {
		background: "none",
		color: "inherit",
		border: "none",
		padding: "0",
		font: "inherit",
		cursor: "pointer",
		outline: "inherit",
		width: "47%",
		// width: "100%",
		[theme.breakpoints.down("xs")]: {
      width: "100%",
    },
	},
	body: {
		maxWidth: "750px",
		flexWrap: "wrap",
		gap: "1em",
		display: "flex",
		width: "100%"
	}

}))
