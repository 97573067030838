import React, { useState, useEffect } from 'react'
import { useAlerts, useVideo, useVideos } from 'hooks'
import {
	Button,
	IconButton,
	Grid,
	makeStyles,
} from '@material-ui/core'
import { VideoModal } from 'components'
import VideoItem from 'containers/videos/VideoItem'
import {
	AddAPhoto,
	ExpandMore,
} from '@material-ui/icons'
import { useHistory } from 'react-router'
import {
	VIDEO_GALLERY_WIDTH,
	VIDEO_GALLERY_HEIGHT,
} from 'lib/constants'

const VideoList = ({ ...props }) => {
	const classes = useStyles({
		height: VIDEO_GALLERY_HEIGHT,
		width: VIDEO_GALLERY_WIDTH,
	})
	const history = useHistory()

	const { showAlertSuccess, showAlertError } = useAlerts()

	const [activeVideo, setActiveVideo] = useState(null)
	const { src, videoOpen, playVideoUrl, stopVideo } =
		useVideo()

	const {
		isLoading,
		videos,
		findVideos,
		deleteVideo,
		page,
		numPages,
		loadMore,
	} = useVideos({})

	const handleDeleteVideo = async (video) => {
		try {
			const videoDelete = await deleteVideo(video, "/api/video/captured")
			console.log(videoDelete)
			showAlertSuccess('Video deleted successfully')
		} catch(e) {
			showAlertError(e.data.message)
		}
		stopVideo()
		findVideos([], 1, false, "/api/video/captured")
	}

	const handlePlayVideo = (video) => {
		playVideoUrl(video.video_url)
		setActiveVideo(video)
	}

	const handleStopVideo = () => {
		setActiveVideo(null)
		stopVideo()
	}

	const handleAddVideoClick = () => {
		history.push('/videos/upload')
	}

	useEffect(() => {
		findVideos([], 1, false, "/api/video/captured")
	}, [])

	console.log(videos)

	return (
		<div className={classes.root}>
			<Grid container spacing={2}>
				<Grid item xs={6} sm={6} md={4} lg={3}>
					<center>
						<Button
							onClick={handleAddVideoClick}
							className={classes.button}
						>
							<div className={classes.videoItem}>
								<AddAPhoto className={classes.icon} />
							</div>
						</Button>
					</center>
				</Grid>
				{videos &&
					videos.map((video, i) => (
						<Grid
							item
							xs={6}
							sm={6}
							md={4}
							lg={3}
							key={i}
						>
							<center>
								<VideoItem
									video={video}
									handleClick={handlePlayVideo}
								/>
							</center>
						</Grid>
					))}
			</Grid>
			{page < numPages && (
				<center>
					<IconButton onClick={loadMore}>
						<ExpandMore className={classes.icon} />
					</IconButton>
				</center>
			)}
			<VideoModal
				src={src}
				title={'Highlights'}
				open={videoOpen}
				handleClose={handleStopVideo}
				actions={
					<div>
						<Button
							size="small"
							onClick={() =>
								handleDeleteVideo(activeVideo)
							}
							className={classes.deleteButton}
						>
							Delete Video
						</Button>
					</div>
				}
			/>
		</div>
	)
}

export default VideoList

const useStyles = makeStyles((theme) => ({
	root: {},
	icon: {
		height: 32,
		width: 32,
	},
	button: {
		padding: 0,
	},
	videoItem: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 8,
		backgroundColor: theme.palette.common.white,
		height: (props) => props.height,
		width: (props) => props.width,
	},
	icon: {
		height: 32,
		width: 32,
		color: theme.palette.background.main,
	},
}))
