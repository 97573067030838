import React from 'react'
import {
	Typography,
	Box,
	Button,
	makeStyles,
} from '@material-ui/core'
import { CoverImage, Modal } from 'components'
import { useHistory } from 'react-router'
import CourseMeta from 'containers/courses/CourseMeta'

const NewCourseModal = ({
	open,
	course,
	handleClose,
}) => {
	const classes = useStyles()
	const history = useHistory()

	const handleClick = () => {
		handleClose()
		history.push(`/courses/${course?.id}`)
	}

	return (
		<Modal
			open={open}
			handleClose={handleClose}
			maxWidth="md"
			title="NEW TRAINING COURSE"
		>
			{course && (
				<div>
					<CoverImage
						mobileImageUrl={course?.image_url}
						desktopImageUrl={course?.web_image_url}
						onClick={handleClick}
						height={400}
					/>
					<Box px={2}>
						<Typography
							variant="h3"
							color="textPrimary"
							className={classes.title}
						>
							{course.title}
						</Typography>
						<Typography
							variant="subtitle1"
							color="secondary"
							className={classes.subtitle}
						>
							{course.subtitle}
						</Typography>

						<CourseMeta course={course} />
						<Box>
							<Button
								fullWidth
								className={classes.button}
								color="primary"
								variant="contained"
								onClick={handleClick}
							>
								SEE MORE DETAILS
							</Button>
						</Box>
					</Box>
				</div>
			)}
		</Modal>
	)
}

export default NewCourseModal

const useStyles = makeStyles((theme) => ({
	root: {},
	title: {
		lineHeight: '1em',
	},
	button: {
		margin: theme.spacing(1, 0, 1, 0),
	},
}))
