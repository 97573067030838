export const COUNTRIES = [
	{ "value": null, "label": "" },
	{ "value": 1, "label": "British" },
	{ "value": 2, "label": "Indonesian" },
	{ "value": 3, "label": "Afghan" },
	{ "value": 4, "label": "Albanian" },
	{ "value": 5, "label": "Algerian" },
	{ "value": 6, "label": "American" },
	{ "value": 7, "label": "Andorran" },
	{ "value": 8, "label": "Angolan" },
	{ "value": 9, "label": "Antiguans" },
	{ "value": 10, "label": "Argentinean" },
	{ "value": 11, "label": "Armenian" },
	{ "value": 12, "label": "Australian" },
	{ "value": 13, "label": "Austrian" },
	{ "value": 14, "label": "Azerbaijani" },
	{ "value": 15, "label": "Bahamian" },
	{ "value": 16, "label": "Bahraini" },
	{ "value": 17, "label": "Bangladeshi" },
	{ "value": 18, "label": "Barbadian" },
	{ "value": 19, "label": "Barbudans" },
	{ "value": 20, "label": "Batswana" },
	{ "value": 21, "label": "Belarusian" },
	{ "value": 22, "label": "Belgian" },
	{ "value": 23, "label": "Belizean" },
	{ "value": 24, "label": "Beninese" },
	{ "value": 25, "label": "Bhutanese" },
	{ "value": 26, "label": "Bolivian" },
	{ "value": 27, "label": "Bosnian" },
	{ "value": 28, "label": "Brazilian" },
	{ "value": 29, "label": "Bruneian" },
	{ "value": 30, "label": "Bulgarian" },
	{ "value": 31, "label": "Burkinabe" },
	{ "value": 32, "label": "Burmese" },
	{ "value": 33, "label": "Burundian" },
	{ "value": 34, "label": "Cambodian" },
	{ "value": 35, "label": "Cameroonian" },
	{ "value": 36, "label": "Canadian" },
	{ "value": 37, "label": "Cape verdean" },
	{ "value": 38, "label": "Central african" },
	{ "value": 39, "label": "Chadian" },
	{ "value": 40, "label": "Chilean" },
	{ "value": 41, "label": "Chinese" },
	{ "value": 42, "label": "Colombian" },
	{ "value": 43, "label": "Comoran" },
	{ "value": 44, "label": "Congolese" },
	{ "value": 45, "label": "Costa rican" },
	{ "value": 46, "label": "Croatian" },
	{ "value": 47, "label": "Cuban" },
	{ "value": 48, "label": "Cypriot" },
	{ "value": 49, "label": "Czech" },
	{ "value": 50, "label": "Danish" },
	{ "value": 51, "label": "Djibouti" },
	{ "value": 52, "label": "Dominican" },
	{ "value": 53, "label": "Dutch" },
	{ "value": 54, "label": "East timorese" },
	{ "value": 55, "label": "Ecuadorian" },
	{ "value": 56, "label": "Egyptian" },
	{ "value": 57, "label": "Emirian" },
	{ "value": 58, "label": "Equatorial guinean" },
	{ "value": 59, "label": "Eritrean" },
	{ "value": 60, "label": "Estonian" },
	{ "value": 61, "label": "Ethiopian" },
	{ "value": 62, "label": "Fijian" },
	{ "value": 63, "label": "Filipino" },
	{ "value": 64, "label": "Finnish" },
	{ "value": 65, "label": "French" },
	{ "value": 66, "label": "Gabonese" },
	{ "value": 67, "label": "Gambian" },
	{ "value": 68, "label": "Georgian" },
	{ "value": 69, "label": "German" },
	{ "value": 70, "label": "Ghanaian" },
	{ "value": 71, "label": "Greek" },
	{ "value": 72, "label": "Grenadian" },
	{ "value": 73, "label": "Guatemalan" },
	{ "value": 74, "label": "Guinea-bissauan" },
	{ "value": 75, "label": "Guinean" },
	{ "value": 76, "label": "Guyanese" },
	{ "value": 77, "label": "Haitian" },
	{ "value": 78, "label": "Herzegovinian" },
	{ "value": 79, "label": "Honduran" },
	{ "value": 80, "label": "Hungarian" },
	{ "value": 81, "label": "Icelander" },
	{ "value": 82, "label": "Indian" },
	{ "value": 83, "label": "Iranian" },
	{ "value": 84, "label": "Iraqi" },
	{ "value": 85, "label": "Irish" },
	{ "value": 86, "label": "Israeli" },
	{ "value": 87, "label": "Italian" },
	{ "value": 88, "label": "Ivorian" },
	{ "value": 89, "label": "Jamaican" },
	{ "value": 90, "label": "Japanese" },
	{ "value": 91, "label": "Jordanian" },
	{ "value": 92, "label": "Kazakhstani" },
	{ "value": 93, "label": "Kenyan" },
	{ "value": 94, "label": "Kittian and nevisian" },
	{ "value": 95, "label": "Kuwaiti" },
	{ "value": 96, "label": "Kyrgyz" },
	{ "value": 97, "label": "Laotian" },
	{ "value": 98, "label": "Latvian" },
	{ "value": 99, "label": "Lebanese" },
	{ "value": 100, "label": "Liberian" },
	{ "value": 101, "label": "Libyan" },
	{ "value": 102, "label": "Liechtensteiner" },
	{ "value": 103, "label": "Lithuanian" },
	{ "value": 104, "label": "Luxembourger" },
	{ "value": 105, "label": "Macedonian" },
	{ "value": 106, "label": "Malagasy" },
	{ "value": 107, "label": "Malawian" },
	{ "value": 108, "label": "Malaysian" },
	{ "value": 109, "label": "Maldivan" },
	{ "value": 110, "label": "Malian" },
	{ "value": 111, "label": "Maltese" },
	{ "value": 112, "label": "Marshallese" },
	{ "value": 113, "label": "Mauritanian" },
	{ "value": 114, "label": "Mauritian" },
	{ "value": 115, "label": "Mexican" },
	{ "value": 116, "label": "Micronesian" },
	{ "value": 117, "label": "Moldovan" },
	{ "value": 118, "label": "Monacan" },
	{ "value": 119, "label": "Mongolian" },
	{ "value": 120, "label": "Moroccan" },
	{ "value": 121, "label": "Mosotho" },
	{ "value": 122, "label": "Motswana" },
	{ "value": 123, "label": "Mozambican" },
	{ "value": 124, "label": "Namibian" },
	{ "value": 125, "label": "Nauruan" },
	{ "value": 126, "label": "Nepalese" },
	{ "value": 127, "label": "New zealander" },
	{ "value": 128, "label": "Ni-vanuatu" },
	{ "value": 129, "label": "Nicaraguan" },
	{ "value": 130, "label": "Nigerien" },
	{ "value": 131, "label": "North korean" },
	{ "value": 132, "label": "Northern irish" },
	{ "value": 133, "label": "Norwegian" },
	{ "value": 134, "label": "Omani" },
	{ "value": 135, "label": "Pakistani" },
	{ "value": 136, "label": "Palauan" },
	{ "value": 137, "label": "Panamanian" },
	{ "value": 138, "label": "Papua new guinean" },
	{ "value": 139, "label": "Paraguayan" },
	{ "value": 140, "label": "Peruvian" },
	{ "value": 141, "label": "Filipino" },
	{ "value": 142, "label": "Polish" },
	{ "value": 143, "label": "Portuguese" },
	{ "value": 144, "label": "Qatari" },
	{ "value": 145, "label": "Romanian" },
	{ "value": 146, "label": "Russian" },
	{ "value": 147, "label": "Rwandan" },
	{ "value": 148, "label": "Saint lucian" },
	{ "value": 149, "label": "Salvadoran" },
	{ "value": 150, "label": "Samoan" },
	{ "value": 151, "label": "San marinese" },
	{ "value": 152, "label": "Sao tomean" },
	{ "value": 153, "label": "Saudi" },
	{ "value": 154, "label": "Scottish" },
	{ "value": 155, "label": "Senegalese" },
	{ "value": 156, "label": "Serbian" },
	{ "value": 157, "label": "Seychellois" },
	{ "value": 158, "label": "Sierra leonean" },
	{ "value": 159, "label": "Singaporean" },
	{ "value": 160, "label": "Slovak" },
	{ "value": 161, "label": "Slovene" },
	{ "value": 162, "label": "Solomon islander" },
	{ "value": 163, "label": "Somali" },
	{ "value": 164, "label": "South african" },
	{ "value": 165, "label": "South korean" },
	{ "value": 166, "label": "South sudanese" },
	{ "value": 167, "label": "Spanish" },
	{ "value": 168, "label": "Sri lankan" },
	{ "value": 169, "label": "Sudanese" },
	{ "value": 170, "label": "Surinamer" },
	{ "value": 171, "label": "Swazi" },
	{ "value": 172, "label": "Swedish" },
	{ "value": 173, "label": "Swiss" },
	{ "value": 174, "label": "Syrian" },
	{ "value": 175, "label": "Taiwanese" },
	{ "value": 176, "label": "Tajik" },
	{ "value": 177, "label": "Tanzanian" },
	{ "value": 178, "label": "Thai" },
	{ "value": 179, "label": "Togolese" },
	{ "value": 180, "label": "Tongan" },
	{ "value": 181, "label": "Trinidadian or Tobagonian" },
	{ "value": 182, "label": "Tunisian" },
	{ "value": 183, "label": "Turkish" },
	{ "value": 184, "label": "Tuvaluan" },
	{ "value": 185, "label": "Ugandan" },
	{ "value": 186, "label": "Ukrainian" },
	{ "value": 187, "label": "Uruguayan" },
	{ "value": 188, "label": "Uzbekistani" },
	{ "value": 189, "label": "Venezuelan" },
	{ "value": 190, "label": "Vietnamese" },
	{ "value": 191, "label": "Welsh" },
	{ "value": 192, "label": "Yemenite" },
	{ "value": 193, "label": "Zambian" },
	{ "value": 194, "label": "Zimbabwean" }
]