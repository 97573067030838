import React, { useEffect } from 'react'
import { useCourses } from 'hooks'
import { useHistory } from 'react-router-dom'
import CourseCarousel from 'containers/courses/CourseCarousel'
import { CATEGORIES } from 'lib/constants'
import { Filter } from '@material-ui/icons'
import { Placeholder } from 'components'

const CourseBrowse = ({ filters = {}, ...props }) => {
	const history = useHistory()
	console.log(filters)
	const { tags, difficulty, trainer_ids } = filters

	const { isLoading, courses, meta, browseCourses } =
		useCourses({})

	const handleCourseClick = (course) => {
		history.push(`/courses/${course.id}/enroll`)
	}

	useEffect(() => {
		// TO DO Update the params here to integrate the filter functionality
		
		let trainerFilters = []
		trainer_ids.forEach((id, i) => {
			trainerFilters[`filters[$or][${i}][trainer_id][$eq]`] = id 
		})

		// Categories

		let tagsFilters = []
		tags.forEach((tag, i) => {
			console.log(tag)
			tagsFilters[`filters[$or][${i}][filter_tag_id][$eq]`] = tag 
		})
		

		let filterObj = {
			'filters[difficulty][$eq]': difficulty,
			...tagsFilters,
			...trainerFilters
		}

		console.log(filterObj)

		browseCourses(filterObj)
	}, [tags, difficulty, trainer_ids])
	// console.log(courses)
	return (
		<>
			{Object.keys(courses)?.length > 0 &&
				Object.keys(courses).map((courseCategory, i) => (
					<CourseCarousel
						key={i}
						label={courseCategory}
						courses={courses[courseCategory]}
						handleClick={handleCourseClick}
					/>
			))}

			{Object.keys(courses)?.length == 0 && (
				<Placeholder
					icon={<Filter />}
					title="No Matches Found"
					subtitle="Please try another filter"
				/>
			)}
		</>
	)

	// return (
	// 	<div>
	// 		{Object.keys(courses)?.length > 0 &&
	// 			Object.keys(courses).map((category, i) => {
	// 				const trainingCourses = courses[category]
	// 				let label = CATEGORIES.find(
	// 					(c) => c.value === category
	// 				)?.label
	// 				if (meta[category]) {
	// 					label += ` (${meta[category]})`
	// 				}
	// 				if (trainingCourses.length === 0)
	// 					return null
	// 				return (
	// 					<CourseCarousel
	// 						key={`${label}_${trainingCourses[0].id}`}
	// 						label={label}
	// 						courses={trainingCourses}
	// 						handleClick={handleCourseClick}
	// 					/>
	// 				)
	// 			})}
	// 	</div>
	// )
}

export default CourseBrowse
