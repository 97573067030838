import React from 'react'
import {
	Box,
	Container,
	Typography,
	makeStyles,
} from '@material-ui/core'
import Image from 'assets/images/login-background.png'
import CustomModal from './CustomModal'

const AuthScreen = ({ title, children , handleClosePopUp,open,alertMessage}) => {
	const classes = useStyles()
	return (
		<div className={classes.root}>
			<Container maxWidth="xs">
				<Box>
					<center>
						<Typography
							variant="h4"
							className={classes.title}
							color="primary"
						>
							{title}
						</Typography>
					</center>
					<CustomModal
					title={'Promotion code'}
					msg={alertMessage}
					buttonLabel={'Continue to the app'}
					handleClose={handleClosePopUp}
					open={open}
            		/>
					{children}
				</Box>
			</Container>
		</div>
	)
}

export default AuthScreen

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100vh',
		width: '100vw',
		background: `url(${Image}) center center no-repeat`,
		backgroundSize: 'cover',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
}))
