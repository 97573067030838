import React from 'react'
import { usePaywall } from 'hooks'
import {
	Typography,
	makeStyles,
} from '@material-ui/core'
import {
	PlayArrow,
	LockOutlined,
} from '@material-ui/icons'
import { CoverImage } from 'components'

const EditorialCover = ({
	editorial,
	handleClick,
	...props
}) => {
	const classes = useStyles()
	const { isPaid } = usePaywall()
	const isLocked = editorial?.premium && !isPaid
	return (
		<div>
			<CoverImage
				primaryLabel={editorial.video_length}
				mobileImageUrl={editorial.image_url}
				desktopImageUrl={editorial.web_image_url}
				maxHeight={500}
				icon={PlayArrow}
				borderRadius
				onClick={() => handleClick(editorial)}
			/>
			<Typography
				variant="body2"
				color="secondary"
				className={classes.caption}
			>
				{editorial?.topic?.title}
			</Typography>
			<Typography
				variant="subtitle1"
				color="textPrimary"
				className={classes.subtitle}
			>
				{editorial.title}
			</Typography>
		</div>
	)
}

export default EditorialCover

const useStyles = makeStyles((theme) => ({
	root: {},
	caption: {
		marginTop: theme.spacing(1),
		textTransform: 'uppercase',
	},
	subtitle: {
		fontSize: 17,
	},
}))
