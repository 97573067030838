import React,{useState,useContext,useEffect} from 'react'
import {
	Autosuggest,
	DateInput,
	TextInput,
} from 'components'
import {
	makeStyles,
	Box,
	Button,
	Grid,
	Container,
	Typography,
} from '@material-ui/core'
import { COUNTRIES } from 'lib/constants/countries'
import Image from 'assets/images/login-background.png'
import {validateNicePayUser} from "validations/users";
import {useAlerts,usePaywall} from "hooks";
import { AppContext } from "context";
import { isPaid } from "lib/utils";
import { useHistory} from "react-router-dom";


const NicePayForm = ({ ...props }) => {
    const classes = useStyles()
    const { showAlertError } = useAlerts();
    const { nicePaySubscribe } = usePaywall();
    const { selectedPackageName } = useContext(AppContext);
    const history = useHistory();
    const [user, setUser] = useState({})

    useEffect(() => {
        
        if (isPaid(user)) {
          history.push(`/home`);
        } else {
          
        }
      }, []);

	const handleChange = (ev) => {
		const { name } = ev.target
		let value = ev.target.value
		setUser({
			...user,
			[name]: value,
		})
	}
      const handleSubmit = async () => {
        const valid = validateNicePayUser(user);
        console.log(user)
    if (valid.isValid) {
      const resp = await nicePaySubscribe({
        ...user,
        "subscription_type": selectedPackageName.toLowerCase(),
      });
     }
     else{
        valid.messages.map((m) => showAlertError(m));
     }
    }
    
	return (
        <div className={classes.root}>
			<Container maxWidth="xs">
				<Box>
					<center>
						<Typography
							variant="h4"
							className={classes.title}
							color="primary"
						>
							Nice Pay Form
						</Typography>
					</center>
					<Grid container spacing={0}>
			<Grid item xs={12} sm={12}>
				<TextInput
					border
					name="buyer_email"
					value={user.buyer_email}
					placeholder="EMAIL"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					name="buyer_name"
					value={user.buyer_name}
					placeholder="NAME"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					name="buyer_phone"
					value={user.buyer_phone}
					placeholder="PHONE"
                    type={"phone"}
					handleChange={handleChange}
				/>
			</Grid>
		
			<Grid item xs={12} sm={12}>
            <Autosuggest
					options={COUNTRIES}
					value={user.buyer_country}
					name="buyer_country"
					placeholder="COUNTRY/REGION"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					name="buyer_city"
					value={user.buyer_city}
					placeholder="City"
					handleChange={handleChange}
				/>
			</Grid>
            <Grid item xs={12} sm={12}>
				<TextInput
					name="buyer_state"
					value={user.buyer_state}
					placeholder="State"
					handleChange={handleChange}
				/>
			</Grid>
            <Grid item xs={12} sm={12}>
				<TextInput
					name="buyer_address"
					value={user.buyer_address}
					placeholder="Address"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					name="buyer_postal_code"
                    type ="number"
                    pattern='[0-9]+([\,|\.][0-9]+)?'
					value={user.buyer_postal_code}
                    placeholder="Postal Code"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<center>
					<Box width={244} my={2}>
						<Button
							fullWidth
							size="large"
							color="primary"
							onClick={handleSubmit}
							variant="contained"
						>
							Continue
						</Button>
					</Box>
				</center>
			</Grid>
		</Grid>
				</Box>
			</Container>
		</div>
		
	)
}

export default NicePayForm
const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100vh',
		width: '100vw',
		background: `url(${Image}) center center no-repeat`,
		backgroundSize: 'cover',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
}))