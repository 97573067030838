import React from 'react'
import { AsyncAutosuggest } from 'components'

const ClubAutosuggest = ({
	value,
	label,
	handleChange,
	name
}) => {
	return (
		<AsyncAutosuggest
			name={name}
			label={label}
			value={value}
			displayField="name"
			srcGetAll="/api/clubs?per_page=1000"
			srcGetOne="/api/club"
			placeholder="Select"
			handleChange={handleChange}
		/>
	)
}



export default ClubAutosuggest
