import React, {
	useContext,
	useEffect,
	useState,
} from 'react'
import { useHistory } from 'react-router'
import {
	useAuth,
	useResponsive,
	useAlerts,
	useUsers,
} from 'hooks'
import {
	Button,
	Grid,
	IconButton,
	Container,
	Typography,
	Hidden,
	makeStyles,
} from '@material-ui/core'
import { SettingsOutlined } from '@material-ui/icons'
import UserDetails from 'containers/users/UserDetails'
import UserProgressBars from 'containers/users/UserProgressBars'
import { AppContext } from 'context'
// import { POSITIONS } from 'lib/constants'
import UserTabs from 'containers/users/UserTabs'
import VideoList from 'containers/videos/VideoList'
import usePositions from 'hooks/usePositions'

const UserShow = ({ ...props }) => {
	const history = useHistory()
	const { id } = props.match.params
	const classes = useStyles()

	const { isMobile } = useResponsive()
	const [activeTab, setActiveTab] = useState(0)
	const [openVeriff, setOpenVeriff] = useState(false)

	const { fetchMe } = useAuth()
	const { currentUser } = useContext(AppContext)
	const { createVerification } = useUsers()

	const handleEditClick = () =>
		history.push(`/users/me/edit`)

	const handleSettingsClick = () =>
		history.push(`/users/settings`)

	const handleVeriffClick = async () => {
		if (
			currentUser?.age <= 16 &&
			!currentUser?.parent_approved
		) {
			history.push('/parental_approval')
		} else {
			let resp = await createVerification(
				currentUser.id
			)
			if (resp.data?.session_url) {
				window.open(resp.data.session_url, '_blank')
			}
		}
	}

	const handleTabChange = (ev, tab) => {
		setActiveTab(tab)
	}

	const handleClubClick = () => {
		history.push(`/clubs/${currentUser?.club_id}`)
	}

	useEffect(() => {
		fetchMe()
	}, [])

	useEffect(() => {
		const { id, veriff_verified } = currentUser || {}
		if (id && !veriff_verified) {
			setOpenVeriff(true)
		}
	}, [currentUser])
	
	const {
		positionId,
		positions,
		findPositions,
	} = usePositions()

	useEffect(() => {
    findPositions()
  }, [])

	return (
		<div className={classes.root}>
			<Container>
				<div className={classes.panels}>
					<div className={classes.leftPanel}>
						<div className={classes.headerLeftPanel}>
							<Typography variant="h2">
								{currentUser?.first_name || currentUser?.last_name
									? currentUser.first_name + " " + currentUser.last_name
									: 'Loading...'}
							</Typography>
							<Typography
								variant="subtitle1"
								color="secondary"
								className={classes.position}
							>
								POSITION:{' '}
								{currentUser?.positions?.length > 0
									? positions?.find(
											(p) =>
												p.value ==
												currentUser?.positions[0]
									  )?.label
									: '-'}
							</Typography>
							{/* {currentUser?.club && (
								<Button
									className={classes.clubButton}
									variant="contained"
									onClick={handleClubClick}
									startIcon={
										currentUser?.club?.image_url ? (
											<img
												src={
													currentUser?.club?.image_url
												}
												alt={currentUser?.club?.name}
												width={28}
												height="auto"
											/>
										) : null
									}
								>
									{currentUser.club?.name}
								</Button>
							)} */}
						</div>
					</div>
					<div className={classes.rightPanel}>
							<div
								className={classes.headerRightPanel}
							>
								<div className={classes.settingsIcon}>
									<IconButton
										variant="contained"
										onClick={handleSettingsClick}
										color="primary"
									>
										<SettingsOutlined />
									</IconButton>
								</div>
                {/* {openVeriff && (
                  <>
                    <Typography variant="body1">
	                    All users under the age of 18 must have parental approval for videos to be seen by scouts
                    </Typography>
                    <Button
                      className={classes.veriffButton}
                      fullWidth
                      color="secondary"
                      variant="contained"
                      onClick={handleVeriffClick}
                    >
	                    ADD PARENT/GUARDIAN
                    </Button>
                  </>
                )} */}
							</div>
					</div>
				</div>
				<div className={classes.tabs}>
					<UserTabs
						activeTab={activeTab}
						handleChange={handleTabChange}
					/>
				</div>

				{currentUser && (
					<div className={classes.panels}>
						<div className={classes.leftPanel}>
							{(!isMobile ||
								(isMobile && activeTab === 1)) && (
								<div className={classes.section}>
									{/* <div className={classes.header}>
										<div>
											<Hidden smDown>
												<Typography variant="h3">
													TOP SKILLS
												</Typography>
											</Hidden>
										</div>
									</div>
									<UserProgressBars
										user={currentUser}
										showAll={true}
									/> */}
								</div>
							)}

							{(!isMobile ||
								(isMobile && activeTab === 2)) && (
								<div className={classes.section}>
									<div className={classes.header}>
										<div>
											<Hidden smDown>
												<Typography variant="h3">
													BIOGRAPHY
												</Typography>
											</Hidden>
										</div>
										<div>
											<Button
												onClick={handleEditClick}
												color="secondary"
												size="small"
											>
												EDIT PROFILE
											</Button>
										</div>
									</div>
									<UserDetails user={currentUser} />
								</div>
							)}
						</div>
						<div className={classes.rightPanel}>
							{(!isMobile ||
								(isMobile && activeTab === 0)) && (
								<div>
									<Hidden smDown>
										<Typography variant="h3">
											HIGHLIGHTS
										</Typography>
									</Hidden>
									<div className={classes.videoList}>
										<VideoList />
									</div>
								</div>
							)}
						</div>
					</div>
				)}
			</Container>
		</div>
	)
}

export default UserShow

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: theme.spacing(8),
	},
	panels: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		gap: 40,
		marginTop: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			gap: 0,
		},
	},
	leftPanel: {
		width: '42%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	rightPanel: {
		width: '58%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
	},
	headerLeftPanel: {
		marginTop: 80,
		[theme.breakpoints.down('sm')]: {
			marginTop: 20,
		},
	},
	headerRightPanel: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
	},
	settingsIcon: {
		position: 'absolute',
		top: 100,
		right: 20,
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		[theme.breakpoints.down('sm')]: {
			top: 60,
		},
	},
	clubButton: {
		marginTop: 20,
		marginBottom: 20,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	veriffButton: {
		marginTop: 20,
		marginBottom: 20,
	},
	section: {
		width: '100%',
	},
	position: {
		textTransform: 'uppercase',
	},
	container: {
		height: '100vh',
	},
	videoList: {
		marginTop: theme.spacing(2),
	},
}))
