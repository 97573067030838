import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router'
import {
	useAlerts,
	usePaywall,
	useCourses,
	useUsers,
	useVideo,
	useAuth,
} from 'hooks'
import {
	Box,
	Button,
	Container,
	Grid,
	Icon,
	Typography,
	makeStyles,
} from '@material-ui/core'
import {
	StickyButton,
	ButtonLoader,
	VideoCover,
	VideoModal,
	CoverImage,
} from 'components'
import CourseDetails from 'containers/courses/CourseDetails'
import { AppContext } from 'context'
import { Lock, PlayArrow } from '@material-ui/icons'
import PremiumSignupModal from 'containers/payments/PremiumSignupModal'
import courseApi from "api/data/course";
import FeedbackDetails from './FeedbackDetails'
import FeedbackAttempts from './FeedbackAttempts'
import useAttempts from 'hooks/useAttempts'
import FootballCard from '../../assets/images/Football_Card.png'
import PlayerCard from './PlayerCard'


const imageSize = 160

const FeedbackShow = ({ ...props }) => {
	const history = useHistory()
	const { id } = props.match.params
	const { fetchMe } = useAuth()

	const { currentUser } = useContext(AppContext)
	const {
		isPaid,
		showPaywall,
		hidePaywall,
		openPaywall,
	} = usePaywall()

	const {
		activeVideo,
		videoOpen,
		playVideo,
		stopVideo,
	} = useVideo()

	const { showAlertSuccess } = useAlerts()
	const { isLoading, enrollCourse } = useUsers()
	const classes = useStyles()

	const { attempts, attempt, findAttempts, findAttempt } = useAttempts({})

	const handleNavigateFeedbackUpload = async () => {
		history.push("/feedback/upload")
	}

	useEffect(() => {
		if (id) {
			fetchMe()
			findAttempt(id, "/api/video/feedback")
		}
	}, [id])

	// const getEnrolledCourses = async () => {
	// 	const resp = await courseApi.getEnrolledCourses();
	// 	if (resp.data.find(c => c.id === course?.id))
	// 		history.push(`/courses/${course.id}`)
	// }
	// useEffect(() => {
	// 	getEnrolledCourses()
	// }, [currentUser, course])
	console.log(attempt)
	return (
		<>
			<Container maxWidth="md">
				<Grid container spacing={0} style={{marginTop: "2em"}}>
					<Grid item md={6}>
						<div className={classes.item}>
							{/* Thumbnail play button */}
							<div 
								onClick={() => playVideo(null, attempt?.video_url)}
								className={classes.imageContainer}
							>
								<PlayArrow className={classes.playVideoIcon} />
								<img
									src={attempt?.thumbnail_url}
									className={classes.image}
								/>
							</div>

							<div className={classes.cardBody}>
								<span>
									<Typography variant={'h5'} className={classes.cardTitle}>Attempt {attempt?.attempt}</Typography>
									<Typography mt={1} variant={'cardText'}>Uploaded on {attempt?.created_at}</Typography>

								</span>

								<span>
									<div className={classes.overAll}>
										{
											attempt.feedback == null && (
												<Typography mt={1}
													variant={'subtitle2'}
													className={classes.ratingText}
												>
													Feedback in progress
												</Typography>
											)
										}

										{
											attempt.feedback != null && (
												<>
													<Typography mt={1}
														variant={'subtitle2'}
														className={classes.ratingText}
													>
														OVERALL
													</Typography>
													<Typography mt={1}
														variant={'subtitle2'}
														className={classes.ratingText}
													>
														{attempt?.feedback?.overall}
													</Typography>
												</>
											)
										}
									</div>
								</span>
								{/* <Typography mt={2} variant={'cardText'} className={classes.status}>Feedback in progress</Typography> */}
							</div>
						</div>
										
						<Typography mt={1}
								variant={'subtitle2'}
								className={classes.ratingDescription}
							>
								{ attempt.feedback != null && (attempt?.feedback?.description) }
								{ attempt.feedback == null && (
									<span className={classes.blur}>
										Coach Paul says that you did a great job in dribbling the ball as well as taking that shot. However, your defending skills need to be worked on.
										Run Up - Direct run up at full speed was great
										Movement - Your movement with the ball was perfect.
										Shooting - Your shot power was insane, however your accuracy and technique gets compromised due to the excessive power.
									</span>
								) }
							</Typography>
					</Grid>
					<Grid item md={6}>
						<PlayerCard
							attempt={attempt}
						/>

					</Grid>
				</Grid>

				<VideoModal
					autoPlay
					title={activeVideo?.title}
					open={videoOpen}
					src={activeVideo?.src}
					captions={activeVideo?.captions}
					videoId={activeVideo?.videoId}
					handleClose={stopVideo}
				/>
			</Container>

			{/* <Button
				fullWidth
				variant="contained"
				// onClick={handleNavigateFeedbackUpload}
				startIcon={isLoading && <ButtonLoader />}
				className={classes.buttonCta}
			>
				Share with friends
			</Button> */}

		</>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {},

	container: {
		display: "flex",
		alignItems: "center",
		flexDirection: "column"
	},
	button: {
		width: "40%",
		backgroundColor: "white"
	},

	imageContainer: {
		position: "relative"
	},

	playVideoIcon: {
		float: "left",
		position: "absolute",
		height: 50,
		width: 50,
		color: theme.palette.common.white,
		transform: "translate(-50%, -55%)",
		left: "50%",
		top: "50%"
	},

	image: {
		height: imageSize,
		width: imageSize,
		borderRadius: 10,
	},

	item: {
		display: "flex",
		flexDirection: 'row',
		// backgroundColor: theme.palette.background.dark,
		padding: 10,
		// height: theme.metrics.screenWidth / 4,
		marginBottom: 10,
		borderRadius: "12px",
		gap: "2em"
	},

	cardBody: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between"
	},

	cardTitle: {
		fontWeight: 600,
		fontSize: "1em",
		fontFamily: "Proximanova Regular"
	},

	overAll: {
		display: "flex",
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 8,
    marginTop:22,
		marginBottom: "10px"
    // padding: 5
  },

	ratingText: {
		color: "black"
	},

	buttonCta: {
		width: "40%",
		backgroundColor: "white"
	},

	blur: {
		filter: "blur(4px)"
	},
	
	ratingDescription: {
		marginTop: "2em"
	}
}))

export default FeedbackShow
