import React, { useEffect, useContext } from 'react'
import {
	Box,
	Divider,
	List,
	SwipeableDrawer,
	withWidth,
} from '@material-ui/core'
import {
	Close,
	ExpandMore,
	SettingsOutlined,
} from '@material-ui/icons'
import { AppContext } from 'context'
import { makeStyles } from '@material-ui/styles'
import {
	useMenu,
	useResponsive,
	useAuth,
} from 'hooks'
import {
	MenuItem,
} from 'components'
import { MENU_ITEMS } from 'lib/navigation'
import { animateScroll } from 'react-scroll'
import {
	useHistory,
	useLocation,
} from 'react-router-dom'
import ActiveTrainingMenu from 'containers/courses/ActiveTrainingMenu'

const drawerWidth = 240

const Drawer = ({
	open,
	width,
	toggleMenu,
	...props
}) => {
	const classes = useStyles()
	const history = useHistory()

	const { pathname } = useLocation()
	const { authenticated, currentUser } =
		useContext(AppContext)
	const { fetchMe } = useAuth()

	const handleClick = (url) => {
		toggleMenu()
		animateScroll.scrollToTop()
		history.push(url)
	}

	useEffect(() => {
		if (authenticated && !currentUser?.id) {
			fetchMe()
		}
	}, [currentUser?.id])

	return (
		<SwipeableDrawer
			open={open}
			variant={'temporary'}
			onOpen={toggleMenu}
			onClose={toggleMenu}
			anchor={'right'}
			className={classes.root}
		>
			<Box
				display="flex"
				justifyContent="space-between"
				flexDirection="column"
				height="100%"
			>
				<List
					component="nav"
					className={classes.list}
				>
					{MENU_ITEMS[
						currentUser ? 'loggedIn' : 'loggedOut'
					].map((menu, idx) => (
						<MenuItem
							key={idx}
							active={pathname === menu.value}
							value={menu.value}
							text={menu.text}
							icon={menu.icon}
							handleClick={handleClick}
						/>
					))}
					{currentUser?.course?.id && (
						<>
							<Divider />
							<ActiveTrainingMenu
								toggleMenu={toggleMenu}
							/>
							<Divider />
						</>
					)}
				</List>
			</Box>
		</SwipeableDrawer>
	)
}

export default withWidth()(Drawer)

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: 'transparent',
	},
	logo: {
		width: 200,
	},
	list: {
		backgroundColor: theme.palette.background.paper,
		width: '300px',
	},
	icon: {
		color: 'white',
	},
	item: {
		display: 'block',
		paddingTop: 0,
		paddingBottom: 0,
		fontWeight: theme.typography.fontWeightMedium,
	},
	button: {
		padding: '10px 8px',
		justifyContent: 'flex-start',
		textTransform: 'none',
		fontSize: '14px',
		letterSpacing: 0,
		width: '100%',
		fontWeight: theme.typography.fontWeightMedium,
	},
	text: {
		color: theme.palette.common.white,
	},
	secondaryIcon: {
		marginLeft: 'auto',
		height: 20,
		width: 20,
	},
	onlineStatusList: {
		color: theme.palette.secondary.contrastText,
	},
}))
