import api from "api";
const endpoints = {
  getChallengeStatus: (id) => `/api/video/feedback/status/${id}`,
}
export default {
  getChallengeStatus: async(statusId) => {
    return await api.get(endpoints.getChallengeStatus(statusId))
  }
}

