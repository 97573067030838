import React from 'react'
import {
	Button,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { StickyButton } from 'components'
import moment from 'moment'

const BenefitDetails = ({ benefit, ...props }) => {
	const classes = useStyles()
	return (
		<div className={classes.root}>
			<center>
				<Typography
					variant="caption"
					color="secondary"
					className={classes.caption}
				>
					T&CS APPLY
				</Typography>
				<Typography variant="h2" color="primary">
					{benefit.title}
				</Typography>
				<Typography
					variant="caption"
					color="secondary"
				>
					EXPIRES{' '}
					{moment(benefit.expires_at).format(
						'DD/MM/YY'
					)}
				</Typography>
			</center>
			<div className={classes.text}>
				<center>
					<Typography
						variant="body1"
						color="primary"
						className={classes.preLine}
					>
						{benefit.description}
					</Typography>
				</center>
			</div>
		</div>
	)
}

export default BenefitDetails

const useStyles = makeStyles((theme) => ({
	root: {},
	text: {
		margin: theme.spacing(4, 0),
	},
	caption: {
		fontSize: 14,
	},
	preLine: {
		whiteSpace: 'pre-line',
	},
}))
