import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const FlexColumn = ({
	className,
	children,
	...rest
}) => {
	const classes = useStyles()

	return (
		<Box
			className={clsx(className, classes.root)}
			display="flex"
			flexDirection="column"
			height="100%"
			justifyContent="center"
			{...rest}
		>
			{children}
		</Box>
	)
}

FlexColumn.propTypes = {
	className: PropTypes.string,
	children: PropTypes.object.isRequired,
}

export default FlexColumn

const useStyles = makeStyles((theme) => ({
	root: {},
}))
