import React, { useEffect } from 'react'
import {
	useBenefits,
	useAlerts,
	usePaywall,
	useAuth,
} from 'hooks'
import { useHistory } from 'react-router-dom'
import {
	StickyButton,
	PaywallOverlay,
	PageHeader,
	Placeholder,
} from 'components'
import Pagination from '@material-ui/lab/Pagination'
import {
	Button,
	Container,
	Box,
	Grid,
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import BenefitItem from 'containers/benefits/BenefitItem'
import PremiumSignupModal from 'containers/payments/PremiumSignupModal'
import { Lock } from '@material-ui/icons'

const BenefitList = ({ ...props }) => {
	const history = useHistory()

	const { currentUser } = useAuth()
	const {
		isPaid,
		openPaywall,
		showPaywall,
		hidePaywall,
	} = usePaywall()

	const {
		isLoading,
		isLoaded,
		isEmpty,
		benefits,
		findBenefits,
		query,
	} = useBenefits({})

	const handleClick = (benefit) => {
		if (!isPaid) {
			showPaywall()
		} else {
			history.push(`/benefits/${benefit.id}`)
		}
	}

	useEffect(() => {
		findBenefits(query)
	}, [query])

	const isLocked = currentUser?.id && !isPaid

	return (
		<>
			<PageHeader
				title="Member Benefits"
				subtitle="Offers, discounts and rewards exclusive to our premium members."
			/>
			<Container maxWidth="lg">
				{isLoaded && (
					<>
						<div>
							{!isEmpty ? (
								<Grid container spacing={2}>
									{benefits.map((benefit, idx) => (
										<Grid
											item
											xs={12}
											sm={6}
											md={4}
											key={idx}
										>
											<BenefitItem
												key={idx}
												benefit={benefit}
												handleClick={handleClick}
												isLocked={isLocked}
											/>
										</Grid>
									))}
								</Grid>
							) : (
								<Placeholder
									icon={<Search />}
									title="No benefits"
									subtitle="Please try another search"
								/>
							)}
						</div>
					</>
				)}
				<PremiumSignupModal
					open={openPaywall}
					handleClose={hidePaywall}
				/>
			</Container>
			{isLocked && (
				<StickyButton>
					<Button
						fullWidth
						color="primary"
						variant="contained"
						onClick={showPaywall}
						startIcon={<Lock />}
					>
						Upgrade Now For Access
					</Button>
				</StickyButton>
			)}
		</>
	)
}

export default BenefitList
