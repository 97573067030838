import React from 'react'
import {
	Box,
	Grid,
	Typography,
	makeStyles,
} from '@material-ui/core'
import CourseMeta from 'containers/courses/CourseMeta'
import WhatYouNeedIcons from 'containers/lessons/WhatYouNeedIcons'
import ProgressBars from 'containers/skills/ProgressBars'
import { useResponsive } from 'hooks'

const FeedbackDetails = ({ course }) => {
	const classes = useStyles()
	const { isMobile } = useResponsive()
	return (
		<div className={classes.container}>
			<Typography variant="h2" color="textPrimary">Feedback Drill</Typography>
			<p className={classes.descriptionTitle}>WHAT TO KNOW</p>
			<p className={classes.description}>Check out the drill above and upload your attempt to get detailed feedback from UEFA licensed coaches. Get access to your player card in your profile to track your progress and share it with your friends. In this drill, we test you on your basics and help you take your game to the next level.</p>
		</div>
	)
}

export default FeedbackDetails

const useStyles = makeStyles((theme) => ({
	root: {},
	subtitle: {
		marginTop: 8,
		fontWeight: 600,
		fontSize: 20,
	},
	descriptionTitle: {
		marginTop: 60,
		marginBottom: 10,
		color: "#8DDDA5",
	},
	container: {
		display: "flex",
		alignItems: "center",
		flexDirection: "column"
	},
	description: {
		// fontSize:25,
		maxWidth: "600px",
		textAlign: "center"
	}
}))
