import React, { useState, useEffect } from 'react'
import {
	useCourses,
	usePaywall,
	useAlerts,
} from 'hooks'
import {
	useHistory,
	useLocation,
} from 'react-router-dom'
import { Placeholder } from 'components'
import Pagination from '@material-ui/lab/Pagination'
import {
	Container,
	Box,
	Paper,
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import CourseSearchResult from 'containers/search/SearchItem'
import SearchForm from 'containers/search/SearchForm'
import queryString from 'query-string'
import PremiumSignupModal from 'containers/payments/PremiumSignupModal'

const SearchContainer = (props) => {
	const history = useHistory()
	const location = useLocation()
	const searchParams = queryString.parse(
		location.search
	)

	const { keywords, tags, difficulty } = searchParams

	const [query, setQuery] = useState('')

	const [searchedCourses, setSearchedCourses] = useState([])

	const {
		isPaid,
		openPaywall,
		showPaywall,
		hidePaywall,
	} = usePaywall()

	const {
		isLoading,
		isLoaded,
		isEmpty,
		courses,
		findCourses,
		paginate,
		page,
		numPages,
		sortKey,
		sortDirection,
	} = useCourses({})
console.log(isEmpty)
	const { showAlertSuccess, showAlertError } =
		useAlerts()

	const handleChange = (ev) => {
		setQuery(ev?.target?.value)
		if (ev?.target?.value === '') {
			handleSearch(null)
		}
	}

	const handleKeyPress = (ev) => {
		if (ev.key == 'Enter') {
			handleSearch(query)
		}
	}

	const handleCourseClick = (course) => {
		if (course.premium && !isPaid) {
			showPaywall()
		} else {
			history.push(`/courses/${course.id}/enroll`)
		}
	}

	const handleSearch = (keywords) => {
		setQuery(keywords)
		if (keywords) {
			history.push(`/search?keywords=${keywords}`)
		} else {
			history.push('/search')
		}
	}

	const handlePaginate = (ev, page) => {
		paginate(page)
	}

	const handleSearchCourses = () => {
		console.log("abc")
		findCourses(
			{
				keywords: keywords,
				'courses.categories': tags,
				'courses.difficulty': difficulty,
				sort_key: sortKey,
				sort_direction: sortDirection,
				per_page: 10,
			},
			page,
			false,
			"/api/courses/browse"
		)
	}

	useEffect(() => {
		handleSearchCourses()
	}, [keywords, tags, difficulty])

	useEffect(() => {
		// Remove duplicate courses if they appear multiple times from different categories
		// This will probably not be needed in the future

		let uniqueCourses = new Set();
		if(typeof courses == "object"){
			// for(let i = 0; i < Object.keys(courses).length; i++) {
			Object.keys(courses).forEach((courseCategory) => {
				for(let j = 0; j < courses[courseCategory].length; j++) {
					uniqueCourses.add(JSON.stringify(courses[courseCategory][j]))
				} 
			})
		}

		let searchedCourseArr = []
		uniqueCourses.forEach((c, i) => {
			searchedCourseArr.push(JSON.parse(c))
		})

		setSearchedCourses(searchedCourseArr)

	}, [courses])

	console.log(courses)

	return (
		<div>
			<Container maxWidth="md">
				{isLoaded && (
					<>
						<div>
							<Box mt={6}>
								<SearchForm
									isLoading={isLoading}
									value={query}
									handleChange={handleChange}
									handleSearch={handleSearch}
									onKeyPress={handleKeyPress}
								/>
							</Box>
							{!isEmpty ? (
								<div>
									{/* {Object.keys(courses)?.length > 0 && Object.keys(courses).map((courseCategory, i) => (
											courses[courseCategory].map((singleCourse, j) => (
												<CourseSearchResult
													// label={courseCategory}
													course={singleCourse}
													handleClick={handleCourseClick}
												/>
											))
										)
									)} */}

									{Array.isArray(courses) && courses?.map((course, idx) => (
										<CourseSearchResult
											key={idx}
											course={course}
											handleClick={handleCourseClick}
										/>
									))}
								</div>
							) : (
								<Placeholder
									icon={<Search />}
									title="No Matches Found"
									subtitle="Please try another search"
								/>
							)}
						</div>

						{numPages > 1 && (
							<Box my={4}>
								<Pagination
									onChange={(ev, page) => {
										paginate(page, "/api/courses/browse")
									}}
									color="primary"
									size="small"
									shape="rounded"
									page={page}
									count={numPages}
								/>
							</Box>
						)}
					</>
				)}
			</Container>
			<PremiumSignupModal
				open={openPaywall}
				handleClose={hidePaywall}
			/>
		</div>
	)
}

export default SearchContainer
