import api from "api";

const endpoints = {
  getArticles: "api/articles",
  getArticle: (id) => `api/article/${id}`,
}

export default {
  getArticles: async (per_page) => {
    return await api.get(endpoints.getArticles, { params: { per_page } })
  },
  getArticle: async (articleId) => {
    return await api.get(endpoints.getArticle(articleId));
  },
}