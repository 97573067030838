import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useUsers, useAlerts } from 'hooks'
import {
	Box,
	Button,
	Typography,
	Container,
	makeStyles,
} from '@material-ui/core'
import UserForm from 'containers/users/UserForm'
import { StickyButton } from 'components'
import moment from 'moment'
import profile from 'api/data/profile'

const UserEdit = ({ ...props }) => {
	const history = useHistory()
	const { id } = props.match.params

	const classes = useStyles()

	const {
		user,
		handleChange,
		updateUser,
		findUser,
		uploadFile,
		deleteFile,
		setCurrentUser
	} = useUsers({ id: id })

	const { showAlertSuccess, showAlertError } =
		useAlerts()

	const handleSaveClick = async () => {
		console.log(user)
		await profile.updateProfile(user)
		await getUser();
		showAlertSuccess('Your profile has been updated')
		// console.log("REMOVE THIS COMMENT")
		history.push(`/users/me`)
	}

	const getUser = async () => {
		let resp = await profile.getProfile();
			console.log(resp);
			if (resp && resp.data) {
				resp.data.dob = resp.data.dob.split("-").reverse().join("/")
				setCurrentUser(resp.data)
			}
	}
	useEffect(() => {
		getUser()
	}, [])

	const handleGoBack = () => {
		history.push(`/users/me`)

	}

	return (
		<>
			<Container>
				<Button
					onClick={handleGoBack}
					color="secondary"
					size="small"
					className={classes.button}
				>
					GO BACK
				</Button>
	
				<Box p={1}>
					<Typography
						color="textPrimary"
						variant="h4"
						className={classes.title}
					>
						EDIT PROFILE
					</Typography>

					<UserForm
						user={user}
						handleChange={handleChange}
					/>
				</Box>
			</Container>
			<StickyButton>
				<Button
					fullWidth
					color="primary"
					variant="contained"
					onClick={handleSaveClick}
				>
					Save
				</Button>
			</StickyButton>
		</>
	)
}

export default UserEdit

const useStyles = makeStyles((theme) => ({
	title: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
	button: {
		marginTop: theme.spacing(3),
	}
}))
