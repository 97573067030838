import React from 'react'
import { Box, makeStyles } from '@material-ui/core'

const drawerWidth = 0

const Layout = ({ children, ...props }) => {
	const classes = useStyles()

	return (
		<Box className={classes.content}>{children}</Box>
	)
}

export default Layout

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	content: {
		overflowX: 'hidden',
		paddingTop: 48,
		transition: theme.transitions.create('padding', {
			easing: theme.transitions.easing.sharp,
			duration:
				theme.transitions.duration.leavingScreen,
		}),
		marginLeft: drawerWidth,
	},
}))
