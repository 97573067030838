import React, { useContext } from 'react'
import {
	Badge,
	Box,
	Divider,
	Menu,
	MenuItem,
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import { AppContext } from 'context'

const useStyles = makeStyles((theme) => ({
	badgeOnline: {
		height: 10,
		width: 10,
		backgroundColor: green[500],
	},
}))

const UserMenu = ({
	open,
	anchorEl,
	handleToggleMenu,
	handleLogoutClick,
	handleClick,
	...props
}) => {
	const { currentUser, authenticated } =
		useContext(AppContext)

	const classes = useStyles()
	return (
		<Menu
			open={open}
			onClose={handleToggleMenu}
			anchorEl={anchorEl}
		>
			<MenuItem
				onClick={(e) =>
					handleClick(`/users/${currentUser.id}/edit`)
				}
			>
				My Account
			</MenuItem>
			<MenuItem onClick={handleLogoutClick}>
				<Badge
					variant="dot"
					classes={{ dot: classes.badgeOnline }}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
				>
					<Box mx={1} />
				</Badge>
				Sign Out
			</MenuItem>
		</Menu>
	)
}

export default UserMenu
