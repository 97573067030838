import React from 'react'
import { colors, makeStyles } from '@material-ui/core'

export const SkillBar = ({ color, max = 5 }) => {
	const classes = useStyles({ max, color })

	return <div className={classes.root} />
}

export default SkillBar

const useStyles = makeStyles((theme) => ({
	root: {
		height: 4,
		borderRadius: 20,
		marginRight: 4,
		width: '100%',
		backgroundColor: (props) => {
			switch (props.color) {
				case 'primary':
					return theme.palette.primary.main
					break
				case 'secondary':
					return theme.palette.secondary.main
					break
				default:
					return '#9E9E9E'
					break
			}
		},
	},
}))
