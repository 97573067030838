import api from "api";

const endpoints = {
  getProfile: "api/user/profile",
  updateProfile: "api/user/update",
  deleteProfile: "api/user",
}

export default {
  getProfile: async() => {
    return await api.get(endpoints.getProfile)
  },
  updateProfile: async ({trial, ...user}) => {
    return await api.post(`${endpoints.updateProfile}`, user);
  },
  deleteProfile: async () => {
    return await api.delete(endpoints.deleteProfile);
  }
}