import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { PlayArrow } from '@material-ui/icons'
import { ResponsiveImage } from 'components'
import { resize } from 'lib/cloudinary'
import {
	VIDEO_GALLERY_WIDTH,
	VIDEO_GALLERY_HEIGHT,
} from 'lib/constants'

const VideoItem = ({
	video,
	handleClick,
	width = VIDEO_GALLERY_WIDTH,
	height = VIDEO_GALLERY_HEIGHT,
	...props
}) => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<Button
				className={classes.button}
				onClick={() => handleClick(video)}
			>
				<div className={classes.image}>
					<div className={classes.iconContainer}>
						<PlayArrow className={classes.icon} />
					</div>
					<ResponsiveImage
						src={video.thumbnail_url}
						borderRadius
						height="160px"
						width="160px"
						gradient
					/>
				</div>
			</Button>
		</div>
	)
}

export default VideoItem

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: 140,
		minWidth: 140,
	},
	button: {
		padding: 0,
	},
	image: {
		width: '100%',
		overflow: 'hidden',
	},
	iconContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		zIndex: 999,
		width: '100%',
		height: '100%',
	},
	icon: {
		height: 60,
		width: 60,
		color: theme.palette.common.white,
	},
}))
