import React, { useState, useEffect } from 'react'
import { useUsers, useAlerts } from 'hooks'
import { AuthScreen } from 'components'
import {
	Button,
	Typography,
	Link,
	makeStyles,
} from '@material-ui/core'
import { Check } from '@material-ui/icons'

const ParentalApproval = ({ ...props }) => {
	const { id } = props.match.params

	const classes = useStyles()
	const [user, setUser] = useState()

	const { showAlertError, showAlertSuccess } =
		useAlerts()

	const {
		handleChange,
		findPublicUser,
		parentApprove,
		parentUnapprove,
	} = useUsers()

	const handleFindPublicUser = async () => {
		let user = await findPublicUser(id)
		setUser(user)
	}

	const handleApproveClick = async () => {
		let resp = await parentApprove(user?.id)
		showAlertSuccess(
			`${user.name} has been approved to use Ellevate.`
		)
		setUser(resp)
	}

	const handleDenyClick = async () => {
		let resp = await parentUnapprove(user?.id)
		showAlertSuccess(
			`${user.name} is not approved to use Ellevate.`
		)
		setUser(resp)
	}

	useEffect(() => {
		if (id) {
			handleFindPublicUser()
		}
	}, [id])

	return (
		<div>
			<AuthScreen title="Parental Approval">
				<Typography
					variant="body1"
					className={classes.notification}
				>
					{user?.name ? user.name : '...'} signed up
					to use the ellevate app, but is under the
					age of 18, and entered your details as their
					parent/guardian.
					<br />
					<br />
					Please approve or deny their use of the app
					below.
					<br />
					<br />
					For further details please visit{' '}
					<Link
						target="_blank"
						color="secondary"
						href="https://e11evate.co.uk"
					>
						our website here.
					</Link>
				</Typography>
				<div className={classes.buttons}>
					<Button
						fullWidth
						onClick={handleApproveClick}
						variant="contained"
						color="primary"
						className={classes.button}
						startIcon={<Check />}
					>
						Approve
					</Button>
					<Button
						fullWidth
						className={classes.button}
						onClick={handleDenyClick}
						variant="contained"
						color="primary"
						startIcon={
							user?.parent_approved == true && (
								<Check />
							)
						}
					>
						Deny
					</Button>
				</div>
			</AuthScreen>
		</div>
	)
}

export default ParentalApproval

const useStyles = makeStyles((theme) => ({
	notification: {
		padding: theme.spacing(2, 0),
		textTransform: 'uppercase',
		color: theme.palette.text.primary,
		borderTop: `1px solid ${theme.palette.primary.light}`,
		borderBottom: `1px solid ${theme.palette.primary.light}`,
		whiteSpace: 'pre-line',
	},
	buttons: {
		marginTop: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	button: {
		maxWidth: 200,
		marginBottom: 5,
	},
}))
