import React, { useContext, useState } from "react";
import { useAuth, useAlerts, useUsers, usePaywall } from "hooks";
import { AppContext } from "context";
import { STRIPE_PUBLISHABLE_KEY } from "lib/constants";
import { loadStripe } from "@stripe/stripe-js";
import { useHistory } from "react-router";
import { isPaid, isStripe } from "lib/utils";
import { Button, makeStyles, Hidden } from "@material-ui/core";
import Center from '../../components/Layout/Center'
import PromotionCodeModal from "./promotionCodeModal";
import SelectPaymentMethodModal from "./selectPaymentMethodModal";

const StripeCreditCard = ({ onSubscribed, selectedPackage }) => {
  const classes = useStyles();

  const history = useHistory();
  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

  const { fetchMe } = useAuth();
  const { currentUser } = useContext(AppContext);
  const { showAlertSuccess, showAlertError } = useAlerts();
  const [openPromotionModal, setOpenPromotionModal] = useState(false);
  const [openSelectPaymentModal, setSelectPaymentModal] = useState(false);

  const { subscribe } = useUsers();
  const { AddAselectedPlan } = usePaywall();

  const handleSubscribe = async () => {
    if (isStripe(currentUser)) {
      await subscribe(selectedPackage);
      await fetchMe();
      showAlertSuccess("You have successfully subscribed");
      if (onSubscribed) {
        onSubscribed();
      }
    } else {
      showAlertError("Please enter your credit card details.");
    }
  };
  const displyPromotionModal = () => {
    setOpenPromotionModal(true);
  };
  const displaySelectPaymentModal = () => {
    setSelectPaymentModal(true);
  };

  const handleManageSubscription = async () => {
    if (currentUser.country == "ID") {
      console.log(currentUser.country);
      AddAselectedPlan(selectedPackage);
      history.push("/users/StripeSubscription");
    }
    console.log(currentUser);
    history.push("/users/StripeSubscription");
  };

  return (
    <div>
      <PromotionCodeModal
        open={openPromotionModal}
        handleClose={() => setOpenPromotionModal(false)}
      />
      <SelectPaymentMethodModal
        open={openSelectPaymentModal}
        handleClose={() => setSelectPaymentModal(false)}
      />
      {!isPaid(currentUser) && (
        <div className={classes.promoCodeLink}>
          <Center>
            <Button
              onClick={()=>setOpenPromotionModal(true)}
              disableRipple
              className={classes.promoCodeLink}
            >
              + Add a promotion code
            </Button>
          </Center>
        </div>
      )}
      {currentUser && (
        <div>
          {/* {!isPaid(currentUser) && !isStripe(currentUser) && (
            <Elements stripe={stripePromise}>
              <ElementsConsumer>
                {({ stripe, elements }) => (
                  <StripeCreditCardForm
                    stripe={stripe}
                    elements={elements}
                    buttonLabel={"Add Credit Card"}
                  />
                )}
              </ElementsConsumer>
            </Elements>
          )} */}

          <div>
            {isPaid(currentUser) && isStripe(currentUser) && (
              <Button
                fullWidth
                className={classes.button}
                color="primary"
                onClick={handleManageSubscription}
                variant="contained"
              >
                MANAGE <Hidden smDown>CREDIT CARDS</Hidden>
              </Button>
            )}
          </div>

          <div>
            {!isPaid(currentUser) && isStripe(currentUser) ? (
            //   <Button
            //     fullWidth
            //     className={classes.button}
            //     color="primary"
            //     onClick={handleSubscribe}
            //     variant="contained"
            //   >
            //     SUBSCRIBE
            //   </Button>
            <Button
                fullWidth
                className={classes.button}
                color="primary"
                onClick={displaySelectPaymentModal}
                variant="contained"
              >
                PAY NOW
              </Button>
            ) : (
              <Button
                fullWidth
                className={classes.button}
                color="primary"
                onClick={displaySelectPaymentModal}
                variant="contained"
              >
                PAY NOW
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default StripeCreditCard;

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    textTransform: "none",
    backgroundColor: "white",
  },
  promoCodeLink: {
    fontSize: 12,
    color: "grey",
    textDecoration: "underline grey",
  },
}));
