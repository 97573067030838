import React, { useState } from 'react'
import {
	FormControl,
	InputAdornment,
	InputBase,
	Typography,
} from '@material-ui/core'
import { syntheticEvent } from 'lib/utils'
import clsx from 'clsx'
import { useStyles } from './styles'

const TextInput = ({
	className,
	required,
	label,
	type,
	name,
	margin,
	value,
	multiline,
	handleChange,
	rows,
	placeholder,
	disabled,
	onKeyPress,
	autoComplete = 'off',
	border: borderTop = false,
	borderBottom = false,
	endIcon,
	isClearable,
	isLoading,
	...props
}) => {
	const classes = useStyles({
		borderTop: borderTop,
		borderBottom: borderBottom,
	})

	const [error, setError] = useState(false)

	const handleInputChange = (ev) => {
		let { name, value } = ev.target
		required && value === ''
			? setError(true)
			: setError(false)
		handleChange(ev)
	}

	return (
		<FormControl
			fullWidth
			className={clsx(className, classes.root)}
		>
			{label && (
				<div className={classes.label}>
					<Typography variant="body1">
						{label}
					</Typography>
				</div>
			)}
			<InputBase
				error={error}
				rows={rows}
				className={clsx(classes.inputBase, {
					[classes.uppercase]: type === 'date',
				})}
				multiline={multiline}
				autoComplete="off"
				fullWidth
				type={type}
				name={name}
				margin={margin}
				disabled={disabled}
				placeholder={placeholder}
				onKeyPress={onKeyPress}
				onChange={handleInputChange}
				value={value}
				endAdornment={
					endIcon && (
						<InputAdornment>{endIcon}</InputAdornment>
					)
				}
			/>
		</FormControl>
	)
}

export default TextInput
