import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'

const Footer = ({ item, ...props }) => {
	const classes = useStyles()

	return <div className={classes.root}></div>
}

Footer.propTypes = {
	className: PropTypes.string,
	item: PropTypes.object,
}

export default Footer

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
}))
