import React, { useState, useEffect } from 'react'
import {
	usePaywall,
	useAuth,
	useAlerts,
	useEvents,
} from 'hooks'
import {
	Box,
	Button,
	Container,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { StickyButton, CoverImage } from 'components'
import EventDateBox from 'containers/events/EventDate'
import moment from 'moment'
import { useHistory } from 'react-router'

const EventShow = ({ ...props }) => {
	const { id } = props.match.params
	const history = useHistory()
	const classes = useStyles()

	const [applied, setApplied] = useState(false)

	const { showAlertSuccess } = useAlerts()
	const { currentUser } = useAuth()

	const { fetchMe } = useAuth()

	const { isPaid } = usePaywall()

	const { event, findEvent, applyToEvent } =
		useEvents({
			id: id,
		})

	const handleApplyToEvent = async () => {
		if (!applied) {
			await applyToEvent(event.id)
			setApplied(true)
			showAlertSuccess(
				'You have applied to this event'
			)
		} else {
			showAlertSuccess(
				'You have already applied to this event'
			)
		}
	}

	const handleIsApplied = () => {
		if (event?.attendees.includes(currentUser.id)) {
			setApplied(true)
		} else {
			setApplied(false)
		}
	}

	useEffect(() => {
		if (id) {
			fetchMe()
			findEvent(id)
		}
	}, [id])

	useEffect(() => {
		if (event?.attendees && currentUser?.id) {
			handleIsApplied()
		}
	}, [event?.attendees, currentUser?.id])

	useEffect(() => {
		if (currentUser?.id && !isPaid) {
			history.push('/events')
		}
	}, [isPaid, currentUser?.id])

	return (
		<>
			<CoverImage
				desktopImageUrl={event.web_image_url}
				mobileImageUrl={event.image_url}
				secondaryAction={
					event?.start_date && (
						<EventDateBox event={event} />
					)
				}
			/>
			<Container maxWidth={'md'}>
				<div>
					<Box p={2}>
						<center>
							<Typography
								variant="body1"
								color="secondary"
								className={classes.subtitle}
							>
								{event?.display_start_date}
							</Typography>
							<Typography
								variant="h2"
								color="textPrimary"
								className={classes.title}
							>
								{event.title}
							</Typography>
							<Typography
								className={classes.description}
								variant="body1"
								color="textPrimary"
							>
								{event.description}
							</Typography>
						</center>
					</Box>
				</div>
			</Container>
			<StickyButton>
				<Button
					fullWidth
					variant="contained"
					color="primary"
					onClick={handleApplyToEvent}
					className={classes.applyButton}
				>
					{applied ? 'Applied' : 'Apply'}
				</Button>
			</StickyButton>
		</>
	)
}

export default EventShow

const useStyles = makeStyles((theme) => ({
	applyButton: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	},
	title: {
		fontSize: 78,
		lineHeight: 0.8,
	},
	subtitle: {
		textTransform: 'uppercase',
	},
	description: {
		marginTop: 40,
		whiteSpace: 'pre-wrap',
	},
}))
