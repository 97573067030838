import api from "api";

const endpoints = {
  getTags: "api/video/captured/tags",
  getFeedbackTags: "api/video/feedback/tags",
  uploadVideo: "api/video/captured/upload",
  getVideos: "api/video/captured",
  getVideo: (id) => `api/video/captured/${id}`
}

export default {
  getTags: async () => {
    return await api.get(endpoints.getTags)
  },
  getFeedbackTags: async () => {
    return await api.get(endpoints.getFeedbackTags)
  },
  uploadVideo: async ({ video, description, tags }) => {
    return await api.post(endpoints.uploadVideo, { video, description, tags })
  },
  getVideos: async () => {
    return await api.get(endpoints.getVideos)
  },
  getVideo: async (captureId) => {
    return await api.get(endpoints.getVideo(captureId))
  },
}