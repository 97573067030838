import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import ReactPlayer from 'react-player'
const VideoPlayer = ({
	src,
	autoPlay = true,
	onComplete,
	...props
}) => {
	const classes = useStyles()
	const [completed, setCompleted] = useState(false)
	const [playlist, setPlaylist] = useState()

	const handleBuildPlaylist = () => {
		setPlaylist({
			file: src,
		})
	}

	const handleOnTimeEvent = async (
		playerProgress
	) => {
		const { duration, currentTime } = playerProgress
		if (!completed && currentTime / duration > 0.99) {
			setCompleted(true)
			if (onComplete) {
				onComplete()
			}
		}
	}

	useEffect(() => {
		setCompleted(false)
		handleBuildPlaylist()
	}, [])

	return (
		<div>
			{playlist && (
				console.log(playlist.file),
				<center>
					<ReactPlayer 
					
					playing
					width={"100%"}
					height={"100%"}
					url={playlist.file}
					controls={true}
			
					config={{ 
					  attributes: {
						controlsList: 'nodownload',
					  },
					}}
					/>

					{/* <ReactJWPlayer
						playerId="12345"
						playlist={playlist}
						isAutoPlay={autoPlay}
						onTime={handleOnTimeEvent}
					/> */}
				</center>
			)}
		</div>
	)
}

export default VideoPlayer

const useStyles = makeStyles((theme) => ({
	root: {
		position: "absolute",
		top: 0,
		left: 0,
	},
	video: {
	position: "relative",
  	paddingTop: "56.25% "
	},
}))
