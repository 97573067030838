import React, { useEffect, useState } from 'react'
import api from 'api'
import { useAlerts, useLoaders } from 'hooks'
import { useHistory } from 'react-router'

/* useResource
  A base hook that allows for CRUD operations of a REST API that follows
  standard REST patterns of GET POST PUT and DELETE to create, update, create and
  destroy objects.

  @param id - The resource ID to auto fetch
  @param url - The API endpoint. The is set dynamically using setEndpoint
  @param name - The name of the resource needed when using POST and PUT
*/

const useResource = ({
	url = '/',
	urlMany,
	name,
	...props
}) => {
	const history = useHistory()
	const { isLoading, showLoading, hideLoading } =
		useLoaders()
	const {
		showAlertError,
		showAlertSuccess,
		showAlertWarning,
	} = useAlerts()

	const [isLoaded, setIsLoaded] = useState(false)
	const [isEmpty, setIsEmpty] = useState(false)
	const [id, setId] = useState(props.id)
	const [resource, setResource] = useState({
		id: props.id,
	})
	const [resources, setResources] = useState([])
	const [meta, setMeta] = useState({})
	const [params, setParams] = useState({})
	const [page, setPage] = useState(1)
	const [perPage, setPerPage] = useState(20)
	const [numPages, setNumPages] = useState(1)

	const [sortKey, setSortKey] = useState(
		`${name}s.id`
	)
	const [sortDirection, setSortDirection] =
		useState('desc')

	const [totalCount, setTotalCount] = useState(0)
	const [errors, setErrors] = useState([])

	const findOne = async (id, customUrl = "") => {
		if (!id) showAlertError('No ID was provided')
		try {
			showLoading()
			//setIsLoaded(false)
			setId(id)
			const res = await api.get(customUrl == "" ? `${url}/${id}` : `${customUrl}/${id}`)
			setResource(res.data)
			setMeta(res.meta)
			setIsLoaded(true)
			hideLoading()
			return res.data
		} catch (e) {
			showAlertError("This page doesn't exist")
			history.push('/home')
			handleErrors(e)
		}
		hideLoading()
	}

	const findMany = async (
		params,
		page = 1,
		loadMore = false,
		customUrl = ""
	) => {
		console.log(customUrl)
		console.log(urlMany)
		try {
			showLoading()
			setParams(params)
			setPage(page)
			if (!loadMore) {
				setIsLoaded(false)
			}
			console.log(params)
			let res = null

			if(customUrl != "") {
				res = await api.get(customUrl, {
					params: {
						...params,
						page,
					},
				})
			} else if(res == null && urlMany) {
				res = await api.get(urlMany || url, {
					params: {
						...params,
						page,
					},
				})
			} else {
				res = await api.get(url, {
					params: {
						...params,
						page,
					},
				})
			}

			// if(urlMany) {
			// 	res = await api.get(urlMany || url, {
			// 		params: {
			// 			...params,
			// 			page,
			// 		},
			// 	})
			// } else {
			// 	res = await api.get(customUrl == "" ? url : customUrl, {
			// 		params: {
			// 			...params,
			// 			page,
			// 		},
			// 	})
			// }


			hideLoading()
			if (res.data) {
				if (!loadMore) {
					setResources(res.data.data == undefined ? res.data : res.data.data)
				} else {
					setResources([...resources, ...res.data.data])
				}
					setMeta({page:res.data.current_page, per_page:res.data.per_page, num_pages:res.data.total, total_count:res.data.total})
					setPage(res.data.current_page)
					setPerPage(res.data.per_page)
					setNumPages(Math.ceil(res.data.total / res.data.per_page))
					setTotalCount(res.data.total)

					// Logic for setting isEmpty as true or false
					if(res.data.data == undefined) {
						if(Array.isArray(res?.data)) {
							setIsEmpty(res?.data?.length > 0 ? false : true)
						} else if(typeof res.data == "object" && Object.keys(res.data).length > 0) {
							setIsEmpty(false)
						} else {
							setIsEmpty(true)
						}
					} else {
						if(Array.isArray(res?.data?.data)) {
							setIsEmpty(res?.data?.data?.length > 0 ? false : true)
						} else if(typeof res.data.data == "object" && Object.keys(res.data.data).length > 0) {
							setIsEmpty(false)
						} else {
							setIsEmpty(true)
						}
					}

					// res.data.data == undefined ? setIsEmpty(res?.data?.length > 0 ? false : true) : setIsEmpty(res?.data?.data?.length > 0 ? false : true)

				setIsLoaded(true)
				return res.data
			}
			hideLoading()
		} catch (e) {
			handleErrors(e)
		}
		hideLoading()
	}

	const loadMore = () => {
		let nextPage = page + 1
		let appendResults = true
		findMany(params, nextPage, appendResults)
	}

	// const create = async (
	// 	resource,
	// 	customUrl = ""
	// ) => {
	// 	try {
	// 		showLoading()
	// 		const res = await api.post(customUrl == "" ? url : customUrl, {
	// 			[name]: resource,
	// 		})
	// 		if (res.data && res.data.id) {
	// 			setResource(res.data)
	// 			setIsLoaded(true)
	// 			setId(res.data.id)
	// 		}
	// 		return res.data
	// 	} catch (e) {
	// 		showAlertError('There was an issue saving')
	// 		handleErrors(e)
	// 	} finally {
	// 		hideLoading()
	// 	}
	// }

	    /**
   * 
   * @param {*} resource 
   * @param {*} files 
   * @param {*} customUrl 
   * @returns 
   */
			const create = async (
				resource,
				files,
				customUrl = ""
			) => {
				try {
					const config = {
						headers: {
							"content-type": "multipart/form-data",
						},
					}
					console.log(resource)
					let formData = new FormData()
					for (const item in resource) {
						formData.append(
							item,
							resource[item]
						)
					}
					if (files && Object.keys(files).length) {
						for (const key in files) {
							formData.append(
								key,
								files[key].file
							)
						}
					}
					
					for (var key of formData.entries()) {
						console.log(key);
					}
		
					const res = await api.post(
						customUrl == "" ? `${url}/create` : customUrl,
						formData,
						config
					)
					if (res.data && res.data.id) {
						setResource(res.data)
						setIsLoaded(true)
						setId(res.data.id)
					}
					hideLoading()
					return res.data == undefined ? res.message : res.data
				} catch (e) {
					console.log(e)
					showAlertError("There was an issue saving")
					handleErrors(e)
				}
			}

	const update = async (resource) => {
		setId(resource.id)
		try {
			showLoading()
			const res = await api.put(
				`${url}/${resource.id}`,
				{
					[name]: resource,
				}
			)
			hideLoading()
			return res.data
		} catch (e) {
			showAlertError('There was an issue updating')
			handleErrors(e)
		}
	}

	const destroy = async (resource, customUrl="") => {
		try {
			showLoading()
			const res = await api.delete(
				customUrl == "" ?
				`${url}/${resource.id}`:
				`${customUrl}/${resource.id}`
			)
			setResource({ data: {} })
			hideLoading()
		} catch (e) {
			handleErrors(e)
		}
	}

	const paginate = (page, customUrl="") => findMany(params, page, false, customUrl)

	const uploadFile = async (
		file,
		attributeName,
		_id
	) => {
		try {
			const config = {
				headers: {
					'content-type': 'multipart/form-data',
				},
			}
			let formData = new FormData()
			formData.append(
				`${name}[${attributeName}]`,
				file
			)
			const res = await api.post(
				`${url}/${_id || id}/upload_file`,
				formData,
				config
			)
		} catch (e) {
			showAlertError('There was an issue uploading')
			handleErrors(e)
		}
	}

	const deleteFile = async (type) => {
		showLoading()
		await api.post(`${url}/${id}/delete_file`, {
			type,
		})
		hideLoading()
	}

	const handleChange = (ev) => {
		const { name } = ev.target
		const value =
			ev.target.type === 'checkbox'
				? ev.target.checked
				: ev.target.value
		setResource({
			...resource,
			[name]: value,
		})
	}

	const handleErrors = (e) => {
		hideLoading()
		setIsLoaded(false)
		setErrors(e)
		console.error('useResource Error:', e)
		if (e?.status == 401) {
			showAlertError('Please Sign In to continue')
			localStorage.removeItem('token')
			setTimeout(
				() => (window.location.href = '/login'),
				1000
			)
		}
		return false
	}

	const reloadOne = () => findOne(id)
	const reloadMany = () => findMany(params)

	const handleSort = (sortBy) => {
		sortDirection == 'asc'
			? setSortDirection('desc')
			: setSortDirection('asc')
		setSortKey(sortBy)
		history.push(
			`?sort_key=${sortKey}&sort_direction=${sortDirection}`
		)
	}

	useEffect(() => {
		if (props.id) setId(props.id)
	}, [props.id])

	return {
		id,
		isLoading,
		isLoaded,
		isEmpty,
		resource,
		resources,
		setResource,
		setResources,
		errors,
		meta,
		setMeta,
		findOne,
		findMany,
		update,
		create,
		destroy,
		paginate,
		loadMore,
		handleChange,
		uploadFile,
		deleteFile,
		params,
		page,
		perPage,
		numPages,
		totalCount,
		reloadOne,
		reloadMany,
		sortKey,
		sortDirection,
		handleSort,
	}
}

export default useResource
