import overrides from 'theme/overrides'
import palette from 'theme/palette'
import typography from 'theme/typography'
import metrics from './metrics'

export const muiTheme = {
	palette,
	overrides,
	typography,
	metrics,
}
