import React from 'react'
import {
	Typography,
	makeStyles,
} from '@material-ui/core'
import { CustomIcon} from 'components'
import Label from '../../components/Atoms/Label'
import Bars from 'containers/skills/Bars'

const ProgressBar = ({
	icon,
	label,
	secondaryLabel,
	color,
	points = 1,
	newPoints = 0,
	maxPoints = 10,
}) => {
	const classes = useStyles()

	if (!secondaryLabel) {
		secondaryLabel = `+${points} SKILL POINT${
			points == 1 ? '' : 'S'
		}`
	}

	return (
		<div className={classes.item}>
			<div className={classes.row}>
				<div className={classes.group}>
					<div className={classes.icon}>
						<CustomIcon size={38} icon={icon} />
					</div>
					<Typography
						variant="h3"
						ml={10}
						className={classes.title}
					>
						{label}
					</Typography>
					{newPoints > 0 && (
						<Typography
							color={'secondary'}
							variant={'h3'}
						>
							+{newPoints}
						</Typography>
					)}
				</div>
				<div className={classes.label}>
					<Label label={secondaryLabel} />
				</div>
			</div>

			<Bars
				points={points}
				color={color}
				newPoints={newPoints}
				maxPoints={maxPoints}
			/>
		</div>
	)
}

export default ProgressBar

const useStyles = makeStyles((theme) => ({
	item: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		marginBottom: 40,
	},
	title: {
		marginLeft: theme.spacing(1),
		[theme.breakpoints.down('xs')]: {
			fontSize: 32,
		},
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	group: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	icon: {
		marginTop: 10,
	},
	label: {},
}))
