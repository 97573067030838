import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const VideoTags = ({
	tags,
	activeTags,
	handleClick,
}) => {
	const classes = useStyles()
	return (
		<div className={classes.root}>
			{tags.map((tag, i) => (
				<Button
					key={i}
					variant="outlined"
					onClick={() => handleClick(tag.id)}
					className={clsx(classes.tag, {
						[classes.selected]:
							activeTags?.includes(tag.id),
					})}
				>
					{tag.name}
				</Button>
			))}
		</div>
	)
}

export default VideoTags

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	tag: {
		fontSize: 11,
		borderRadius: 20,
		border: `1px solid ${theme.palette.primary.main}`,
		margin: theme.spacing(0.5),
	},
	selected: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
		},
	},
}))
