import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router'
import {
	useAlerts,
	usePaywall,
	useCourses,
	useUsers,
	useVideo,
} from 'hooks'
import {
	Box,
	Button,
	Container,
	makeStyles,
} from '@material-ui/core'
import {
	StickyButton,
	ButtonLoader,
	VideoCover,
	VideoModal,
	CoverImage,
} from 'components'
import CourseDetails from 'containers/courses/CourseDetails'
import { AppContext } from 'context'
import { Lock } from '@material-ui/icons'
import PremiumSignupModal from 'containers/payments/PremiumSignupModal'
import courseApi from "api/data/course";
import FeedbackDetails from './FeedbackDetails'
import FeedbackAttempts from './FeedbackAttempts'
import useAttempts from 'hooks/useAttempts'

const FeedbackList = ({ ...props }) => {
	const history = useHistory()
	const { id } = props.match.params

	const { currentUser } = useContext(AppContext)
	const {
		isPaid,
		showPaywall,
		hidePaywall,
		openPaywall,
	} = usePaywall()

	const {
		activeVideo,
		videoOpen,
		playVideo,
		stopVideo,
	} = useVideo()

	const { showAlertSuccess } = useAlerts()
	const { isLoading, enrollCourse } = useUsers()
	const classes = useStyles()

	const { attempts, findAttempts } = useAttempts({})

	const handleNavigateFeedbackUpload = async () => {
		history.push("/feedback/upload")
	}

	useEffect(() => {
		findAttempts()
	}, [])

	// const getEnrolledCourses = async () => {
	// 	const resp = await courseApi.getEnrolledCourses();
	// 	if (resp.data.find(c => c.id === course?.id))
	// 		history.push(`/courses/${course.id}`)
	// }
	// useEffect(() => {
	// 	getEnrolledCourses()
	// }, [currentUser, course])

	return (
		<>
			<VideoCover
				mobileImageUrl={"https://res.cloudinary.com/ellevate-football/image/upload/v1637080192/courses/web_images/1.png"}
				desktopImageUrl={"https://res.cloudinary.com/ellevate-football/image/upload/v1637080192/courses/web_images/1.png"}
				maxHeight={400}
				handleClick={() => playVideo(null, "https://res.cloudinary.com/ellevate-football/video/upload/q_auto:eco/v1630073291/courses/videos/1.mp4")}
			/>
			<Container className={classes.container}>
				<FeedbackDetails />

				{attempts?.length != 0 && <FeedbackAttempts attempts={attempts} />}
				
				{attempts?.length < 3 && (
					<Button
						fullWidth
						variant="contained"
						onClick={handleNavigateFeedbackUpload}
						startIcon={isLoading && <ButtonLoader />}
						className={classes.button}
					>
						Upload attempt
					</Button>
				)}

				{attempts?.length >= 3 && (
					<div className={classes.disabledButton}>
						<span>You have reached your attempts limit.</span>
					</div>
				)}

				<VideoModal
					autoPlay
					title={activeVideo?.title}
					open={videoOpen}
					src={activeVideo?.src}
					captions={activeVideo?.captions}
					videoId={activeVideo?.videoId}
					handleClose={stopVideo}
				/>
			</Container>
		</>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {},
	container: {
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		width: "100%"
	},
	button: {
		width: "100%",
		maxWidth: "500px",
		backgroundColor: "white",
		margin: "80px 0px"
	},
	disabledButton: {
		width: "100%",
		maxWidth: "500px",
		backgroundColor: "#e0e0e0",
		margin: "80px 0px",
		color: "rgba(0, 0, 0, 0.87)",
		borderRadius: "20px",
		display: "flex",
		justifyContent: "center",
		fontSize: "17px",
		textTransform: "uppercase",
		padding: "4px"
	}
}))

export default FeedbackList
