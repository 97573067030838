import React, {
	useContext,
	useState,
	useEffect,
} from 'react'
import { AppContext } from 'context'
import { useResponsive } from 'hooks'
import {
	Container,
	makeStyles,
	Typography,
} from '@material-ui/core'
import { useHistory } from 'react-router'
import { resize } from 'lib/cloudinary'
import CourseMeta from 'containers/courses/CourseMeta'
import {
	COVER_HEIGHT,
	COVER_MOBILE_HEIGHT,
} from 'lib/constants'
import clsx from 'clsx'

const RecommendedTraining = ({
	course,
	handleClick,
}) => {
	const history = useHistory()
	const { isMobile } = useResponsive()

	const { currentUser } = useContext(AppContext)

	const imageUrl = isMobile
		? course?.thumbnail_url
		: course?.web_image5_url

	const classes = useStyles({
		src: imageUrl,
	})

	return (
		<div
			onClick={handleClick}
			className={classes.cover}
		>
			<Container
				maxWidth="lg"
				className={classes.content}
			>
				<div>
					<Typography
						variant="h5"
						color="primary"
						className={classes.activeTraining}
					>
						SUGGESTED TRAINING
					</Typography>
				</div>
				<div className={classes.courseDetails}>
					<Typography
						variant="h2"
						color="textPrimary"
						className={classes.title}
					>
						{course.title}
					</Typography>
					<Typography
						variant="subtitle1"
						color="secondary"
						className={classes.subtitle}
					>
						{course.subtitle}
					</Typography>
					<CourseMeta course={course} fontSize={14} />
				</div>
			</Container>
			<div className={classes.gradient} />
		</div>
	)
}

export default RecommendedTraining

const useStyles = makeStyles((theme) => ({
	cover: {
		cursor: 'pointer',
		width: '100%',
		height: COVER_HEIGHT,
		backgroundPosition: '50% 25%',
		backgroundImage: (props) => `url(${props.src})`,
		backgroundSize: 'cover',
		[theme.breakpoints.down('sm')]: {
			height: COVER_MOBILE_HEIGHT,
		},
	},
	content: {
		paddingTop: 20,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'space-between',
		height: '100%',
		width: '100%',
	},
	activeTraining: {
		color: theme.palette.background.main,
	},
	subtitle: {
		marginTop: 8,
		fontWeight: 600,
	},
	courseDetails: {},
	gradient: {
		marginTop: -200,
		height: 200,
		width: '100%',
		backgroundImage:
			'linear-gradient(rgba(0,0,0, 0) 0%,rgba(0,0,0, 1) 100%)',
	},
}))
