import React, { useEffect } from 'react'
import {
	usePaywall,
	useAuth,
	useAlerts,
	useArticles,
} from 'hooks'
import {
	Box,
	Container,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { VideoPlayer, CoverImage } from 'components'
import { useHistory } from 'react-router'

const ArticleShow = ({ ...props }) => {
	const { id } = props.match.params
	const history = useHistory()
	const classes = useStyles()

	const { showAlertSuccess } = useAlerts()
	const { currentUser } = useAuth()

	const { fetchMe } = useAuth()

	const { isPaid } = usePaywall()

	const { article, findArticle } = useArticles({
		id: id,
	})

	useEffect(() => {
		if (id) {
			fetchMe()
			findArticle(id)
		}
	}, [id])

	useEffect(() => {
		if (currentUser?.id && !isPaid) {
			//history.push('/articles')
		}
	}, [isPaid, currentUser?.id])

	return (
		<>
			<CoverImage
				desktopImageUrl={article.image_url}
				mobileImageUrl={article.image_url}
			/>
			<Container maxWidth={'md'}>
				<div>
					<Box p={2}>
						<Typography
							variant="subtitle1"
							color="secondary"
							className={classes.category}
						>
							{article.category}
						</Typography>
						<Typography
							variant="h2"
							color="textPrimary"
							className={classes.title}
						>
							{article.title}
						</Typography>

						{article.author && (
							<Typography
								variant="subtitle1"
								color="secondary"
								className={classes.author}
							>
								BY {article?.author?.name}{' '}
								{article?.published_date}
							</Typography>
						)}
						<Typography
							variant="subtitle2"
							color="textPrimary"
							className={classes.subtitle}
						>
							{article.subtitle}
						</Typography>

						{(article.video_url ||
							article.video_id) && (
							<VideoPlayer
								src={article.video_url}
								videoId={article.video_id}
								captions={article.captions}
							/>
						)}

						{article.image2_url && (
							<div className={classes.image}>
								<CoverImage
									borderRadius
									desktopImageUrl={article.image2_url}
									mobileImageUrl={article.image2_url}
								/>
							</div>
						)}

						<Typography
							className={classes.description}
							variant="subtitle2"
							color="textPrimary"
						>
							{article.body}
						</Typography>
					</Box>
				</div>
			</Container>
		</>
	)
}

export default ArticleShow

const useStyles = makeStyles((theme) => ({
	applyButton: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	},
	image: {
		marginTop: theme.spacing(2),
	},
	category: {
		textTransform: 'uppercase',
	},
	author: {
		textTransform: 'uppercase',
	},
	title: {
		fontSize: 78,
		lineHeight: 0.8,
	},
	subtitle: {
		whiteSpace: 'pre-wrap',
		marginTop: theme.spacing(2),
	},
	description: {
		marginTop: 40,
		whiteSpace: 'pre-wrap',
	},
}))
