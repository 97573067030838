import React from 'react'
import captureApi from 'api/data/capture'
import { useResource } from 'hooks'

const useVideos = ({ id, ...props }) => {
	const {
		resourceId: videoId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: video,
		resources: videos,
		findOne: findVideo,
		findMany: findVideos,
		update: updateVideo,
		create: createVideo,
		save: saveVideo,
		destroy: deleteVideo,
		handleChange,
		handleChangePage,
		refresh: reloadVideo,
		reloadMany: reloadVideos,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		paginate,
		loadMore,
		sortKey,
		sortDirection,
		handleSort,
		setResource: setVideo,
	} = useResource({
		id: id,
		url: '/api/video',
		name: 'video',
	})

	const findTags = async () => {
		const resp = await captureApi.getTags();
		return resp.data
	}

	const findFeedbackTags = async () => {
		const resp = await captureApi.getFeedbackTags();
		return resp.data
	}

	return {
		videoId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		video,
		videos,
		findVideo,
		findVideos,
		findTags,
		saveVideo,
		updateVideo,
		createVideo,
		deleteVideo,
		handleChange,
		handleChangePage,
		query,
		page,
		numPages,
		perPage,
		paginate,
		loadMore,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadVideo,
		reloadVideos,
		setVideo,
		findFeedbackTags
	}
}

export default useVideos
