import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useAuth, useAlerts } from 'hooks'
import {
	Box,
	List,
	ListItem,
	ListItemText,
	Typography,
	Container,
	makeStyles,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@material-ui/core'
import { SETTING_ITEMS } from 'lib/navigation'
import { Subheader } from 'components'
import clsx from 'clsx'

const SettingsList = ({ ...props }) => {
	const history = useHistory()
	const classes = useStyles()
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

	const { logoutUser } = useAuth()

	const { showAlertSuccess } = useAlerts()

	const handleClick = async (item) => {
		switch (item.value) {
			case '/terms':
				window.open(
					'https://ellevate-football.com/terms-conditions/',
					'_blank'
				)
				break
			case 'contact_us':
				window.open(
					'mailto:info@e11evate.co.uk',
					'_blank'
				)
				break
			case 'logout':
				showAlertSuccess('You have logged out.')
				await logoutUser()
				break
			case 'delete_account':
				setShowDeleteDialog(true)
				break
			default:
				history.push(item.value)
				break
		}
	}

	const deleteUser = () => {
		console.log("deleteUser")
	}

	return (
		<>
			<Subheader title="Settings" />
			<Container>
				<List className={classes.list}>
					{SETTING_ITEMS.map((item, i) => (
						<ListItem
							disableGutters
							button
							onClick={() => handleClick(item)}
							className={clsx(classes.lineItem, {
								[classes.borderTop]: i === 0,
							})}
							key={i}
						>
							<ListItemText
								primary={
									<Typography
										variant="body2"
										color="primary"
									>
										{item.label}
									</Typography>
								}
							/>
						</ListItem>
					))}
				</List>
			</Container>
			<Dialog
				onClose={() => setShowDeleteDialog(false)}
				open={showDeleteDialog}
			>
				<DialogTitle>Delete your account</DialogTitle>
				<DialogContent dividers>
					<p>Are you sure you want to delete your account?</p>
				</DialogContent>
				<DialogActions onClick={() => setShowDeleteDialog(false)}>
					<Typography
						variant="body2"
						color="primary"
						onClick={() => deleteUser()}
					>
						Delete
					</Typography>
				</DialogActions>
			</Dialog>

		</>
	)
}

export default SettingsList

const useStyles = makeStyles((theme) => ({
	borderTop: {
		borderTop: `1px solid ${theme.palette.primary.light}`,
	},
	lineItem: {
		borderBottom: `1px solid ${theme.palette.primary.light}`,
	},
}))
