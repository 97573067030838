// import {Dimensions} from 'react-native'
// const {width, height} = Dimensions.get('window')

export default {
  spacing: 5,
  paddingSmall: 8,
  marginSmall: 10,
  marginBig: 30,
  contentTopMargin: 44,
  verticalMargin: 20,
  horizontalMargin: 20,
  authScreenTopMargin: 50,
  authScreenBottomMargin: 35,
  // screenWidth: width < height ? width : height,
  // screenHeight: width < height ? height : width,
  // componentFullwidth: width - 20,
  buttonHeigth: 36,
  marginBottom: 22,
  borderRadius: 8,
  borderRadiusModal: 8,
  paddingLeftTextInput: 18,
  paddingRightTextInput: 10,
  iconSize: 23,
  bigIconSize: 65,
  inputHeight: 38,
  searchInputHeight: 30,
  searchInputMargin: 17,
  loadingButtonHeigth: 30,
  standardHeight: 55,
  tabletMaxWidth: 440,
  lineHeight: 38,
  // galleryImageSize: ((width - 40)/ 4) - (2 / 3)
}
