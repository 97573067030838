import React from 'react'
import {
	Grid,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { WHAT_YOU_NEED_ICONS } from 'lib/constants'
import { CustomIcon } from 'components'

const WhatYouNeedIcons = ({
	icons = ['phone', 'football'],
	flexDirection = 'row',
	sm = 6,
	md = 6,
}) => {
	const classes = useStyles({ flexDirection })

	return (
		<div>
			<Typography
				variant="subtitle1"
				color="secondary"
				className={classes.text}
			>
				WHAT YOU NEED
			</Typography>
			<Grid container spacing={1}>
				{icons?.map((icon, i) => (
					<Grid item xs={6} sm={sm} md={md} key={i}>
						<div className={classes.content}>
							<Typography
								variant="h6"
								color={'secondary'}
							>
								{WHAT_YOU_NEED_ICONS[icon]}
							</Typography>
							<CustomIcon size={80} icon={icon} />
						</div>
					</Grid>
				))}
			</Grid>
		</div>
	)
}

export default WhatYouNeedIcons

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	text: {
		fontSize: 18,
		margin: theme.spacing(2, 0),
	},
	item: {
		padding: 20,
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
	},
	content: {
		width: '100%',
		display: 'flex',
		flexDirection: (props) => props.flexDirection,
		flexWrap: 'wrap',
		alignItems: 'center',
	},
}))
