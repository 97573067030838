import React from 'react'
import {
	CardActionArea,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { ResponsiveImage } from 'components'
import { resize } from 'lib/cloudinary'
import {
	THUMBNAIL_HEIGHT,
	THUMBNAIL_WIDTH_X3,
} from 'lib/constants'
import EventThumbnailDate from 'containers/events/EventThumbnailDate'

const EventItem = ({
	event,
	handleClick,
	width = THUMBNAIL_WIDTH_X3,
	height = THUMBNAIL_HEIGHT,
	isLocked,
	...props
}) => {
	const classes = useStyles({
		width: width,
		height: height,
	})

	const imageUrl = resize(event.image_url, {
		width: width,
		height: height,
	})

	return (
		<div className={classes.root}>
			<CardActionArea
				onClick={() => handleClick(event)}
			>
				<div className={classes.date}>
					<EventThumbnailDate
						event={event}
						opacity={isLocked ? 0.8 : 1.0}
					/>
				</div>
				<ResponsiveImage
					width={width}
					src={imageUrl}
					borderRadius
					gradient
					opacity={isLocked ? 0.6 : 1.0}
				/>
			</CardActionArea>
			<Typography
				variant="body2"
				color="secondary"
				className={classes.caption}
			>
				{event?.display_start_date}
			</Typography>
			<Typography
				variant="subtitle1"
				color="textPrimary"
				className={classes.title}
			>
				{event.title}
			</Typography>
		</div>
	)
}

export default EventItem

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 40,
		marginBottom: 40,
		maxWidth: (props) => props.width,
		height: (props) => props.height,
	},
	date: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		position: 'relative',
		top: 0,
		marginBottom: -120,
		paddingRight: 10,
		zIndex: theme.zIndex.drawer + 1,
	},
	button: {
		padding: 0,
		borderRadius: 0,
	},
}))
