import React, { useState, useEffect } from 'react'
import {
	CoverImage,
	Modal,
	VideoModal,
} from 'components'
import { useAuth, useVideo } from 'hooks'
import {
	Button,
	makeStyles,
	Typography,
} from '@material-ui/core'
import {
	INTRO_VIDEO_URL,
	INTRO_IMAGE_URL,
} from 'lib/constants'
import { PlayArrow } from '@material-ui/icons'
import { uniq } from 'lodash'

const IntroVideoModal = ({ open, handleClose }) => {
	const classes = useStyles()

	const { currentUser, updateMe } = useAuth()

	const { videoOpen, playVideo, stopVideo } =
		useVideo()

	const handlePlayVideo = async () => {
		let viewedNotifications = uniq([
			...currentUser?.viewed_notifications,
			'video_intro',
		])
		await updateMe({
			id: currentUser.id,
			viewed_notifications: viewedNotifications,
		})
		playVideo(INTRO_VIDEO_URL)
	}

	const handleStopVideo = () => {
		stopVideo()
	}

	return (
		<Modal
			maxWidth="md"
			open={open}
			handleClose={handleClose}
		>
			<CoverImage
				mobileImageUrl={INTRO_IMAGE_URL}
				desktopImageUrl={INTRO_IMAGE_URL}
				icon={PlayArrow}
				onClick={handlePlayVideo}
				maxHeight={400}
			/>
			<div className={classes.modalContent}>
				<Typography variant="h3">
					BEFORE YOU GET STARTED
				</Typography>
				<Typography variant="body1">
					What is Training and how does it work,
					introductory video to explain it all.
				</Typography>
			</div>
			<VideoModal
				open={videoOpen}
				src={INTRO_VIDEO_URL}
				handleClose={handleStopVideo}
			/>
		</Modal>
	)
}

export default IntroVideoModal

const useStyles = makeStyles((theme) => ({
	root: {},
	modalContent: {
		padding: theme.spacing(2),
	},
}))
