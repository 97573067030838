import React from 'react'
import api from 'api'
import { useLoaders, useResource } from 'hooks'

const useEvents = ({ id, ...props }) => {
	const { showLoading, hideLoading } = useLoaders()

	const {
		resourceId: eventId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: event,
		resources: events,
		findOne: findEvent,
		findMany: findEvents,
		update: updateEvent,
		create: createEvent,
		save: saveEvent,
		destroy: deleteEvent,
		handleChange,
		handleChangePage,
		reloadOne: reloadEvent,
		reloadMany: reloadEvents,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		setResources: setEvents,
	} = useResource({
		id: id,
		url: '/api/v1/events',
		name: 'event',
	})

	const applyToEvent = async (eventId) => {
		try {
			showLoading()
			const resp = await api.post(
				`/api/v1/events/${eventId}/register`
			)
			return resp.data
		} catch (e) {
			console.log(e)
		} finally {
			hideLoading()
		}
	}

	return {
		eventId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		event,
		events,
		findEvent,
		findEvents,
		saveEvent,
		updateEvent,
		createEvent,
		deleteEvent,
		handleChange,
		handleChangePage,
		applyToEvent,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadEvent,
		reloadEvents,
	}
}

export default useEvents
