import React from 'react'
import {
	Container,
	Button,
	Typography,
	makeStyles,
} from '@material-ui/core'
import {
	CoverImage,
	ResponsiveImage,
} from 'components'
import CourseMeta from 'containers/courses/CourseMeta'
import { useResponsive } from 'hooks'

const FeaturedCourseItem = ({
	course,
	handleClick,
	...props
}) => {
	const classes = useStyles()

	const { isMobile } = useResponsive()

	return (
		<div
			className={classes.root}
			onClick={() => handleClick(course)}
		>
			<CoverImage
				gradient
				secondaryLabel="Featured"
				mobileImageUrl={course.image_url}
				desktopImageUrl={course.image_url}
			/>
			<Container maxWidth="lg">
				<div className={classes.container}>
					<Typography
						variant="h2"
						color="textPrimary"
						className={classes.title}
					>
						{course.title}
					</Typography>
					<Typography
						variant="subtitle1"
						color="secondary"
						className={classes.subtitle}
					>
						{course.subtitle}
					</Typography>
					<CourseMeta fontSize={14} course={course} />
				</div>
			</Container>
		</div>
	)
}

export default FeaturedCourseItem

const useStyles = makeStyles((theme) => ({
	root: {},
	title: {},
	subtitle: {
		marginTop: 8,
		fontWeight: 600,
	},
	button: {
		padding: 0,
		borderRadius: 0,
	},
	container: {
		margin: theme.spacing(0, 0, 0, 9),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(0),
		},
	},
}))
