import React from 'react'
import {
	ListItem,
	ListItemText,
	Typography,
	makeStyles,
} from '@material-ui/core'

const LineItem = ({ label, value, ...rest }) => {
	const classes = useStyles()

	return (
		<ListItem>
			<ListItemText
				primary={
					<Typography variant="body2">
						{label}
					</Typography>
				}
				secondary={
					<Typography variant="body1">
						{value ? value : '-'}
					</Typography>
				}
			/>
		</ListItem>
	)
}

export default LineItem

const useStyles = makeStyles((theme) => ({
	root: {},
}))
