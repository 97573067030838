import React from 'react'
import api from 'api'
import {
	useAlerts,
	useLoaders,
	useResource,
} from 'hooks'

const useArticles = ({ id, ...props }) => {
	const { showLoading, hideLoading } = useLoaders()
	const { showAlertError, showAlertSuccess } =
		useAlerts()

	const {
		resourceId: articleId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: article,
		resources: articles,
		findOne: findArticle,
		findMany: findArticles,
		update: updateArticle,
		create: createArticle,
		save: saveArticle,
		destroy: deleteArticle,
		handleChange,
		handleChangePage,
		reload: reloadArticle,
		reloadMany: reloadArticles,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
	} = useResource({
		id: id,
		url: '/api/article',
		urlMany: '/api/articles',
		name: 'article',
	})

	return {
		articleId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		article,
		articles,
		findArticle,
		findArticles,
		saveArticle,
		updateArticle,
		createArticle,
		deleteArticle,
		handleChange,
		handleChangePage,
		query,
		page,
		paginate,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		reloadArticle,
		reloadArticles,
	}
}

export default useArticles
