import React from 'react'
import ProgressBar from 'containers/skills/ProgressBar'
import { SKILLS } from 'lib/constants'

const ProgressBars = ({ skills }) => {
	return (
		<div>
			{skills &&
				skills.map((skill, i) => {
					let label = SKILLS.get(skill.name);

					let numPoints = parseInt(
						skill.point
					)

					if (!numPoints || numPoints === 0)
						return null
					

					return (
						<ProgressBar
							key={i}
							points={numPoints}
							label={label}
							icon={skill.name}
						/>
					)
				})}
		</div>
	)
}

export default ProgressBars
