import React from 'react'
import {
	Button,
	Grid,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { ResponsiveImage } from 'components'
import CourseMeta from 'containers/courses/CourseMeta'
import { resize } from 'lib/cloudinary'
import {
	THUMBNAIL_HEIGHT,
	THUMBNAIL_WIDTH_X3,
} from 'lib/constants'

const CourseSearchResult = ({
	course,
	handleClick,
	width = THUMBNAIL_WIDTH_X3,
	height = THUMBNAIL_HEIGHT,
	...props
}) => {
	const classes = useStyles()

	// Use slighly larger dimensions for search results
	const imageUrl = resize(course.image_url, {
		width: width,
		height: height,
	})

	return (
		<div className={classes.root}>
			<div className={classes.image}>
				<Button
					className={classes.button}
					onClick={() => handleClick(course)}
				>
					<ResponsiveImage
						height={height}
						width={width}
						src={course.image_url}
						borderRadius
					/>
				</Button>
			</div>
			<div className={classes.snippet}>
				<div>
					<Typography
						variant="h3"
						color="textPrimary"
						className={classes.title}
					>
						{course.title}
					</Typography>
					<Typography
						variant="body2"
						color="secondary"
						className={classes.subtitle}
					>
						{course.subtitle}
					</Typography>
				</div>
				<div>
					<CourseMeta course={course} />
				</div>
			</div>
		</div>
	)
}

export default CourseSearchResult

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginBottom: theme.spacing(2),
		width: '100%',
	},
	image: {
		padding: theme.spacing(1),
	},
	snippet: {
		padding: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	title: {
		lineHeight: 0.8,
	},
	button: {
		padding: 0,
		borderRadius: 0,
	},
}))
