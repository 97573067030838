import React, { useContext } from "react";
import { AppContext } from "context";
import { Subheader, Panel } from "components";
import { STRIPE_PUBLISHABLE_KEY } from "lib/constants";
import StripeCreditCardForm from "containers/payments/StripeCreditCardForm";
import StripeManageCard from "containers/payments/StripeManageCard";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { isPaid, isStripe } from "lib/utils";
import {
  Container,
  Typography,
  Hidden,
  makeStyles,
  Box,
} from "@material-ui/core";
import { CoverImage, Label } from "components";

import {
  INDONESIA_PRICE,
  MEMBERSHIP_PRICE,
  MEMBERSHIP_ANNUAL_PRICE,
  PREMIUM_BENEFITS,
  PREMIUM_SIGNUP_IMAGE_URL,
} from "lib/constants";

const Subscription = ({ ...props }) => {
  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
  const { currentUser } = useContext(AppContext);
  const classes = useStyles();
  const { selectedPackageName } = useContext(AppContext);

  return (
    <div className={classes.root}>
      <div className={classes.coverImage}>
        <CoverImage
          disableGradient
          mobileImageUrl={PREMIUM_SIGNUP_IMAGE_URL}
          desktopImageUrl={PREMIUM_SIGNUP_IMAGE_URL}
        />
        <div className={classes.gradient} />
      </div> 

      <Container maxWidth={isPaid(currentUser) ? "lg" : "sm"}>
        {!isPaid(currentUser)&&(
        <div className={classes.subscribeContent}>
          {" "}
          <div className={classes.rowContainer}>
            <Typography variant="h3" className={classes.title}>
              <Hidden smDown>
                {selectedPackageName == "Monthly" ? "MONTHLY " : "YEARLY "}
              </Hidden>
              SUBSCRIPTION
            </Typography>
           
            
          </div>
          <div className={classes.priceContainer}>
            <Typography variant="body1" color="secondary">
              {currentUser?.country == "ID" ? (
                <span>{INDONESIA_PRICE}</span>
              ) : (
                <span>
                  {selectedPackageName == "monthly"
                    ? MEMBERSHIP_PRICE
                    : MEMBERSHIP_ANNUAL_PRICE}
                </span>
              )}
            </Typography>
            <Typography variant="body1" color="secondary">
              +7 Day Free Trial
            </Typography>
          </div>
        </div>
        )}
        <div style={{ height: 40 }} />

        {currentUser && (
          <div>
            {!isPaid(currentUser) ? (
              <Elements stripe={stripePromise}>
                <ElementsConsumer>
                  {({ stripe, elements }) => (
                    <StripeCreditCardForm
                      stripe={stripe}
                      elements={elements}
                      buttonLabel="Add Credit Card"
                    />
                  )}
                </ElementsConsumer>
              </Elements>
            ) : (
              <Panel p={0}>
                <StripeManageCard
                  brand={currentUser.stripe_brand}
                  last4={currentUser.stripe_last_4}
                />
              </Panel>
            )}
          </div>
        )}
      </Container>
    </div>
  );
};

export default Subscription;
const useStyles = makeStyles((theme) => ({
  root: {},
  coverImage: {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "auto",
  },
  content: {
    padding: theme.spacing(1),
  },
  title: {
    color: theme.palette.common.white,
  },
  subtitle: {
    lineHeight: "1em",
  },
  listItem: {
    padding: 0,
    margin: 0,
  },
  benefits: {
    marginTop: theme.spacing(3),
  },
  avatar: {
    height: 24,
    width: 24,
    backgroundColor: theme.palette.common.white,
  },
  icon: {
    height: 20,
    width: 20,
    color: theme.palette.secondary.main,
  },
  subscribeContainer: {
    borderRadius: 8,
    width: "100%",
  },
  subscribeContent: {
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),

    marginTop: theme.spacing(2),
    borderRadius: 8,
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.background.light,
  },
  discount: {
    justifyContent: "center",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(2),
    color: "black",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 20,
    fontFamily: "Proximanova Medium",
    fontSize: 14,
    minWidth: 120,
    padding: "2px 12px 2px 12px",
    border: `1px solid ${theme.palette.secondary.main}`,
    alignContent: "center",
    alignSelf: "center",
  },

  gradient: {
    marginTop: -400,
    height: 200,
    width: "100%",
    backgroundImage: "linear-gradient(rgba(0,0,0, 0) 0%,rgba(0,0,0, 1) 100%)",
  },
  rowContainer: {
    display: "flex",
    gap: "23%",
  },
  columnContainer: {
    width: "100%",
  },
  submitButtonContainer: {
    width: "65%",
    paddingLeft: "12%",
  },

  priceContainer: {
    display: "flex",
    gap: "56%",
  },
}));
