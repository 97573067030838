import React from 'react'
import { Modal } from 'components'
import {
	Button,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { PROGRESS_VIDEOS } from 'lib/constants'

const LevelUpModal = ({
	open,
	handleClose,
	level,
}) => {
	const classes = useStyles()
	const videoUrl = PROGRESS_VIDEOS[level?.value]
	return (
		<Modal
			open={open}
			handleClose={handleClose}
			fullScreen
		>
			<center>
				<video
					muted
					autoPlay
					controls={false}
					src={videoUrl}
					loop
					className={classes.video}
				/>
				<Typography variant="h2">LEVEL UP</Typography>
				<Typography
					variant="subtitle1"
					color="secondary"
					className={classes.subtitle}
				>
					CONGRATULATIONS
				</Typography>
				<Typography variant="body2" color="primary">
					Well done on reaching{' '}
					<span className={classes.highlight}>
						Level {level?.level} for {level?.label}
					</span>
				</Typography>
				<Typography variant="body2" color="primary">
					Keep training to level up all your skills.
				</Typography>
			</center>
		</Modal>
	)
}

export default LevelUpModal

const useStyles = makeStyles((theme) => ({
	video: {
		width: '100%',
		maxWidth: 500,
		height: 'auto',
	},
	highlight: {
		color: theme.palette.secondary.main,
	},
	subtitle: {
		fontWeight: 600,
		marginBottom: theme.spacing(4),
	},
}))
