import React from "react"
import api from "api"
import {
  useAlerts,
  useLoaders,
  useResource,
} from "hooks"
import { validatePosition } from "validations/trainers"
import { useState } from "react"

const usePositions = () => {
  const [positions, setPositions] = useState([])

  const findPositions = async () => {
    const res = await api.get(`/api/positions`)
    if (res.data) {
      // Map response from {id: "", name: ""} to {value: "", label: ""}
      const mapped = []
      for(let i = 0; i < res.data.length; i++) 
      {
        mapped.push({
          value: res.data[i].id,
          label: res.data[i].name
        })
      }
      setPositions(mapped)
    }
  }

  return {
    positions,
    findPositions
  }
}

export default usePositions
