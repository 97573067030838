import React,{useContext} from 'react'
import { useAlerts, useAuth, useUsers} from 'hooks'
import { CardElement } from '@stripe/react-stripe-js'
import { Button, makeStyles } from '@material-ui/core'
import { ButtonLoader } from 'components'
import { useHistory } from 'react-router'
import { AppContext } from "context";

const StripeCreditCardForm = ({
	stripe,
	elements,
	buttonLabel = 'Finish',
	onComplete,
}) => {
	const history = useHistory()
	const { isLoading, addPayment } = useUsers()
	const { selectedPackageName } = useContext(AppContext);
	const { showAlertError } = useAlerts()
	const { fetchMe } = useAuth()
	const classes = useStyles()

	const handleSubmit = async (event) => {
        console.log(selectedPackageName)
		event.preventDefault()

		if (elements == null) {
			return
		}
		const card = elements.getElement(CardElement)
		const { error, token } = await stripe.createToken(card)
		console.log(card)
		console.log(token)
		if (token?.id) {
			await addPayment(token.id, selectedPackageName)
			fetchMe()
			if (onComplete) {
				onComplete()
			}
		} else {
			showAlertError(
				'Please check your credit card information'
			)
		}
	}

	return (
		<div className={classes.root}>
			<CardElement options={stripeStyles} />
			<Button
				fullWidth
				className={classes.button}
				onClick={handleSubmit}
				variant="contained"
				color="primary"
				type="submit"
				disabled={!stripe || !elements}
				startIcon={isLoading && <ButtonLoader />}
			>
				{buttonLabel}
			</Button>
		</div>
	)
}

export default StripeCreditCardForm

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
	button: {
		marginTop: theme.spacing(2),
	},
}))

const stripeStyles = {
	style: {
		base: {
			fontSize: '18px',
			color: '#ffffff',
			'::placeholder': {
				color: '#aab7c4',
			},
		},
		invalid: {
			color: '#9e2146',
		},
	},
}
