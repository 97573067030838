import React, {
	useContext,
	useState,
	useEffect,
} from 'react'
import { AppContext } from 'context'
import { CoverImage } from 'components'
import { useResponsive } from 'hooks'
import {
	Box,
	Container,
	Grid,
	LinearProgress,
	makeStyles,
	Typography,
} from '@material-ui/core'
import { useHistory } from 'react-router'
import { cover, resize } from 'lib/cloudinary'
import {
	COVER_HEIGHT,
	COVER_MOBILE_HEIGHT,
} from 'lib/constants'
import HeroImg from '../../assets/images/hero-feedback-player-card.png'

const FeedbackPlayerCard = ({ handleClick, challengeStatus }) => {
	const history = useHistory()
	const { isMobile } = useResponsive()
	const { currentUser } = useContext(AppContext)
	const classes = useStyles({
		src: HeroImg,
	})

	return (
		<div
			onClick={() => handleClick()}
			className={classes.cover}
		>
      {
        challengeStatus == "Feedback in progress. Check back soon!" && (
          <div className={classes.inProgressCta}>
            <LinearProgress className={classes.ctaProgressBar} />
            <p className={classes.ctaDescriptionInProgress}>{challengeStatus}</p>
          </div>
        )
      }

      {     
        challengeStatus == "Feedback ready. View your new player card now!" && (
          <div className={classes.readyCta}>
            <p className={classes.ctaDescriptionReady}>{challengeStatus}</p>
          </div>
        )
      }
      
			<Container
				maxWidth="lg"
				className={classes.content}
			>
        {
          challengeStatus == "" && (
            <div className={classes.defaultCta}>
              <Typography
                variant="h3"
                color="primary"
                className={classes.ctaTitle}
              >
                GET YOUR FEEDBACK & PLAYER CARD
              </Typography>
              <p className={classes.ctaDescription}>Click here to receive detailed feedback from UEFA licensed coaches in 1 day</p>
            </div>
          )
        }
			</Container>
		</div>
	)
}
export default FeedbackPlayerCard
const useStyles = makeStyles((theme) => ({
	cover: {
		cursor: 'pointer',
		width: '100%',
		height: COVER_HEIGHT,
		backgroundPosition: '50% 25%',
		backgroundImage: (props) => `url(${props.src}) `,
		backgroundSize: 'cover',
		// maskImage:
		// 	'linear-gradient(to top, rgba(0,0,0,0.05) 0%,rgba(0,0,0,1.0) 15%)',
		[theme.breakpoints.down('sm')]: {
			height: COVER_MOBILE_HEIGHT,
		},
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column-reverse",
		borderRadius: "12px",

	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		height: '100%',
		width: '100%',
	},
	defaultCta: {
		display: "flex",
		flexDirection: "column"
	},
  inProgressCta: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    padding: "0px 40px",
    borderRadius: "0px 0px 12px 12px"
  },
  ctaProgressBar: {
    backgroundColor: "#6BE0A0",
    marginTop: "24px"
  },
  readyCta: {
    width: "100%",
    padding: "0px 40px",
    borderRadius: "0px 0px 12px 12px",
    backgroundColor: "#6BE0A0"
  },
  ctaTitle: {
		lineHeight: 0.9,
		fontSize: "60px"
	},
	ctaDescription: {
		color: "#8DDDA5"
	},
  ctaDescriptionInProgress: {
		color: "#1E1E1E",
    marginTop: "10px"
	},
  ctaDescriptionReady: {
    color: "#1E1E1E"
  },



	activeTraining: {
		marginTop: theme.spacing(2),
		lineHeight: 0.9,
		color: theme.palette.background.main,
		[theme.breakpoints.down('xs')]: {
			color: theme.palette.secondary.main,
		},
	},
	activeTrainingCaption: {
		lineHeight: 0.9,
		textTransform: 'uppercase',
		color: theme.palette.background.main,
		[theme.breakpoints.down('xs')]: {
			color: theme.palette.secondary.main,
		},
	},
	caption: {
		textTransform: 'uppercase',
	},
	nextSession: {
		cursor: 'pointer',
		margin: theme.spacing(0, 0, 6, 0),
		padding: theme.spacing(1),
		border: `1px solid ${theme.palette.secondary.main}`,
		borderRadius: 6,
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		backgroundColor: theme.palette.background.main,
		maxWidth: 540,
	},
	caption: {
		lineHeight: 0.9,
	},
	sessionProgress: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
	},
	
}))